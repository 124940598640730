import React, {useContext} from 'react'

import Assets from '../../constants/images'

import {NotificationPopupContext, SubAdminProfilePopupContext, UserDataContext} from "../../App"
import NotificationPopup from '../popup/NotificationPopup'

import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import SubAdminProfilePopup from '../popup/SubAdminProfilePopup'
import {useDispatch, useSelector} from 'react-redux';
import {initTour, resetTour} from '../../redux/slices/tourSlice.js'


const SubAdminHeader = (props) => {
    const [userData, setUserData] = useContext(UserDataContext)
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    const [handleSubAdminProfilePopup, setHandleSubAdminProfilePopup] = useContext(SubAdminProfilePopupContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tour = useSelector((state) => state.tour);
    const location = useLocation();


    const handleInitTour = () => {
        console.log("handleInitTour")
        // navigate("/sub-admin/manage-group")
        dispatch(resetTour())
        dispatch(initTour())
    }

    return (
        <header className='header pb-4'>
            <NotificationPopup />
            <SubAdminProfilePopup />

            <div className='d-flex align-items-center justify-content-between'>
                <div className='me-4'>
                    <NavLink to={"/sub-admin/manage-group"}>
                        <img id="logo" className='w-100' src={Assets.Logo} alt="Logo" />
                    </NavLink>
                </div>

                <div className='d-flex align-items-center'>
                    <div className='me-4 d-none d-md-flex align-items-center'>
                        {/*{*/}
                        {/*    (location.pathname === '/sub-admin/manage-group')?*/}
                        {/*        <Button className='headerBtn me-4' onClick={handleInitTour}>Build</Button>*/}
                        {/*        :<Button className='headerBtn me-4 opacity-75' onClick={handleInitTour}>Build</Button>*/}
                        {/*}*/}
                        <NavLink
                            to="/sub-admin/manage-group"
                            id="build-group-btn"
                            className="text-dark me-4"
                            onClick={handleInitTour} // Call the function with navigation and actions
                        >
                            Build
                        </NavLink>

                        {/*<NavLink id="build-group-btn" className='text-dark me-4' onClick={handleInitTour}>Build</NavLink>*/}
                        {/* <NavLink id="manage-group-btn" className="text-dark me-4 manage-group-nav" to="/sub-admin/manage-group">Manage Group</NavLink> */}
                        <NavLink id="email-setup-btn" className="text-dark me-4" to="/sub-admin/email-setup">E-mail Setup</NavLink>
                        <NavLink id="client-history-btn" className="text-dark" to="/sub-admin/client-history">Client History</NavLink>
                    </div>
                    {/* <div className="me-4"
                        style={{
                            aspectRatio: '1 / 1',
                            width: '20px',
                            height: '20px',
                            padding: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ff574c',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            outline: '2px solid rgba(255, 87, 76, .5)'
                        }}
                        onClick={() => props.onHelp()}>
                        <QuestionMarkIcon />
                    </div> */}

                    {/* <div className='circle-div me-3 me-md-4' onClick={() => setHandleNotificationPopup(true)}>
                        <div className="notify"></div>
                        <BsFillBellFill />
                    </div> */}

                    <div id="account-circle" className='circle-div img-div' onClick={() => {
                        setHandleSubAdminProfilePopup(true);
                        props.onStopIntro();
                    }}>
                        <img onError={(e) => e.target.src = Assets.ProfilePlaceHolder} src={userData?.details?.image ? userData?.details?.image : Assets.AvatarLarge} alt="Avatar" />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default SubAdminHeader