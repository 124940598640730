import React from "react";

import {MusicIconSmall, PlayButton} from "../../constants/svgs";

export const MusicCard = ({
                            data,
                            downloadFile,
                            click,
                            setClick,
                            setSelectedData,
                            setRowModalOpen,
                            setModalType
                          }) => {
  return (
      <div
          onClick={() => {
            setClick(!click);
            setSelectedData(data);
            setRowModalOpen(true);
            setModalType(true);
          }}
          className="my-card file-card pb-3 p-2"
      >
        {/* <Button onClick={()=>downloadFile(data?.file_url)} className='download-btn'><MdDownloadForOffline /></Button> */}
        <div className="bg-div bg-light-grey py-4">
          <div className="file-icon-container bg-white">
            <MusicIconSmall />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 mt-2">
          <span className="d-inline-block">{data?.title}</span>
          <PlayButton />
        </div>
      </div>
  );
};
