import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, Col, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap'
import { AddGroupIconMedium, AddGroupIconSmall } from '../constants/svgs'
import { RemoveGroupPopupContext, RemoveBoxPopupContext, AddBoxPopupContext, AddGroupPopupContext, EditGroupPopupContext, EditBoxPopupContext, EditDeleteGroupPopupContext, RemoveDeleteGroupPopupContext } from '../App'
import AddGroupPopup from '../components/popup/AddGroupPopup'
import AddBoxPopup from '../components/popup/AddBoxPopup'
import AudioCardSmall from '../components/home/AudioCardSmall'
import ImageCardSmall from '../components/home/ImageCardSmall'
import VideoCardSmall from '../components/home/VideoCardSmall'
import PdfCardSmall from '../components/home/PdfCardSmall'
import ImagePopup from '../components/popup/ImagePopup'
import PdfPopup from '../components/popup/PdfPopup'
import AudioPopup from '../components/popup/AudioPopup'
import VideoPopup from '../components/popup/VideoPopup'
import { GetBoxes, GetDeletedGroupBoxes, GetEssionsListing, GetGroups, GetResourceBoxes, SaveResourceInBoxes, SaveResourceInDeletedGroupBoxes, UploadEresource } from '../services/auth/auth.services'
import { success, error } from "../constants/msg";

import RemoveResourcePopup from '../components/popup/RemoveResourcePopup'
import { UploadFileToS3 } from '../utils/helpers'
import { UserDataContext } from "../App"


import { useParams } from 'react-router-dom'
import { constant } from "../utils/constants"
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri'

import RemoveGroupPopup from "../components/popup/RemoveGroupPopup"
import RemoveBoxPopup from "../components/popup/RemoveBoxPopup"
import EditGroupPopup from '../components/popup/EditGroupPopup'
import EditBoxPopup from '../components/popup/EditBoxPopup'
import { BsFillInfoCircleFill } from "react-icons/bs"
import EditDeletedBoxPopup from '../components/popup/EditDeletedBoxPopup'
import RemoveDeletedBoxPopup from '../components/popup/RemoveDeletedBoxPopup'
import { TOOL_TIP_MESSAGES } from '../constants/app-constants'

const ManageSpecificGroupPage = ({ setGroupName }) => {
    const { id } = useParams()
    const fileUploadRefGroup = useRef(null)
    const fileUploadRefBox = useRef(null)
    const [isDisabledGroup, setIsDisabledGroup] = useState(false)
    const [isDisabledBox, setIsDisabledBox] = useState(false)
    const [loadingGroup, setLoadingGroup] = useState(false);
    const [loadingBox, setLoadingBox] = useState(false);
    const [loadingDeletedGroupBox, setLoadingDeletedGroupBox] = useState(false);
    const [session, setSession] = useState()
    const [groups, setGroups] = useState([])
    const [boxes, setBoxes] = useState([])
    const [resourceBoxes, setResourceBoxes] = useState([])
    const [mergeGroupAndBoxes, setMergeGroupAndBoxes] = useState([])

    const [showSelectedResource, setShowSelectedResource] = useState(null)
    const [selectedResource, setSelectedResource] = useState(null)
    const [deletedGroupData, setDeletedGroupData] = useState([])
    const [activeDeletedGroupBox, setActiveDeletedGroupBox] = useState(false)
    const [count, setCount] = useState(0)

    const [selectedGroup, setSelectedGroup] = useState({
        index: "",
        id: "",
    })

    const [selectedBox, setSelectedBox] = useState({
        index: "",
        id: "",
        name: "",
        group_index: "",
        group_id: ""
    })

    const [fileSize, setFileSize] = useState(0)
    const [now, setNow] = useState(0)
    const [now2, setNow2] = useState(0)

    // *********************** working context ***********************
    const [handleRemoveGroupPopup, setHandleRemoveGroupPopup] = useContext(RemoveGroupPopupContext);
    const [handleRemoveBoxPopup, setHandleRemoveBoxPopup] = useContext(RemoveBoxPopupContext);
    const [handleAddGroupPopup, setHandleAddGroupPopup] = useContext(AddGroupPopupContext);
    const [handleAddBoxPopup, setHandleAddBoxPopup] = useContext(AddBoxPopupContext);
    const [handleEditGroupPopup, setHandleEditGroupPopup] = useContext(EditGroupPopupContext);
    const [handleEditBoxPopup, setHandleEditBoxPopup] = useContext(EditBoxPopupContext);
    const [userData, setUserData] = useContext(UserDataContext);

    const [handleEditDeletedGroupPopup, setHandleEditDeletedGroupPopup] = useContext(EditDeleteGroupPopupContext);
    const [handleRemoveDeletedGroupPopup, setHandleRemoveDeletedGroupPopup] = useContext(RemoveDeleteGroupPopupContext);

    // *********************** working context ***********************


    // States to set "url's" of different resources inside popup
    const [pdfPopupItem, setPdfPopupItem] = useState({ file_url: "" });
    const [imagePopupItem, setImagePopupItem] = useState({ file_url: "" });
    const [videoPopupItem, setVideoPopupItem] = useState({ file_url: "" });
    const [audioPopupItem, setAudioPopupItem] = useState({ file_url: "", title: "" });

    const returnCardInformation = (v, i) => {
        // if (v.type === "text") {
        //   return (
        //     <TextViewer data={v} key={i} />
        //   )
        // }
        if (v.type === "pdf" || v.type === "document") {
            return (
                <PdfCardSmall setSelectedResource={setSelectedResource} setPdfPopupItem={setPdfPopupItem} data={v} key={i} />
            )
        }
        else if (v.type === "image") {
            return (
                <ImageCardSmall setSelectedResource={setSelectedResource} setImagePopupItem={setImagePopupItem} data={v} key={i} />
            )
        }
        else if (v.type === "mp3" || v.type === "audio") {
            return (
                <AudioCardSmall setSelectedResource={setSelectedResource} setAudioPopupItem={setAudioPopupItem} isAudioPlayer={true} show1="d-block" show2="d-none" paddingY="py-5" data={v} key={i} />
            )
        }
        else if (v.type === "video" || v.type === "mp4" || v.type === "m4v") {
            return (
                <VideoCardSmall setSelectedResource={setSelectedResource} setVideoPopupItem={setVideoPopupItem} isAudioPlayer={false} show1="d-block" show2="d-none" paddingY="py-5" data={v} key={i} />
            )
        }
    }

    const returnFileType = (file) => {
        let fileType = file.name.split(".")
        fileType = fileType[fileType.length - 1].toLowerCase()

        if (fileType === "png" || fileType === "jpg" || fileType === "jpeg" || fileType === "jfif" || fileType === "webp") {
            fileType = "image"
        }
        else if (fileType === "pdf" || fileType === "docx" || fileType === "doc" || fileType === "txt" || fileType === "xlsx" || fileType === "tiff") {
            fileType = "document"
        }
        else if (fileType === "mp3") {
            fileType = "mp3"
        }
        else if (fileType === "mp4" || fileType === "mov" || fileType === "m4v") {
            fileType = "video"
        }
        else {
            fileType = "invalid"
        }
        return fileType
    }

    const handleFileUploadInBox = async (e, box_id, group_id) => {
        let file = e.target.files[0]

        if (file) {
            setFileSize(file.size)
            setIsDisabledBox(true)
            setLoadingBox(true)
            let fileType = returnFileType(file)
            if (fileType == "invalid") {
                error("Invalid file type")
                fileUploadRefBox.current.value = null
                setLoadingBox(false)
                setTimeout(() => {
                    setIsDisabledBox(false)
                }, 1500)
            }
            else {
                try {
                    await UploadFileToS3(file, constant.resourceDirName).then(async (document) => {
                        const title = file.name
                        const description = "Dummy"
                        const fileSize = file.size

                        await UploadEresource(title, description, document.url, fileType, fileSize, userData.id).then(async (res) => {
                            const resourse_id = res.data.data.id
                            await SaveResourceInBoxes(resourse_id, box_id, group_id).then((response) => {
                                const uploadResourceData = res.data.data
                                const resourseBoxData = response.data.data
                                let obj = {
                                    box: {
                                        id: resourseBoxData.box_id,
                                        group_id: resourseBoxData.group_id
                                    },
                                    box_id: resourseBoxData.box_id,
                                    group: {
                                        id: resourseBoxData.group_id
                                    },
                                    group_id: resourseBoxData.group_id,
                                    id: uploadResourceData.id,
                                    resource: {
                                        ...uploadResourceData,
                                        resource_boxId: resourseBoxData.id
                                    },
                                    resource_id: resourseBoxData.resource_id,
                                    resource_boxId: resourseBoxData.id
                                }
                                updateStateOnFileUpload(obj, resourseBoxData.group_id, resourseBoxData.box_id, false)
                                fileUploadRefBox.current.value = null
                                setTimeout(() => {
                                    setIsDisabledBox(false)
                                    success(response.data.message)
                                }, 1100);

                                setLoadingBox(false)

                            }).catch((e) => {
                                fileUploadRefBox.current.value = null
                                error(e.response.data.message)
                                setTimeout(() => {
                                    setIsDisabledBox(false)
                                }, 1000);
                                setLoadingBox(false)
                            })
                        }).catch((e) => {
                            fileUploadRefBox.current.value = null
                            error("File is too large")
                            setTimeout(() => {
                                setIsDisabledBox(false)
                            }, 1000);
                            setLoadingBox(false)
                        })
                    })
                }
                catch (e) {
                    fileUploadRefBox.current.value = null
                    setTimeout(() => {
                        setIsDisabledBox(false)
                    }, 1000);
                }
            }
        }
    }

    const handleFileUploadInDeletedGroupBox = async (e, box_id) => {
        let file = e.target.files[0]

        if (file) {
            setFileSize(file.size)
            setIsDisabledBox(true)
            setLoadingDeletedGroupBox(true)
            let fileType = returnFileType(file)
            if (fileType == "invalid") {
                error("Invalid file type")
                fileUploadRefBox.current.value = null
                setLoadingDeletedGroupBox(false)
                setTimeout(() => {
                    setIsDisabledBox(false)
                }, 1500)
            }
            else {
                try {
                    await UploadFileToS3(file, constant.resourceDirName).then(async (document) => {
                        const title = file.name
                        const description = "Dummy"
                        const fileSize = file.size

                        await UploadEresource(title, description, document.url, fileType, fileSize, userData.id).then(async (res) => {
                            const resourse_id = res.data.data.id
                            await SaveResourceInDeletedGroupBoxes(resourse_id, box_id).then((response) => {
                                const uploadResourceData = res.data.data
                                const resourseBoxData = response.data.data
                                let obj = {
                                    box: {
                                        id: resourseBoxData.box_id,
                                        group_id: null
                                    },
                                    box_id: resourseBoxData.box_id,
                                    group: {
                                        id: null
                                    },
                                    group_id: null,
                                    id: uploadResourceData.id,
                                    resource: {
                                        ...uploadResourceData,
                                        resource_boxId: resourseBoxData.id
                                    },
                                    resource_id: resourseBoxData.resource_id,
                                    resource_boxId: resourseBoxData.id
                                }
                                updateStateOnFileUploadInDeletedGroupBox(obj, box_id, false)
                                fileUploadRefBox.current.value = null
                                setTimeout(() => {
                                    setIsDisabledBox(false)
                                    success(response.data.message)
                                }, 1100);

                                setLoadingDeletedGroupBox(false)

                            }).catch((e) => {
                                fileUploadRefBox.current.value = null
                                error(e.response.data.message)
                                setTimeout(() => {
                                    setIsDisabledBox(false)
                                }, 1000);
                                setLoadingDeletedGroupBox(false)
                            })
                        }).catch((e) => {
                            fileUploadRefBox.current.value = null
                            error("File is too large")
                            setTimeout(() => {
                                setIsDisabledBox(false)
                            }, 1000);
                            setLoadingDeletedGroupBox(false)
                        })
                    })
                }
                catch (e) {
                    fileUploadRefBox.current.value = null
                    setTimeout(() => {
                        setLoadingDeletedGroupBox(false)
                        setIsDisabledBox(false)
                    }, 1000);
                }
            }
        }
    }

    const updateStateOnFileUpload = (obj, group_id, box_id, bool) => {
        let array = []
        let temp = [...resourceBoxes]
        temp.unshift(obj)

        if (bool) {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].group_id == group_id && temp[i].box == null) {
                    array.push(temp[i].resource)
                }
            }
            setResourceBoxes(temp)
            // setShowSelectedResource(array)
            setShowSelectedResource(null)
        }
        else {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].group_id == group_id && temp[i].box !== null && temp[i].box_id == box_id) {
                    array.push(temp[i].resource)
                }
            }
            setResourceBoxes(temp)
            setShowSelectedResource(array)
        }
    }

    const updateStateOnFileUploadInDeletedGroupBox = (obj, box_id, bool) => {
        let temp = [...deletedGroupData]
        let index = temp.findIndex((val) => val.id == box_id)
        temp[index].resources.unshift(obj.resource)
        setDeletedGroupData(temp)
        setShowSelectedResource(temp[index].resources)
    }

    const handleSelectedResources = (group_id, box_id, bool) => {
        let array = []
        // for group everytime
        if (bool) {
            for (let i = 0; i < resourceBoxes.length; i++) {
                if (resourceBoxes[i].group_id == group_id && resourceBoxes[i].box == null) {
                    array.push(resourceBoxes[i].resource)
                }
            }
            // setShowSelectedResource(array)
            setShowSelectedResource(null)
        }
        else {
            for (let i = 0; i < resourceBoxes.length; i++) {
                if (resourceBoxes[i].group_id == group_id && resourceBoxes[i].box !== null && resourceBoxes[i].box_id == box_id) {
                    array.push(resourceBoxes[i].resource)
                }
            }
            setShowSelectedResource(array)
        }
    }

    useEffect(() => {
        const getEsession = async () => {
            try {
                await GetEssionsListing(userData.id).then((res) => {
                    let session_id = res.data.data[0].id
                    setSession(res.data.data[0])
                    getGroups(session_id)
                    getBoxes(session_id)
                    getDeletedGroupBoxes(session_id)
                })
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        const getGroups = async (session_id) => {
            try {
                await GetGroups(session_id).then((res) => {
                    let data = res.data.data
                    let index = data.findIndex((val) => val.id == id)
                    setGroupName(data[index].name)
                    setGroups([data[index]])
                })


            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        const getBoxes = async (session_id) => {
            try {
                const res = await GetBoxes(session_id);
                setBoxes(res.data.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        const getResourceBoxes = async () => {
            let array = [];
            try {
                const res = await GetResourceBoxes();
                let data = res.data.data;

                for (let i = 0; i < data.length; i++) {
                    if (typeof data[i].resource !== 'undefined' && data[i].resource !== null) {
                        data[i].resource.resource_boxId = data[i].id;
                    }
                    array.push(data[i]);
                }

                setResourceBoxes(array);
            }
            catch (e) {
                error(e.response.data.message);
            }
        }

        const getDeletedGroupBoxes = async (session_id) => {
            try {
                const res = await GetDeletedGroupBoxes(session_id)
                let data = res.data.data
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < data[i].resources.length; j++) {
                        let index = data[i].box_resources.findIndex((val) => val.resource_id == data[i].resources[j].id)
                        data[i].resources[j].resource_boxId = data[i].box_resources[index].id
                    }
                }
                setDeletedGroupData(data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        getEsession()
        getResourceBoxes()

    }, [id])

    useEffect(() => {
        let array = []
        if (boxes && groups) {
            let temp = [...groups]
            for (let i = 0; i < temp.length; i++) {
                for (let j = 0; j < boxes.length; j++) {
                    if (temp[i].id === boxes[j].group_id) {
                        array.push(boxes[j])
                    }
                }
                temp[i].boxes = array
                array = []
            }
            setMergeGroupAndBoxes(temp)
        }
    }, [groups, boxes])

    useEffect(() => {
        if (loadingBox == true) {
            if (now < 97) {
                setTimeout(() => {
                    setNow(now + 1)
                }, ((1 / 1000) * fileSize * (1 / 100)))
            }
        }
        else if (loadingDeletedGroupBox == true) {
            if (now2 < 97) {
                setTimeout(() => {
                    setNow2(now2 + 1)
                }, ((1 / 1000) * fileSize * (1 / 100)))
            }
        }
        else {
            setNow(0)
            setNow2(0)
        }
    }, [loadingBox, now, now2, loadingDeletedGroupBox])

    const renderTooltip = (message) => (
        <Tooltip style={{ fontSize: "12px" }}>{message}</Tooltip>
    );

    return (
        <div className='manage-group-page'>
            <AddGroupPopup session={session} isStateUpdate={true} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />
            <EditGroupPopup selectedGroup={selectedGroup} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />
            <EditBoxPopup selectedBox={selectedBox} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />
            <EditDeletedBoxPopup selectedBox={selectedBox} deletedGroupData={deletedGroupData} setDeletedGroupData={setDeletedGroupData} />
            <RemoveDeletedBoxPopup setShowSelectedResource={setShowSelectedResource} selectedBox={selectedBox} deletedGroupData={deletedGroupData} setDeletedGroupData={setDeletedGroupData} />

            <AddBoxPopup setMergeGroupAndBoxes={setMergeGroupAndBoxes} mergeGroupAndBoxes={mergeGroupAndBoxes} selectedGroup={selectedGroup} />
            <ImagePopup data={imagePopupItem} />
            <PdfPopup data={pdfPopupItem} />
            <AudioPopup data={audioPopupItem} />
            <VideoPopup data={videoPopupItem} />
            <RemoveResourcePopup deletedGroupData={deletedGroupData} setDeletedGroupData={setDeletedGroupData} activeDeletedGroupBox={activeDeletedGroupBox} resourceBoxes={resourceBoxes} setResourceBoxes={setResourceBoxes} showSelectedResource={showSelectedResource} setShowSelectedResource={setShowSelectedResource} selectedResource={selectedResource} />
            <RemoveGroupPopup redirectStatus={true} count={count} setCount={setCount} deletedGroupData={deletedGroupData} setDeletedGroupData={setDeletedGroupData} setShowSelectedResource={setShowSelectedResource} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />
            <RemoveBoxPopup setShowSelectedResource={setShowSelectedResource} selectedBox={selectedBox} setSelectedBox={setSelectedBox} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />


            <Row>
                <Col lg={6} xl={3} className="mb-4">
                    <div className="left p-4 pt-0">
                        <div className="holder">
                            <div className="accordian-container mt-4">
                                <Accordion defaultActiveKey="0">
                                    {
                                        mergeGroupAndBoxes.length > 0 &&
                                        mergeGroupAndBoxes.map((group, i) => (
                                            <Accordion.Item key={i} className="border-bottom" eventKey={i}>
                                                <Accordion.Header onClick={() => {
                                                    setSelectedGroup({
                                                        id: group.id,
                                                        index: i,
                                                        name: group.name
                                                    })
                                                    setSelectedBox({
                                                        id: "",
                                                        index: "",
                                                        name: "",
                                                        group_id: "",
                                                        group_index: ""
                                                    })
                                                    setActiveDeletedGroupBox(false)
                                                    handleSelectedResources(group.id, null, true)
                                                }}>
                                                    <span className="title-group">{group.name}</span>
                                                    {
                                                        (!group?.resources || group?.resources?.length == 0) && <span className="delete-btn-container" onClick={() => {
                                                            setHandleRemoveGroupPopup(true)
                                                            setActiveDeletedGroupBox(false)
                                                            setSelectedGroup({
                                                                id: group.id,
                                                                index: i,
                                                                name: group.name
                                                            })
                                                        }}>
                                                            <RiDeleteBin6Line className='me-2 icon text-grey' />
                                                        </span>
                                                    }

                                                    <span className="edit-btn-container" onClick={() => {
                                                        setSelectedGroup({
                                                            id: group.id,
                                                            index: i,
                                                            name: group.name
                                                        })
                                                        setActiveDeletedGroupBox(false)
                                                        setHandleEditGroupPopup(true)
                                                    }}>
                                                        <RiEdit2Line className='me-2 icon text-grey' />
                                                    </span>
                                                </Accordion.Header>

                                                <Accordion.Body>
                                                    <div className="box-small w-100 p-2 d-flex align-items-center" onClick={() => {
                                                        setActiveDeletedGroupBox(false)
                                                        setHandleAddBoxPopup(true)
                                                        setSelectedGroup({
                                                            id: group.id,
                                                            index: i,
                                                            name: group.name
                                                        })
                                                    }}>
                                                        <div className='icon-container'>
                                                            <AddGroupIconSmall />
                                                        </div>
                                                        <h6 className='m-0 p-0 ms-3'>+ Add New Box</h6>
                                                    </div>

                                                    {
                                                        group.boxes.length > 0 &&
                                                        group.boxes.map((box, j) => (
                                                            <div key={j} className='sub-description mt-3 d-flex align-items-center justify-content-between'
                                                                onClick={() => {
                                                                    setSelectedGroup({
                                                                        id: "",
                                                                        index: "",
                                                                        name: ""
                                                                    })

                                                                    setSelectedBox({
                                                                        id: box.id,
                                                                        index: j,
                                                                        name: box.name,
                                                                        group_id: box.group_id,
                                                                        group_index: i
                                                                    })

                                                                    setActiveDeletedGroupBox(false)
                                                                    handleSelectedResources(box.group_id, box.id, false)
                                                                }}
                                                            >
                                                                <h6 className={`m-0 p-0`}>
                                                                    {box.name}
                                                                </h6>

                                                                <div>
                                                                    <span className="me-1" onClick={() => {
                                                                        setSelectedGroup({
                                                                            id: "",
                                                                            index: "",
                                                                            name: ""
                                                                        })

                                                                        setSelectedBox({
                                                                            id: box.id,
                                                                            index: j,
                                                                            name: box.name,
                                                                            group_id: box.group_id,
                                                                            group_index: i
                                                                        })

                                                                        setActiveDeletedGroupBox(false)
                                                                        setHandleEditBoxPopup(true)
                                                                    }}>
                                                                        <RiEdit2Line className='me-1 icon text-grey' />
                                                                    </span>

                                                                    <span className="" onClick={() => {
                                                                        setActiveDeletedGroupBox(false)
                                                                        setHandleRemoveBoxPopup(true)
                                                                    }}>
                                                                        <RiDeleteBin6Line className='me-2 icon text-grey' />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6} xl={9} className="mb-4">
                    <div className="right p-4">
                        <div className="holder">
                            
                            <Row className={`${showSelectedResource === null || showSelectedResource.length <= 0 ? "h-100" : ""} `}>
                                <Col xs={12} xl={12} className={`${showSelectedResource === null || showSelectedResource.length <= 0 ? "h-100" : ""} `}>
                                    <Row className="mb-4">
                                        <Col xl={{ span: 6, offset: 6 }}>
                                            {
                                                selectedBox.id !== "" &&
                                                <>
                                                    {
                                                        activeDeletedGroupBox ?
                                                            <label className='d-block' htmlFor="myfile">
                                                                <input
                                                                    disabled={isDisabledBox}
                                                                    accept='.jpg, .jpeg, .png, .jfif, .tiff, .mp4, .m4v,.mov, .mp3, .pdf, .docx, .txt, .xlsx'
                                                                    ref={fileUploadRefBox}
                                                                    onClick={() => {
                                                                        setSelectedGroup({
                                                                            id: "",
                                                                            index: "",
                                                                            name: ""
                                                                        })
                                                                    }}
                                                                    onChange={(e) => handleFileUploadInDeletedGroupBox(e, selectedBox.id)}
                                                                    className='d-none' type="file" name="file" id="myfile"
                                                                />
                                                                <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_RESOURCE)}>
                                                                    <div className="box w-100 p-3 d-flex align-items-center">
                                                                        <span className="custom-tooltip"><BsFillInfoCircleFill /></span>
                                                                        <div className='icon-container'>
                                                                            <AddGroupIconMedium />
                                                                        </div>
                                                                        {
                                                                            loadingDeletedGroupBox ?
                                                                                <div className='mt-20 d-flex align-items-center flex-column w-100'>
                                                                                    <ProgressBar className='w-100' now={now2} />
                                                                                    <p className='m-0 fw-500 w-100 text-orange text-end'>{now2 + "%"}</p>
                                                                                    <small>larger files may simply take longer to upload. Please be patient. 1GB max file size</small>
                                                                                </div>
                                                                                :
                                                                                <h6 className='m-0 p-0 ms-3'>Add New Resource In {selectedBox.name}</h6>
                                                                        }
                                                                    </div>
                                                                </OverlayTrigger>

                                                            </label>
                                                            :
                                                            <label className='d-block' htmlFor="myfile">
                                                                <input
                                                                    disabled={isDisabledBox}
                                                                    accept='.jpg, .jpeg, .png, .jfif, .tiff, .mp4, .m4v,.mov, .mp3, .pdf, .docx, .txt, .xlsx'
                                                                    ref={fileUploadRefBox}
                                                                    onClick={() => {
                                                                        setSelectedGroup({
                                                                            id: "",
                                                                            index: "",
                                                                            name: ""
                                                                        })
                                                                    }}
                                                                    onChange={(e) => handleFileUploadInBox(e, selectedBox.id, selectedBox.group_id)}
                                                                    className='d-none' type="file" name="file" id="myfile"
                                                                />
                                                                <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_RESOURCE)}>
                                                                    <div className="box w-100 p-3 d-flex align-items-center">
                                                                        <span className="custom-tooltip"><BsFillInfoCircleFill /></span>
                                                                        <div className='icon-container'>
                                                                            <AddGroupIconMedium />
                                                                        </div>
                                                                        {
                                                                            loadingBox ?
                                                                                <div className='mt-20 d-flex align-items-center flex-column w-100'>
                                                                                    <ProgressBar className='w-100' now={now} />
                                                                                    <p className='m-0 fw-500 w-100 text-orange text-end'>{now + "%"}</p>
                                                                                    <small>larger files may simply take longer to upload. Please be patient. 1GB max file size</small>
                                                                                </div>
                                                                                :
                                                                                <h6 className='m-0 p-0 ms-3'>Add New Resource In {selectedBox.name}</h6>
                                                                        }
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </label>
                                                    }
                                                </>

                                            }

                                        </Col>
                                    </Row>
                                    <Row className={`${showSelectedResource === null ? "h-100" : ""} `}>
                                        {
                                            showSelectedResource === null ?
                                                <Col className="d-flex align-items-center mt-2 text-center justify-content-center h-100 my-auto" xs={12}>
                                                    <div>
                                                        <h3 className='no-items'>Please select box to see available resources 2</h3>
                                                    </div>
                                                </Col>
                                                :
                                                showSelectedResource.length > 0 ?
                                                    showSelectedResource.map((v, i) => (
                                                        <Col key={i} xl={6} className="mb-4">
                                                            {
                                                                returnCardInformation(v, i)
                                                            }
                                                        </Col>
                                                    ))
                                                    :
                                                    <div className="mt-5">
                                                        <h3 className='text-center no-items'>No resources available</h3>
                                                    </div>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col >
            </Row >
        </div >
    )
}

export default ManageSpecificGroupPage