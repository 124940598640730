import {configureStore} from '@reduxjs/toolkit'
import boxSlice from "./slices/boxSlice";
import groupSlice from "./slices/groupSlice";
import tourSlice from "./slices/tourSlice";
import professionalSlice from "./slices/professionalSlice";
import templateSlice from "./slices/templateSlice";
/*
export const store = configureStore({
    reducer: rootReducer,
})*/
export default configureStore({
    reducer: {
        box: boxSlice,
        group: groupSlice,
        tour: tourSlice,
        professional: professionalSlice,
        template: templateSlice,
    }
});