import React, {useState} from 'react';
import {Button, Image} from "react-bootstrap"
import {error, success} from '../../../../constants/msg';
import {DeleteEResource} from "../../../../services/auth/auth.services";
import {setReloadBoxData} from "../../../../redux/slices/boxSlice";
import {useDispatch} from "react-redux";
import Assets from "../../../../constants/images";


const EmptyComponent = ({ rowData, setRowModalOpen, getResourceBoxes }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = async () => {
        setIsDeleting(true);
        await DeleteEResource(rowData.id).then((data) => {
            success(data.data.message);
            setIsDeleting(false);
            dispatch(setReloadBoxData(true))
            setRowModalOpen(false)
            getResourceBoxes()
        }).catch((e) => {
            setIsDeleting(false);
            error(e.response.data.message)
        })
    }

    return (
        <>
            <div className="d-flex flex-column gap-4">

                <div className={`image_box "show"`}>
                    <Image src={Assets.ImagePlaceHolder} />
                </div>
                <div className="action_button d-flex gap-3 mx-auto">
                    <Button className="btn-common no-bg" onClick={handleDelete} disabled={isDeleting}>Delete</Button>
                </div>
            </div >
        </>
    )
}

export default EmptyComponent