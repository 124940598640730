import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { GetPages } from "../services/auth/pages.services"
import parse from 'html-react-parser';
import { error } from "../constants/msg";

import Loader from '../components/loader/Loader';

const TermsAndConditionsPage = ({ noGutters, height }) => {
    const [pageData, setPageData] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const FetchPage = async () => {
            try {
                const res = await GetPages(1);
                setPageData(res.data.data)
                setIsLoading(false)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }
        FetchPage()
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div className='text-center mt-4'>
                        <Loader />
                    </div>
                    :
                    <div className="terms-and-condition mb-4">
                        <Row className={!noGutters && 'mt-4'}>
                            <Col xs={12}>
                                <div className={`div-content text-start p-4 bg-white ${height && "h-100vh"}`}>
                                    <div className='border-bottom pb-4 mb-4'>
                                        <h5 className='p-0 m-0'>{pageData.title}</h5>
                                    </div>
                                    <p>By creating an account and accessing this site you are agreeing to abide by our terms of service here below.</p>

                                    <p>What you basically need to know:</p>
                                    <ol>
                                        <li>
                                            <p>We provide a way for professionals and their clients to improve the way they learn and help their clients perform by providing professionals a simple way to send Session Specific Follow-Up in Seconds.</p>
                                        </li>
                                        <li>
                                            <p>You agree to not do anything illegal. As a Pro or a Client</p>
                                        </li>
                                        <li>
                                            <p>The stickler rule: Just because a typo, or an example says something, it’s the spirit of the rules we are sticking with, not the micro version of how someone could possibly interpret it. In the end we have the final say on the content allowed on our platform, and we don’t have to give a reason for disallowing things (we probably will, but we don’t have to)</p>
                                        </li>
                                        <li>
                                            <p>Non-complete definitions of everything we are talking about (for people who want to be sticklers).</p>
                                        </li>
                                        <li>
                                            <p>As a Pro, you understand that content you deliver may be consumed by other parties and that no system is 100% secure.</p>
                                        </li>
                                        <li>
                                            <p>As a Client, you agree to use the content delivered to you for the exclusive purpose the Pro sent you the content.</p>
                                        </li>
                                        <li>
                                            <p>We collect data to improve the service.</p>
                                        </li>
                                        <li>
                                            <p>You own your content.</p>
                                            <ol>
                                                <li>
                                                    <p>You license us to distribute the content to Clients in accordance with the time and manner of your engagement with our service.</p>
                                                </li>
                                                <li>
                                                    <p>You license all Grahamity employees to view the content for private purposes to improve ourselves as humans.</p>
                                                </li>
                                                <li>
                                                    <p>You license your clients to engage with the content in a manner that helps them improve.</p>
                                                </li>
                                                <li>
                                                    <p>We may retain content for a time after it has been deleted to comply with legal requirements.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>When viewing content</p>
                                            <ol>
                                                <li>
                                                    <p>No screenies (this is piracy).</p>
                                                </li>
                                                <li>
                                                    <p>View as many times as you like.</p>
                                                </li>
                                                <li>
                                                    <p>There is more than one way to do something, and none of the presented ideas are ours!</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Don’t try to break, cheat, copy, or impair the system.</p>
                                        </li>
                                        <li>
                                            <p>There might be links to other websites in the content you receive.&nbsp; What’s on there is not our fault, and frankly, none of our business.&nbsp; If it’s illegal, we will take action (see rule #1).</p>
                                        </li>
                                        <li>
                                            <p>If you have a way we can improve the platform, please let us know! We are a small team and can usually move pretty quickly.</p>
                                        </li>
                                        <li>
                                            <p>Standard, we aren’t liable for your experience, damages etc… (The stuff that is in every TOS on the planet)</p>
                                        </li>
                                    </ol>

                                    <b>1.&nbsp; SERVICE</b>
                                    <p>Grahamity Inc. “Grahamity” provides its users a place to share selected and user curated content in an extremely expedient manner.&nbsp; This shared space may be accessed on any device with an internet connection.</p>

                                    <p>It is our mission and goal to help coaches, counselors, and trainers of all fields and disciplines to enact a stronger impact with their clients through rapid, specific communications to supplement their in-person interactions. Or provide for Session-Specific Follow-Up in Seconds™.</p>

                                    <p>Sites to which these Terms of Service apply are as follows</p>
                                    <p><a href="https://www.grahamity.com">https://www.grahamity.com</a></p>
                                    <p><a href="https://www.grahamity.app">https://www.grahamity.app</a></p>
                                    <p>iOS app “Grahamity”</p>
                                    <p>Android app “Grahamity”</p>
                                    <p><a href="https://www.radix-na.com">https://www.Radix-NA.com</a> (Grahamity’s parent company)</p>

                                    <p>The content available through this service may include, but is not limited to video, audio, documentation, graphics, photos, text, metrics, and software (together “Content”) whether provided by you, a third party, Grahamity, or it’s affiliates.&nbsp; The presence of any particular piece of content does not reflect the views or opinions of Grahamity or it’s affiliates.&nbsp; Grahamity is not responsible for any action or inaction caused by the viewing, listening, downloading, installing, or distribution of content used in connection with our services. &nbsp;</p>
                                    <p>It is the responsibility of the uploading party to follow all DMCA and Licensing requirements required for distribution of content.&nbsp; Grahamity cannot be held liable for the distribution of any protected content.&nbsp; Grahamity will, however, limit access to and remove content in violation of intellectual property law.&nbsp; If you have concerns about a particular content violation please reach out to <a href="mailto:dmca@grahamity.com">dmca@grahamity.com</a> to report the offending content.</p>


                                    <b>2. <nbsp /> Legal Use</b>
                                    <p>The following restrictions apply to your use of the Service. You are not allowed to:</p>
                                    <ol>
                                        <li>
                                            <p>access, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter, modify or otherwise use any part of the Service or any Content except: (a) as expressly authorized by the Service; or (b) with prior written permission from Grahamity and, if applicable, the respective rights holders;</p>
                                        </li>
                                        <li>
                                            <p>circumvent, disable, fraudulently engage with, or otherwise interfere with any part of the Service (or attempt to do any of these things), including security-related features or features that (a) prevent or restrict the copying or other use of Content or (b) limit the use of the Service or Content;</p>
                                        </li>
                                        <li>
                                            <p>access the Service using any automated means (such as robots, botnets or scrapers) except (a) in the case of public search engines, in accordance with Grahamity’s robots.txt file; or (b) with Grahamity’s prior written permission;&nbsp;</p>
                                        </li>
                                        <li>
                                            <p>collect or harvest any information that might identify a person (for example, usernames or faces), unless permitted by that person or allowed under section (3) above;</p>
                                        </li>
                                        <li>
                                            <p>use the Service to distribute unsolicited promotional or commercial content or other unwanted or mass solicitations;</p>
                                        </li>
                                        <li>
                                            <p>cause or encourage any inaccurate measurements of genuine user engagement with the Service, including by paying people or providing them with incentives to increase a box’s views, engagement, or otherwise manipulate metrics in any manner;</p>
                                        </li>
                                        <li>
                                            <p>misuse any reporting, complaint, dispute, or appeals process, including by making groundless, vexatious, or frivolous submissions;</p>
                                        </li>
                                        <li>
                                            <p>run contests on or through the Service;</p>
                                        </li>
                                        <li>
                                            <p>use the Service to utilize Content other than for personal, non-commercial use, with the exception of content distributed internally for the company receiving the Content (for example, you may not publicly screen videos or stream music from the Service) without express permission of the Content originator; or</p>
                                        </li>
                                        <li>
                                            <p>use the Service to (a) sell any advertising, sponsorships, or promotions placed on, around, or within the Service or Content; or (b) sell advertising, sponsorships, or promotions on any page of any website or application that only contains Content from the Service</p>
                                        </li>
                                    </ol>
                                    <p>There are also some general restrictions on content. Grahamity may at any time remove content which does not follow our policies or which we deem defamatory or derogatory.&nbsp; This is subjective and we are not perfect.&nbsp; If you believe your content was deleted in error please contact <a href="mailto:datarecovery@grahamity.com">datarecovery@grahamity.com</a> and we will work with you to resolve the issue.&nbsp; Any content deleted as a result of a DMCA infringement, being illegal in nature, or copyright protected by a third party is not eligible for recovery.</p>
                                    <b>Content Restrictions</b>
                                    <ol>
                                        <li>
                                            <p>Illegal No uploaded content shall be permitted which is illegal in nature, encourages illegal actions, or attempts to circumvent judicial action.</p>
                                        </li>
                                        <li>
                                            <p>Copyright Protected User acknowledges all content uploaded is either their own intellectual property, created using the creative commons license, or has been granted distribution license from the copyright owner.</p>
                                        </li>
                                        <li>
                                            <p>Defamatory or Derogatory The definition of defamatory and derogatory changes regionally, culturally and daily.&nbsp; Uploading content which is racist in nature, or contains negative attitudes towards cultures, people groups, religions, or political ideology is allowed.&nbsp; The PROMOTION of such material (defined as broad distribution to the gross majority of contacts in your client list) is strictly prohibited.&nbsp; The discussion of such material is critical to the function of the platform and will remain a part of the service for the foreseeable future.&nbsp;</p>
                                        </li>
                                        <li>
                                            <p>DMCA Contact If you believe any content viewed or distributed on the platform is in violation of the DMCA, please email <a href="mailto:dmca@grahamity.com">dmca@grahamity.com</a></p>
                                        </li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>
                    </div>
            }
        </>
    )
}

export default TermsAndConditionsPage