import React from 'react'

const LeftPanel = ({ backgroundImage }) => {
    return (
        <div className='left d-none d-md-block'>
            <img src={backgroundImage} alt="backgroundImage" />
        </div>
    )
}

export default LeftPanel