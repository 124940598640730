import React, {useContext, useEffect, useState} from "react";
import {Col, Modal, Nav, Row, Tab} from "react-bootstrap";
import {useParams} from "react-router-dom";
import ImageCard from "../components/home/ImageCard";
import {MusicCard} from "../components/home/MusicCard";
import PdfFileCard from "../components/home/PdfFileCard";
// import TextFileCard from '../components/home/TextFileCard'
import VideoCard from "../components/home/VideoCard";
import Player from "../components/home/Player";
import ImageViewer from "../components/home/ImageViewer";

import {GetResourceClient} from "../services/auth/auth.services";
import {saveAs} from "file-saver";
import {UserDataContext} from "../App";
import Loader from "../components/loader/Loader";
import {error} from "../constants/msg";

import ImagePopup from "../components/popup/ImagePopup";
import LinkCard from "../components/home/LinkCard";
import PdfViewer from "../components/home/PdfViewer";

const CurrentSessionDescriptionPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [click, setClick] = useState(false);
  const { id } = useParams();
  const [userData, setUserData] = useContext(UserDataContext);
  const [boxes, setBoxes] = useState(null);
  const [clickedImage, setClickedImage] = useState(null);
  const [rowModalOpen, setRowModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [modalType, setModalType] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("windowWidth", windowWidth);

  useEffect(() => {
    const getResourceClientAndBoxes = async () => {
      try {
        const res = await GetResourceClient(
            userData.subadmin_user_id,
            false,
            userData.id,
            id
        );
        let data = res.data.data;
        // remove duplicate boxes from data
        // data = data.filter((value, index, self) =>
        //   index === self.findIndex((t) => (
        //     t.box_id == value.box_id && t.reciever_id == value.reciever_id && t.box_id == id
        //   ))
        // )
        setBoxes(...data);
        setIsLoading(false);
      } catch (e) {
        error("");
        setIsLoading(false);
      }
    };

    getResourceClientAndBoxes();
  }, [id]);

  const downloadFile = (url) => {
    saveAs(url);
  };

  const returnCard = (v, i) => {
    // if (v.type === "text") {
    //   return (
    //     <TextFileCard data={v} key={i} />
    //   )
    // }
    if (v.type === "pdf" || v.type === "document") {
      return (
          <PdfFileCard
              click={click}
              setClick={setClick}
              setSelectedData={setSelectedData}
              downloadFile={downloadFile}
              setRowModalOpen={setRowModalOpen}
              data={v}
              key={i}
              showTitle={true}
              redirect={true}
              setModalType={setModalType}
          />
      );
    } else if (v.type === "image") {
      return (
          <ImageCard
              click={click}
              setSelectedData={setSelectedData}
              setClick={setClick}
              downloadFile={downloadFile}
              setRowModalOpen={setRowModalOpen}
              data={v}
              key={i}
              setModalType={setModalType}

          />
      );
    } else if (v.type === "mp3" || v.type === "audio") {
      return (
          <MusicCard
              click={click}
              setSelectedData={setSelectedData}
              setClick={setClick}
              downloadFile={downloadFile}
              setRowModalOpen={setRowModalOpen}
              data={v}
              key={i}
              setModalType={setModalType}
          />
      );
    } else if (v.type === "video" || v.type === "mp4" || v.type === "m4v") {
      return (
          <VideoCard
              click={click}
              setSelectedData={setSelectedData}
              setClick={setClick}
              downloadFile={downloadFile}
              setRowModalOpen={setRowModalOpen}
              data={v}
              key={i}
              setModalType={setModalType}
          />
      );
    } else if (v.link) {
      return (
          <LinkCard
              data={v}
              click={click}
              setSelectedData={setSelectedData}
              setRowModalOpen={setRowModalOpen}
              setClick={setClick}
              downloadFile={downloadFile}
              key={i}
              showTitle={true}
              redirect={true}
              setModalType={setModalType}
          />
      );
    }
  };

  const returnCardInformation = (v, i) => {
    // if (v.type === "text") {
    //   return (
    //     <TextViewer data={v} key={i} />
    //   )
    // }
    if (v.type === "pdf" || v.type === "document") {

      const fileExtension = v.file_url.split('.').pop().toLowerCase();
      return (
          <div className={fileExtension === 'pdf' ? "pdfWrapper" : "docxWrapper"}>
            <PdfViewer data={v} key={i} />
          </div>
      );
    } else if (v.type === "image") {
      return <ImageViewer data={v} key={i} setClickedImage={setClickedImage} setModalType={setModalType} />;
    } else if (v.type === "mp3" || v.type === "audio") {
      return (
          <Player
              click={click}
              setClick={setClick}
              isAudioPlayer={true}
              show1="d-block"
              show2="d-none"
              paddingY="py-5"
              data={v}
              key={i}
          />
      );
    } else if (v.type === "video" || v.type === "mp4" || v.type === "m4v") {
      return (
          <Player
              isAudioPlayer={false}
              show1="d-block"
              show2="d-none"
              paddingY="py-5"
              data={v}
              key={i}
          />
      );
    } else if (v.link) {
      return <LinkCard data={v} key={i} showTitle={false} redirect={false} />;
    }
  };

  return (
      <>
        {clickedImage && <ImagePopup data={clickedImage} />}

        {isLoading ? (
            <div className="mt-4 text-center">
              <Loader />
            </div>
        ) : (
            <div className="current-session-description mt-4">
              {boxes ? (
                  <Tab.Container id="left-tabs-example" defaultActiveKey={`id-${0}`}>
                    <Row>
                      <Col
                          xs={12}
                          // xl={`${boxes.resources.length > 0 ? "6" : "12"}`}
                          xl={12}
                          className="custom-height mb-4 mb-xl-0"
                      >
                        <Nav variant="pills" className="flex-column pills-container">
                          <Row>
                            <Col xs={12}>
                              <div className="select-box border-bottom pb-2">
                                <h6 className="m-0 w-500">{boxes.box.name}</h6>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-4">
                            {boxes.resources.map((v, i) => {
                              return (
                                  <Col
                                      key={i}
                                      xs={6}
                                      sm={6}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      className="mb-4"
                                  >
                                    <Nav.Item>
                                      <Nav.Link className="p-0" eventKey={`id-${i}`}>
                                        {returnCard(v, i)}
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Col>
                              );
                            })}
                          </Row>
                        </Nav>
                      </Col>

                      {/*<Col xs={12} xl={6} className="hide_col_mobile">*/}
                      {/*  <Tab.Content className="">*/}
                      {/*    {boxes?.resources.map((v, i) => {*/}
                      {/*      return (*/}
                      {/*        <Tab.Pane*/}
                      {/*          key={i}*/}
                      {/*          className="h-100"*/}
                      {/*          eventKey={`id-${i}`}*/}
                      {/*        >*/}
                      {/*          <div className="right p-2 p-sm-4 h-100">*/}
                      {/*            <div className="content-container border p-4 h-100">*/}
                      {/*              <h4 className="title mb-3">{v.title}</h4>*/}
                      {/*              {returnCardInformation(v, i)}*/}
                      {/*            </div>*/}
                      {/*          </div>*/}
                      {/*        </Tab.Pane>*/}
                      {/*      );*/}
                      {/*    })}*/}
                      {/*  </Tab.Content>*/}
                      {/*</Col>*/}
                    </Row>
                  </Tab.Container>
              ) : (
                  <h3 className="text-muted">No resources are available</h3>
              )}
            </div>
        )}
        {/*{windowWidth && windowWidth <= 480 ? (*/}
        <Modal
            className="mediaModalClient logout-modal show_modal_mobile"
            size={modalType? "md" : "lg"}
            show={rowModalOpen}
            onHide={() => {
              setRowModalOpen(false);
            }}
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body className="modal_body">
            {selectedData != null ? (
                <div className="right p-2 p-sm-4 h-100">
                  <div className="content-container p-4 h-100">
                    {/*<h4 className="heading_modal mb-3">{selectedData.title}</h4>*/}
                    {returnCardInformation(selectedData, 0)}
                  </div>
                </div>
            ) : (
                ""
            )}
          </Modal.Body>
        </Modal>
        {/*) : (*/}
        {/*  ""*/}
        {/*)}*/}
      </>
  );
};

export default CurrentSessionDescriptionPage;
