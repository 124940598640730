import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { AddSessionPopupContext, UserDataContext } from "../../App"
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";

import { useNavigate } from "react-router-dom";
import { AddBox, CreateSession } from '../../services/auth/auth.services';

const AddSessionPopup = ({ sessionCreated, setSessionCreated }) => {
    const [handleAddSessionPopup, setHandleAddSessionPopup] = useContext(AddSessionPopupContext);
    const [userData, setUserData] = useContext(UserDataContext);
    const [isDisabled, setIsDisabled] = useState(false);

    const navigate = useNavigate();
    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        const { sessionTitle } = data
        setIsDisabled(true)
        try {
            const res = await CreateSession(userData.id, sessionTitle);
            reset({ sessionTitle: "" })
            setHandleAddSessionPopup(false)
            success("Company created successfully")
            setSessionCreated(true)
            setIsDisabled(false)
        } catch (e) {
            error(e.response.data.message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div>

            <Modal
                className='add-box-modal'
                show={handleAddSessionPopup}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    {/* <div className="header">
                        <div onClick={() => {
                            reset({ sessionTitle: "" })
                        }}
                            className="fa-times"><FaTimes /></div>
                    </div> */}

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Create your Company</h6>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='p-5'>
                                <div className='text-center'>
                                    <p>
                                        Please establish your company by entering your company name. If you do not have a company, feel free to use your name.
                                    </p>
                                </div>

                                <div className=''>
                                    <Form.Label>Company Name</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            maxLength={VALIDATIONS.SESSION_TITLE}
                                            autoComplete="off"
                                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                                            name="sessionTitle"
                                            type="text"
                                            autoFocus={true}
                                            placeholder="Enter company name"
                                            {...register("sessionTitle",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.SESSION_TITLE,
                                                        message: VALIDATIONS_TEXT.SESSION_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.SESSION_TITLE_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.sessionTitle &&
                                        <small className='text-red'>{errors.sessionTitle.message}</small>}
                                </div>
                                <div className='mt-5'>
                                    <Button disabled={isDisabled} type='submit' className="w-100">Create my company</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddSessionPopup