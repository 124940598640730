import React, {createContext, useEffect, useState} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {error} from './constants/msg'
import Navigation from './Navigation'
import {GetAuthUserLocalStorage, GetEssionsListing} from "./services/auth/auth.services"
import {roles, stripeCredentials} from "./utils/constants"
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from '@stripe/react-stripe-js';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "intro.js/introjs.css";

const NotificationPopupContext = createContext()
const ProfilePopupContext = createContext()
const NotesPopupContext = createContext()
const DeleteAccountPopupContext = createContext()
const LogoutPopupContext = createContext()
const AddGroupPopupContext = createContext()
const GroupsContext = createContext()
const SubAdminProfilePopupContext = createContext()
const AddBoxPopupContext = createContext()
const SubAdminLogoutPopupContext = createContext()
const CalendarPopupContext = createContext()
const AddClientPopupContext = createContext()
const AddByEmailPopupContext = createContext()
const RemoveResourcePopupContext = createContext()
const CurrentSessionContext = createContext()
const EditGroupPopupContext = createContext()
const EditBoxPopupContext = createContext()
const EditDeleteGroupPopupContext = createContext()


const ImagePopupContext = createContext()
const VideoPopupContext = createContext()
const AudioPopupContext = createContext()
const PdfPopupContext = createContext()

const UserDataContext = createContext()
const RemoveGroupPopupContext = createContext()
const RemoveBoxPopupContext = createContext()
const RemoveHistoryPopupContext = createContext()
const RemoveDeleteGroupPopupContext = createContext()
const StripeCardPopupContext = createContext()
const AddSessionPopupContext = createContext()
const AddChangeAbleContext = createContext()

const stripePromise = loadStripe(stripeCredentials.STRIPE_KEY);

const App = () => {
    const [handleNotificationPopup, setHandleNotificationPopup] = useState(false);
    const [handleProfilePopup, setHandleProfilePopup] = useState(false);
    const [handleNotesPopup, setHandleNotesPopup] = useState(false);
    const [handleDeleteAccountPopup, setHandleDeleteAccountPopup] = useState(false);
    const [handleLogoutPopup, setHandleLogoutPopup] = useState(false);
    const [handleAddGroupPopup, setHandleAddGroupPopup] = useState(false);
    const [handleGroups, setHandleGroups] = useState([]);
    const [handleAddBoxPopup, setHandleAddBoxPopup] = useState(false);
    const [handleAddClientPopup, setHandleAddClientPopup] = useState(false);
    const [handleAddByEmailPopup, setHandleAddByEmailPopup] = useState(false);
    const [handleRemoveResourcePopup, setHandleRemoveResourcePopup] = useState(false);
    const [handleEditGroupPopup, setHandleEditGroupPopup] = useState(false);
    const [handleEditBoxPopup, setHandleEditBoxPopup] = useState(false);
    const [handleEditDeletedGroupPopup, setHandleEditDeletedGroupPopup] = useState(false);
    const [handleRemoveDeletedGroupPopup, setHandleRemoveDeletedGroupPopup] = useState(false);
    const [handleStripeCardPopup, setHandleStripeCardPopup] = useState(false);
    const [handleAddSessionPopup, setHandleAddSessionPopup] = useState(false);


    const [handleSubAdminProfilePopup, setHandleSubAdminProfilePopup] = useState(false);
    const [handleCalendarPopup, setHandleCalendarPopup] = useState(false);

    const [handleImagePopup, setHandleImagePopup] = useState(false);
    const [handleVideoPopup, setHandleVideoPopup] = useState(false);
    const [handleAudioPopup, setHandleAudioPopup] = useState(false);
    const [handlePdfPopup, setHandlePdfPopup] = useState(false);

    const [userData, setUserData] = useState(GetAuthUserLocalStorage());
    const [handleCurrentSession, setHandleCurrentSession] = useState("");
    const [handleRemoveGroupPopup, setHandleRemoveGroupPopup] = useState(false);
    const [handleRemoveBoxPopup, setHandleRemoveBoxPopup] = useState(false);
    const [handleRemoveHistoryPopup, setHandleRemoveHistoryPopup] = useState(false);
    const [changeAble, setChangeAble] = useState(0);
    const getSessions = async (id) => {
        try {
            const res = await GetEssionsListing(id);
            if (res.data.data.length > 0) {
                setHandleCurrentSession(res.data.data[0].name)
            }
        } catch (e) {
            if (e.response.status == 401){
                localStorage.clear();
                window.location.href = "/signin/trainer";
            }
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        if (userData) {
            if (userData.role_id === roles.ROLE_USER) {
                getSessions(userData.subadmin_user_id)
            } else {
                getSessions(userData.id)
            }
        }
    }, [userData])
    return (
        <div className="App">
            <Elements stripe={stripePromise}>
                <AddChangeAbleContext.Provider value={[changeAble, setChangeAble]}>
                    <NotificationPopupContext.Provider value={[handleNotificationPopup, setHandleNotificationPopup]}>
                        <ProfilePopupContext.Provider value={[handleProfilePopup, setHandleProfilePopup]}>
                            <NotesPopupContext.Provider value={[handleNotesPopup, setHandleNotesPopup]}>
                                <DeleteAccountPopupContext.Provider
                                    value={[handleDeleteAccountPopup, setHandleDeleteAccountPopup]}>
                                    <LogoutPopupContext.Provider value={[handleLogoutPopup, setHandleLogoutPopup]}>
                                        <AddGroupPopupContext.Provider
                                            value={[handleAddGroupPopup, setHandleAddGroupPopup]}>
                                            <GroupsContext.Provider
                                                value={[handleGroups, setHandleGroups]}>
                                                <SubAdminProfilePopupContext.Provider
                                                    value={[handleSubAdminProfilePopup, setHandleSubAdminProfilePopup]}>
                                                    <AddBoxPopupContext.Provider
                                                        value={[handleAddBoxPopup, setHandleAddBoxPopup]}>
                                                        <CalendarPopupContext.Provider
                                                            value={[handleCalendarPopup, setHandleCalendarPopup]}>
                                                            <ImagePopupContext.Provider
                                                                value={[handleImagePopup, setHandleImagePopup]}>
                                                                <VideoPopupContext.Provider
                                                                    value={[handleVideoPopup, setHandleVideoPopup]}>
                                                                    <AudioPopupContext.Provider
                                                                        value={[handleAudioPopup, setHandleAudioPopup]}>
                                                                        <AddClientPopupContext.Provider
                                                                            value={[handleAddClientPopup, setHandleAddClientPopup]}>
                                                                            <AddByEmailPopupContext.Provider
                                                                                value={[handleAddByEmailPopup, setHandleAddByEmailPopup]}>
                                                                                <UserDataContext.Provider
                                                                                    value={[userData, setUserData]}>
                                                                                    <RemoveResourcePopupContext.Provider
                                                                                        value={[handleRemoveResourcePopup, setHandleRemoveResourcePopup]}>
                                                                                        <CurrentSessionContext.Provider
                                                                                            value={[handleCurrentSession, setHandleCurrentSession]}>
                                                                                            <PdfPopupContext.Provider
                                                                                                value={[handlePdfPopup, setHandlePdfPopup]}>
                                                                                                <RemoveGroupPopupContext.Provider
                                                                                                    value={[handleRemoveGroupPopup, setHandleRemoveGroupPopup]}>
                                                                                                    <RemoveBoxPopupContext.Provider
                                                                                                        value={[handleRemoveBoxPopup, setHandleRemoveBoxPopup]}>
                                                                                                        <RemoveHistoryPopupContext.Provider
                                                                                                            value={[handleRemoveHistoryPopup, setHandleRemoveHistoryPopup]}>
                                                                                                            <EditGroupPopupContext.Provider
                                                                                                                value={[handleEditGroupPopup, setHandleEditGroupPopup]}>
                                                                                                                <EditBoxPopupContext.Provider
                                                                                                                    value={[handleEditBoxPopup, setHandleEditBoxPopup]}>
                                                                                                                    <EditDeleteGroupPopupContext.Provider
                                                                                                                        value={[handleEditDeletedGroupPopup, setHandleEditDeletedGroupPopup]}>
                                                                                                                        <RemoveDeleteGroupPopupContext.Provider
                                                                                                                            value={[handleRemoveDeletedGroupPopup, setHandleRemoveDeletedGroupPopup]}>
                                                                                                                            <StripeCardPopupContext.Provider
                                                                                                                                value={[handleStripeCardPopup, setHandleStripeCardPopup]}>
                                                                                                                                <AddSessionPopupContext.Provider value={[handleAddSessionPopup, setHandleAddSessionPopup]}>
                                                                                                                                    <BrowserRouter>
                                                                                                                                        <Navigation />
                                                                                                                                    </BrowserRouter>
                                                                                                                                </AddSessionPopupContext.Provider>
                                                                                                                            </StripeCardPopupContext.Provider>
                                                                                                                        </RemoveDeleteGroupPopupContext.Provider>
                                                                                                                    </EditDeleteGroupPopupContext.Provider>
                                                                                                                </EditBoxPopupContext.Provider>
                                                                                                            </EditGroupPopupContext.Provider>
                                                                                                        </RemoveHistoryPopupContext.Provider>
                                                                                                    </RemoveBoxPopupContext.Provider>
                                                                                                </RemoveGroupPopupContext.Provider>
                                                                                            </PdfPopupContext.Provider>
                                                                                        </CurrentSessionContext.Provider>
                                                                                    </RemoveResourcePopupContext.Provider>
                                                                                </UserDataContext.Provider>
                                                                            </AddByEmailPopupContext.Provider>
                                                                        </AddClientPopupContext.Provider>
                                                                    </AudioPopupContext.Provider>
                                                                </VideoPopupContext.Provider>
                                                            </ImagePopupContext.Provider>
                                                        </CalendarPopupContext.Provider>
                                                    </AddBoxPopupContext.Provider>
                                                </SubAdminProfilePopupContext.Provider>
                                            </GroupsContext.Provider>
                                        </AddGroupPopupContext.Provider>
                                    </LogoutPopupContext.Provider>
                                </DeleteAccountPopupContext.Provider>
                            </NotesPopupContext.Provider>
                        </ProfilePopupContext.Provider>
                    </NotificationPopupContext.Provider>
                </AddChangeAbleContext.Provider>
            </Elements>
            <ToastContainer show />
        </div>
    )
}

export default App
export {
    AddSessionPopupContext,
    RemoveDeleteGroupPopupContext,
    EditDeleteGroupPopupContext,
    EditGroupPopupContext,
    EditBoxPopupContext,
    RemoveHistoryPopupContext,
    RemoveGroupPopupContext,
    RemoveBoxPopupContext,
    NotificationPopupContext,
    ProfilePopupContext,
    NotesPopupContext,
    DeleteAccountPopupContext,
    LogoutPopupContext,
    AddGroupPopupContext,
    GroupsContext,
    SubAdminProfilePopupContext,
    AddBoxPopupContext,
    SubAdminLogoutPopupContext,
    CalendarPopupContext,
    ImagePopupContext,
    VideoPopupContext,
    AudioPopupContext,
    AddClientPopupContext,
    AddByEmailPopupContext,
    UserDataContext,
    RemoveResourcePopupContext,
    CurrentSessionContext,
    PdfPopupContext,
    StripeCardPopupContext,
    AddChangeAbleContext
}

