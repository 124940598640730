import React, {useContext, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {RemoveGroupPopupContext} from "../../App"
import {useNavigate} from 'react-router-dom'
import {DeleteGroup} from "../../services/auth/auth.services"
import {error, success} from "../../constants/msg";
import {useDispatch} from "react-redux";
import {setGroupsData} from "../../redux/slices/groupSlice";


const RemoveBoxPopup = ({ redirectStatus, count, setCount, deletedGroupData, setDeletedGroupData, setShowSelectedResource, selectedGroup, setSelectedGroup, mergeGroupAndBoxes, setMergeGroupAndBoxes }) => {
    const [handleRemoveGroupPopup, setHandleRemoveGroupPopup] = useContext(RemoveGroupPopupContext);
    const [isDisabled, setIsDisabled] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const deleteGroup = async () => {
        const { id, index } = selectedGroup
        setIsDisabled(true)

        try {
            let temp = [...mergeGroupAndBoxes]
            const res = await DeleteGroup(id)
            temp.splice(index, 1)
            setMergeGroupAndBoxes(temp)
            dispatch(setGroupsData(temp));
            success(res.data.message)
            setSelectedGroup({
                index: "",
                id: "",
                name: ""
            })
            setCount(count + 1)
            setShowSelectedResource(null)
            setHandleRemoveGroupPopup(false)
            setIsDisabled(false)
            if (redirectStatus) {
                navigate("/sub-admin/manage-group")
            }
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(() => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div>
            <Modal
                className='logout-modal'
                show={handleRemoveGroupPopup}
                onHide={() => setHandleRemoveGroupPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleRemoveGroupPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Delete Group</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    {/* Deleting a group will move all boxes to the root layer */}
                                    Deleting a group will remove all boxes and the resources in it.
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button disabled={isDisabled} onClick={() => deleteGroup()} className="btn-common me-4">Delete</Button>
                                <Button onClick={() => setHandleRemoveGroupPopup(false)} className="btn-common no-bg">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RemoveBoxPopup