import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {CalendarIcon, SortIcon, TickIconOrange} from "../constants/svgs";
import {AddClientPopupContext, CalendarPopupContext, RemoveHistoryPopupContext, UserDataContext} from "../App";
import CalendarPopup from "../components/popup/CalendarPopup";
import AddByEmailPopup from "../components/popup/AddByEmailPopup";
import AddClientPopup from "../components/popup/AddClientPopup";
import {MdOutlineMail} from "react-icons/md";
import {GetResourceClientWithParams, GetUsersAddedBySubadmin,} from "../services/auth/auth.services";
import {error} from "../constants/msg";

import RemoveHistoryPopup from "../components/popup/RemoveHistoryPopup";
import {customTableStyles, roles} from "../utils/constants";
import DataTable from 'react-data-table-component';
import Loader from "../components/loader/Loader";

const ODD_OPACITY = 0.2;

const ClientHistoryPage = (props) => {
    const [loading, setLoading] = useState(true);
    const [datatableLoading, setDatatableLoading] = useState(false);
    const [handleRemoveHistoryPopup, setHandleRemoveHistoryPopup] = useContext(RemoveHistoryPopupContext);
    const [userData, setUserData] = useContext(UserDataContext)
    const [handleCalendarPopup, setHandleCalendarPopup] = useContext(CalendarPopupContext);
    const [handleAddClientPopup, setHandleAddClientPopup] = useContext(AddClientPopupContext);
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [text, setText] = useState("")
    const [patternError, setPatternError] = useState(false)
    const [rows, setRows] = useState([])
    const [tempArray, setTempArray] = useState([])
    const [selectedHistory, setSelectedHistory] = useState("")
    const [uploadedClientData, setUploadedClientData] = useState([])
    const [active, setActive] = useState(true)
    const [count, setCount] = useState(0)
    const [isFiltered, setIsFiltered] = useState(false)
    const [isFilteredByEmail, setFilteredByEmail] = useState(false)

    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [totalRecord, setTotalRecord] = useState(0)

    const [perPageHistory, setPerPageHistory] = useState(10)
    const [pageHistory, setPageHistory] = useState(1)
    const [totalHistoryRecord, setTotalHistoryRecord] = useState(0)
    const [totalRows, setTotalRows] = useState(0);

    const [receiver, setReceiver] = useState(null)
    const [sendDate, setSendDate] = useState(null)

    const { register, handleSubmit } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => { };

    const clientHistoryColumn = [
        {
            name: 'Boxes',
            selector: row => row.box_name,
            sortable: true,
        },
        {
            name: 'Send Date',
            selector: row => row.send_date,
            sortable: true,
        },
        {
            name: 'Open',
            selector: row => row.open,
            sortable: true,
        },
        {
            name: 'Professional',
            selector: row => row.professional,
            sortable: true,
        },
    ];



    const handleFilter = (date) => {
        // let filteredData = tempArray.filter((data) => {
        //     return data.send_date === date;
        // });
        // setRows(filteredData);
        setSendDate(date)
    };

    const clearFilter = () => {
        setSendDate(null)
        setIsFiltered(false)
    }

    const clearFilterByEmail = () => {
        // setRows(tempArray)
        setSelectedUser(null)
        setFilteredByEmail(false)
        setReceiver(null)
    }

    const handleFilterByEmail = (selected_data) => {
        // let filteredData = tempArray.filter((data) => {
        //     return data.client_email === selected_data.email;
        // });
        setFilteredByEmail(true)
        // setRows(filteredData)
        // // setTempArray(filteredData)
        // console.log("selected_data.id", selected_data.id)
        setReceiver(selected_data.id)
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const returnSessionName = (date, session_id) => {
        let str = "";
        let month = "";
        date = date.split("-");

        if (date[1] == "01" || date[1] == "1") {
            month = "Jan";
        } else if (date[1] == "02" || date[1] == "2") {
            month = "Feb";
        } else if (date[1] == "03" || date[1] == "3") {
            month = "Mar";
        } else if (date[1] == "04" || date[1] == "4") {
            month = "Apr";
        } else if (date[1] == "05" || date[1] == "5") {
            month = "May";
        } else if (date[1] == "06" || date[1] == "6") {
            month = "Jun";
        } else if (date[1] == "07" || date[1] == "7") {
            month = "Jul";
        } else if (date[1] == "08" || date[1] == "8") {
            month = "Aug";
        } else if (date[1] == "09" || date[1] == "9") {
            month = "Sep";
        } else if (date[1] == "10" || date[1] == "10") {
            month = "Oct";
        } else if (date[1] == "11" || date[1] == "11") {
            month = "Nov";
        } else {
            month = "Dec";
        }

        str = `Company ` + month + "-" + date[2] + "-" + date[0];
        return str;
    };

    useEffect(() => {
        // getResourceClient();
        getAllUsersBySubAdmin();
    }, []);

    useEffect(() => {
        setUploadedClientData([])
        getUsersAddedBySubAdmin();
    }, [count, perPage, page]);

    useEffect(() => {
        setRows([]);
        getResourceClient();
    }, [perPageHistory, pageHistory, receiver, sendDate]);

    const getUsersAddedBySubAdmin = async () => {
        let array = [];
        try {
            setLoading(true)
            await GetUsersAddedBySubadmin(userData.id, roles.ROLE_USER, true, perPage, page).then(
                (res) => {
                    let data = res.data.data.data;
                    data.map((v, i) => {
                        array.push({
                            id: v?.id,
                            name: v?.name || "-",
                            email: v?.email || "-",
                            phone: v?.details?.phone || "-",
                            // location: v?.details?.address || "-",
                        });
                    });
                    setUploadedClientData(array);
                    setLoading(false)
                    setTotalRecord(res?.data?.data?.total)
                }
            );
        } catch (e) {
            setLoading(false)
            error(e.response.data.errors[0].message);
        }
    };

    const getAllUsersBySubAdmin = async () => {
        try {
            setLoading(true)
            await GetUsersAddedBySubadmin(userData.id, roles.ROLE_USER).then(
                (res) => {
                    let data = res?.data?.data;
                    setUsers(data);
                }
            );
        } catch (e) {
            setLoading(false)
            error(e.response.data.errors[0].message);
        }
    };

    const getResourceClient = async () => {
        let array = [];
        setDatatableLoading(true)

        try {
            let params = {}
            params['orderBy'] ='id'
            params['sortedBy'] ='desc'
            if(userData.id){
                params['subadmin_user_id']= userData.id
            }

            if (pageHistory) {
                params['page'] = pageHistory;
            }

            if (perPageHistory) {
                params['per_page'] = perPageHistory;
            }

            if (sendDate !== null) {
                params['send_date'] = sendDate;
            }

            if (receiver) {
                params['reciever_id'] = receiver;
            }

            params['paginate'] = true;

            await GetResourceClientWithParams(params).then((res) => {
                let data = res.data.data;
                setTotalHistoryRecord(res?.data?.meta?.total)
                data.map((v, i) => {
                    array.push({
                        id: v.id,
                        box_name: v.box?.name || "-",
                        session: returnSessionName(
                            v.created_at?.split(" ")[0],
                            v.box?.session_id
                        ),
                        send_date: v.created_at?.split(" ")[0] || "-",
                        professional: v.sender?.name || "-",
                        client_email: v.reciever?.email,
                        open: v.open_box == null ? "-"  : <TickIconOrange />,
                        action: "",
                    });
                });
                setRows(array);

                setTempArray(array);
                // setTotalRows(res?.data?.meta?.total)
                setDatatableLoading(false)
            });
        } catch (e) {
            setDatatableLoading(false)
            error(e.response.data.message);
        }
    };
    const filterdData = users.filter((value) => {
        return value.email.toLowerCase().includes(text.toLowerCase());
    });

    // const HistoryComponent = () => {
    //     return (
    //         <div style={{ height: 595 }} className="w-100">
    //             <StripedDataGrid
    //                 disableColumnMenu
    //                 disableColumnFilter
    //                 disableColumnSelector
    //                 getRowClassName={(params) =>
    //                     params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
    //                 }
    //                 className="data-table"
    //                 rows={rows}
    //                 columns={columnsHistory}
    //                 localeText={customLocaleText}
    //             />
    //         </div>
    //     );
    // };

    // const ClientComponent = () => {
    //     return (
    //         <div style={{ height: 595 }} className="mt-3 w-100">
    //             <NewStripedDataGrid
    //                 disableColumnMenu
    //                 disableColumnFilter
    //                 disableColumnSelector
    //                 getRowClassName={(params) =>
    //                     params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
    //                 }
    //                 className="data-table"
    //                 rows={uploadedClientData}
    //                 columns={columnsClient}
    //                 localeText={customLocaleText}
    //             />
    //         </div>
    //     );
    // };

    useEffect(() => {
        if (props.triggerStepAction === 1) {
            setActive(false);
        }
    }, [props.triggerStepAction]);

    const addNewCount = (newCount) => {
        setCount(newCount);
        if (props.shouldIntroReopen && newCount > 0) {
            props.setIntroFlags({ ...props.introFlags, hasFirstClient: true });
            props.setShouldIntroReopen(false);
            props.onHelp(7);
        }
    }

    return (
        <div className='client-history-page general_layout'>
            {handleCalendarPopup && <CalendarPopup handleFilter={handleFilter} setIsFiltered={setIsFiltered} />}
            <AddByEmailPopup count={count} setCount={setCount} />
            <AddClientPopup count={count} setCount={setCount} />
            <RemoveHistoryPopup rows={rows} setRows={setRows} setTempArray={setTempArray} selectedHistory={selectedHistory} />

            <Row className=" h-100">
                <Col md={6} lg={4} xxl={3} className="mb-4 h-100">
                    <div
                        className={`search-container h-100 p-3 ${!active && "d-flex align-items-center justify-content-center"
                        }`}
                    >
                        <div className={`holder p-4 ${!active && "d-none"} `}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Label>Search Client</Form.Label>
                                <InputGroup className="">
                                    <Form.Control
                                        onChange={(e) => {
                                            // if (!isValidEmail(e.target.value)) {
                                            //     setPatternError(true);
                                            // } else {
                                            //     setPatternError(false);
                                            // }
                                            setText(e.target.value.trim());
                                        }}
                                        value={text}
                                        name="search"
                                        type="text"
                                        autocomplete="off"
                                        placeholder="Lookup client history by e-mail"
                                    />
                                </InputGroup>
                                {(filterdData.length <= 0 && text.length > 0) &&
                                <small className='text-red'>No result found</small>}

                                {text.length > 0 && filterdData.length > 0 && (
                                    <div className="suggestion-holder bg-white">
                                        <div className="search-suggestion">
                                            <ul>
                                                {
                                                    filterdData.map((v, i) => (
                                                        <li
                                                            key={i}
                                                            onClick={() => {
                                                                setText("")
                                                                setSelectedUser(v)
                                                                handleFilterByEmail(v)
                                                            }}
                                                        ><MdOutlineMail className='text-dark me-3' />{v.email}</li>
                                                    ))

                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                <Button type="submit" className="mt-4 w-100">
                                    Search
                                </Button>
                            </Form>
                        </div>

                        <div className={`holder p-4 `}>
                            <Row>
                                <Col
                                    xs={5}
                                    lg={12}
                                    xl={5}
                                    xxl={6}
                                    className="mb-lg-2 mb-xl-4 mb-4"
                                >
                                    <h6 className="fw-600 p-0 m-0">Current Client: </h6>
                                </Col>
                                <Col xs={7} lg={12} xl={7} xxl={6} className="mb-4">
                                    <h6 className="p-0 m-0">
                                        {selectedUser ? selectedUser.name : "-"}
                                    </h6>
                                </Col>

                                <Col
                                    xs={5}
                                    lg={12}
                                    xl={5}
                                    xxl={6}
                                    className="mb-lg-2 mb-xl-4 mb-4"
                                >
                                    <h6 className="fw-600 p-0 m-0">Email: </h6>
                                </Col>
                                <Col xs={7} lg={12} xl={7} xxl={6} className="mb-4">
                                    <h6 className="p-0 m-0">
                                        {selectedUser ? selectedUser.email : "-"}
                                    </h6>
                                </Col>

                                <Col
                                    xs={5}
                                    lg={12}
                                    xl={5}
                                    xxl={6}
                                    className="mb-lg-2 mb-xl-4 mb-4"
                                >
                                    <h6 className="fw-600 p-0 m-0">Contact Number#: </h6>
                                </Col>
                                <Col xs={7} lg={12} xl={7} xxl={6} className="mb-4">
                                    <h6 className="p-0 m-0">
                                        {selectedUser ? selectedUser.details.phone : "-"}
                                    </h6>
                                </Col>

                                <Col
                                    xs={5}
                                    lg={12}
                                    xl={5}
                                    xxl={6}
                                    className="mb-lg-2 mb-xl-4 mb-4"
                                >
                                    <h6 className="fw-600 p-0 m-0">Address: </h6>
                                </Col>
                                <Col xs={7} lg={12} xl={7} xxl={6} className="mb-4">
                                    <h6 className="p-0 m-0 text-break">
                                        {selectedUser ? selectedUser.details.address : "-"}
                                    </h6>
                                </Col>
                                <Col xs={12} lg={12} xl={12} xxl={12} className="mb-4">
                                    {
                                        isFilteredByEmail ?
                                            <Button className="mt-4 w-100" onClick={() => clearFilterByEmail()}>Clear Filter by Email</Button>
                                            :
                                            ''
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div className={`w-100 `}>
                            <Row>
                                <Col xs={12}>
                                    <Button
                                        id="add-client-btn"
                                        onClick={() => {
                                            props.onCloseIntro();
                                            if (props.stepsEnabled) {
                                                props.setShouldIntroReopen(true);
                                            }
                                            setHandleAddClientPopup(true);
                                        }}
                                        className="w-100"
                                    >
                                        Add Client
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>

                <Col md={6} lg={8} xxl={9} className="mb-4 h-100">
                    <div className="right h-100 table-container p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h6 class="blue-color fw-500 m-0">Client History</h6>
                            {
                                isFiltered ?
                                    <div className='text-end'>
                                        <Button className="btn-clear-filter mb-3 mt-3" onClick={() => clearFilter()}>Clear Filter</Button>
                                    </div>
                                    :
                                    <div className="border box d-flex p-2" onClick={() => setHandleCalendarPopup(true)}>
                                        <span className='d-inline-block me-4'>Filter By Date</span>
                                        <CalendarIcon />
                                    </div>
                            }
                        </div>
                        {/* <MemoizedHistoryComponent /> */}
                        <div className="mt-3 w-100 h-100">
                            {datatableLoading ?
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <Loader />
                                </div> : <DataTable
                                    data={rows}
                                    columns={clientHistoryColumn}
                                    customStyles={customTableStyles}
                                    // fixedHeader
                                    // fixedHeaderScrollHeight='500px'
                                    progressPending={datatableLoading}
                                    responsive
                                    selectableRows={false}
                                    striped
                                    noRowsPerPage={true}
                                    highlightOnHover={false}
                                    showSortable={false}
                                    pagination
                                    paginationPerPage={perPageHistory}
                                    // paginationRowsPerPageOptions={[5, 10, 20, 40, 60, 80, 100]}
                                    paginationTotalRows={totalHistoryRecord}
                                    paginationServer
                                    sortIcon={<SortIcon />}
                                    paginationDefaultPage={pageHistory}
                                    onChangePage={(newPage) => {
                                        setPageHistory(newPage);
                                    }}
                                    onChangeRowsPerPage={(currentRowsPerPage) => {
                                        setPerPageHistory(currentRowsPerPage);
                                    }}
                                    // progressComponent={}
                                    noDataComponent={'No record available'}
                                />}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ClientHistoryPage;
