import {errorMessages, s3Credential} from "./constants";
import AWS from 'aws-sdk'
import axios from 'axios';

window.Buffer = window.Buffer || require("buffer").Buffer;

// export const UploadFileToS3 = async (file, dirName) => {
//     let newFileName = file.name;
//     if (file.name.split("")[0] === ".") {
//         newFileName = newFileName.slice(1, newFileName.length)
//     }
//     newFileName = newFileName.split(" ").join("-")
//
//     const fileSize = file.size;
//
//     //Check filesize
//     if (fileSize > parseInt(s3Credential.fileSize, 10)) {
//         return {
//             success: false,
//             message: errorMessages.fileSize
//         }
//     }
//
//     const config = {
//         bucketName: s3Credential.bucketName,
//         dirName: dirName,
//         region: s3Credential.region,
//         accessKeyId: s3Credential.accessKeyId,
//         secretAccessKey: s3Credential.secretAccessKey,
//         s3Url: s3Credential.s3EndPoint,
//     };
//
//     const ReactS3Client = new S3(config);
//     let responseData = {};
//     file = file.hasOwnProperty('originFileObj') ? file.originFileObj : file
//     await ReactS3Client.uploadFile(file, newFileName)
//         .then((data) => {
//             if (data && data.status === 204) {
//                 responseData = {
//                     success: true,
//                     message: errorMessages.fileSuccess,
//                     url: s3Credential.s3EndPoint + dirName + "/" + newFileName,
//                     mime_type: file.type
//                 }
//             } else {
//                 responseData = {
//                     success: false,
//                     message: errorMessages.fileError
//                 }
//             }
//         })
//     return responseData;
// }

export const UploadFileToS3 = async (file, dirName, onProgress) => {

    // const s3Credentials = {
    //     bucketName: s3Credential.bucketName,
    //     dirName: dirName,
    //     region: s3Credential.region,
    //     accessKeyId: s3Credential.accessKeyId,
    //     secretAccessKey: s3Credential.secretAccessKey,
    //     s3Url: s3Credential.s3EndPoint,
    // };

    let newFileName = file.name;
    if (file.name.split("")[0] === ".") {
        newFileName = newFileName.slice(1, newFileName.length);
    }
    newFileName = newFileName.split(" ").join("-");

    const fileSize = file.size;

    // Check filesize
    if (fileSize > parseInt(s3Credential.fileSize, 10)) {
        return {
            success: false,
            message: errorMessages.fileSize,
        };
    }

    // Configure AWS SDK
    AWS.config.update({
        accessKeyId: s3Credential.accessKeyId,
        secretAccessKey: s3Credential.secretAccessKey,
        region: s3Credential.region,
    });

    const s3 = new AWS.S3();
    const params = {
        Bucket: s3Credential.bucketName,
        Key: `${dirName}/${newFileName}`,
        ContentType: file.type,
        ACL: 'public-read'
    };

    // Get signed URL
    const signedUrl = await s3.getSignedUrlPromise('putObject', params);

    // Upload the file using Axios to track progress
    let responseData = {};
    file = file.hasOwnProperty('originFileObj') ? file.originFileObj : file;
    await axios.put(signedUrl, file, {
        headers: {
            'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (onProgress) {
                onProgress(progress);
            }
        },
    })
        .then((response) => {
            if (response.status === 200) {
                responseData = {
                    success: true,
                    message: errorMessages.fileSuccess,
                    url: `${s3Credential.s3EndPoint}${dirName}/${newFileName}`,
                    mime_type: file.type,
                };
            } else {
                responseData = {
                    success: false,
                    message: errorMessages.fileError,
                };
            }
        })
        .catch((error) => {
            responseData = {
                success: false,
                message: errorMessages.fileError,
            };
        });

    return responseData;
};

export const UploadFileToS3WithProgressBar = function (file, dirName) {
    const bucket = new AWS.S3({
        accessKeyId: s3Credential.accessKeyId,
        secretAccessKey: s3Credential.secretAccessKey,
        region: s3Credential.region,
        dirName: dirName,

    });

    let newFileName = file.name;
    if (file.name.split("")[0] === ".") {
        newFileName = newFileName.slice(1, newFileName.length)
    }
    newFileName = newFileName.split(" ").join("-")

    const fileSize = file.size;

    //Check filesize
    if (fileSize > parseInt(s3Credential.fileSize, 10)) {
        return {
            success: false,
            message: errorMessages.fileSize
        }
    }

    const fileExtension = newFileName.split('.').pop()
    const newFileNameWithMillis =
        `${newFileName.slice(0, newFileName.length - fileExtension.length - 1)}_${Date.now()}.${fileExtension}`

    const params = {
        Bucket: s3Credential.bucketName,
        Key: dirName + '/' + newFileNameWithMillis,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
    };

    return bucket.upload(params, function (err, data) {
        if (err) {
            return false;
        }
       
        return true;
    });
}
export const returnFileType = (file) => {
    let fileType = file.name.split(".")
    fileType = fileType[fileType.length - 1].toLowerCase()

    if (fileType === "png" || fileType === "jpg" || fileType === "jpeg" || fileType === "jfif" || fileType === "svg" || fileType === "webp") {
        fileType = "image"
    }
    else if (fileType === "pdf" || fileType === "docx" || fileType === "doc" || fileType === "txt" || fileType === "xlsx" || fileType === "tiff" || fileType === "html") {
        fileType = "document"
    }
    else if (fileType === "mp3") {
        fileType = "mp3"
    }
    else if (fileType === "mp4" || fileType === "mov" || fileType === "m4v") {
        fileType = "video"
    }
    else if (fileType === "zip") {
        fileType = "archive"
    }
    else {
        fileType = "invalid"
    }
    return fileType
}
export const downloadFromUrl = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

