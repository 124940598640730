import React, {useContext, useEffect, useState} from 'react'
import CurrentSessionCard from '../components/home/CurrentSessionCard'
import {Accordion, Col, Row} from 'react-bootstrap'

import {GetResourceClient} from '../services/auth/auth.services'
import {UserDataContext} from "../App"
import {error} from '../constants/msg'
import Loader from '../components/loader/Loader'
import ForcePasswordPopup from '../components/popup/ForcePasswordPopup'

const CurrentSessionPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [userData, setUserData] = useContext(UserDataContext);
  const [boxes, setBoxes] = useState([]);
  const [resetPasswordPopup, setResetPasswordPopup] = useState(false)

  useEffect(() => {
    const getResourceClientAndBoxes = async () => {
      try {
        const res = await GetResourceClient(userData.subadmin_user_id, true, userData.id)
        let data = res?.data?.data
        // remove duplicate boxes from data
        // data = data.filter((value, index, self) =>
        //   index === self.findIndex((t) => (
        //     t.box_id == value.box_id && t.reciever_id == value.reciever_id
        //   ))
        // )

        setBoxes(data)
        setIsLoading(false)
      }
      catch (e) {
        error("")
        setIsLoading(false)
      }
    }
    getResourceClientAndBoxes()

    if (userData.change_password === 0 && userData.remind_password_change_time === null) {
      setResetPasswordPopup(true)
    } else if (userData.change_password === 0 && userData.remind_password_change === 1) {
      var g1 = new Date();
      var g2 = new Date(userData.remind_password_change_time);
      if (g1.getTime() > g2.getTime()) {
        setResetPasswordPopup(true)
      }
    }
  }, [userData])

  return (
    <div className='current-session-page'>
      {/* <NotesPopup /> */}
      {
        isLoading ?
          <div className='text-center'>
            <Loader />
          </div>
          :
          <Row>
            <ForcePasswordPopup resetPasswordPopup={resetPasswordPopup} setResetPasswordPopup={setResetPasswordPopup} />

            <Col xs={12} sm={12}>
              {
                Object.keys(boxes).length > 0 ?
                  <Accordion defaultActiveKey="0">
                    {
                      Object.keys(boxes).map((data, index) => (
                        <Accordion.Item eventKey={index} className="mb-3">
                          <Accordion.Header>{data}</Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              {
                                boxes[data].map((v, i) => (
                                  <Col key={i} xl={3} xs={12} sm={6} md={6} lg={4} className="mb-4">
                                    <CurrentSessionCard index={i} data={v} groupBy={true} />
                                  </Col>
                                ))
                              }
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))
                    }
                  </Accordion>
                  :
                  <h3 className='text-muted'>No box is available</h3>
              }
            </Col>
          </Row>

      }
    </div>
  )
}

export default CurrentSessionPage