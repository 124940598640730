import React, {useContext, useState} from 'react'
import {Button, Form, InputGroup, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {AddBoxPopupContext} from "../../App"
import {useForm} from "react-hook-form";
import {VALIDATIONS, VALIDATIONS_TEXT} from '../../constants/app-constants';

import {useNavigate} from "react-router-dom";
import ThemeModalLayout from './ThemeModalLayout';
import {error, success} from "../../constants/msg";
import Loader from "../loader/Loader";
import {UploadPngIcon} from '../../constants/svgs';
import {Upload} from 'antd';
import {UploadFileToS3} from '../../utils/helpers';
import {useDispatch} from "react-redux";
import {AddBox} from "../../services/auth/auth.services";

const AddBoxPopup = ({ selectedGroup, mergeGroupAndBoxes, setMergeGroupAndBoxes, sortingOrder }) => {
    const [handleAddBoxPopup, setHandleAddBoxPopup] = useContext(AddBoxPopupContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false)
    const [url, setUrl] = useState("")
    const [fileList, setFileList] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { register:register1, control:control1, handleSubmit:handleSubmit1, reset:reset1, setValue:setValue1, formState: { errors:errors1 } } = useForm({
        mode: "onChange1"
    });
    const { register:register2, control:control2, handleSubmit:handleSubmit2, reset:reset2, setValue:setValue2, formState: { errors:errors2 } } = useForm({
        mode: "onChange2"
    });


    const returnMatchedGroupIndex = () => {
        for (let i = 0; i < mergeGroupAndBoxes.length; i++) {
            if (mergeGroupAndBoxes[i].id === selectedGroup.id) {
                return i
            }
        }
    }

    const onSubmit = async (data) => {
        setValue2('lessonTitle',data.lessonTitle)
        setShowToolTip(true);
        setIsDisabled(false)
        setHandleAddBoxPopup(false)
        // Note For dev: Adjust the API's call according to the need.
    }

    const addNewData = (index, newlyAddedData) => {
        setMergeGroupAndBoxes(prevState => {
            const newState = [...prevState];
            newState[index] = {
                ...newState[index],
                boxes: [...newState[index].boxes, newlyAddedData]
            };
            return newState;
        });
    };

    const onSubmitToolTip = async (data) => {
        const id = selectedGroup.id
        const { lessonTitle, description } = data

        setIsDisabled(true)
        try {
            const index = returnMatchedGroupIndex()

            // Clone the entire mergeGroupAndBoxes array to avoid mutating state directly
            const newMergeGroupAndBoxes = [...mergeGroupAndBoxes];

            // Clone the specific boxes array
            // let groupBoxes = [...newMergeGroupAndBoxes[index].boxes];

            const groupBoxes = mergeGroupAndBoxes[index].boxes
            const newBoxPosition = groupBoxes.length > 0 ? groupBoxes[groupBoxes.length - 1].position != 0 ? groupBoxes[groupBoxes.length - 1].position + 1 : groupBoxes.length + 1 : 1
            let thumbnail = null;
            if (fileList.length > 0) {
                let s3Response = await UploadFileToS3(fileList[0], "boxes")
                thumbnail = s3Response.url
            }
            await AddBox(id, thumbnail, lessonTitle,description, 1, newBoxPosition).then((res) => {
                const newlyAddedData = res?.data?.data
                newlyAddedData.box_resources = []
                newlyAddedData.resources = []
                if (sortingOrder === 'desc') {
                    mergeGroupAndBoxes[index].boxes.unshift(newlyAddedData)
                    setMergeGroupAndBoxes([...mergeGroupAndBoxes])
                } else {
                    addNewData(index, newlyAddedData)
                }
                setFileList([]);
                success(res.data.message)
                reset1()
                reset2()
                setHandleAddBoxPopup(false)
                setShowToolTip(false);
                setIsDisabled(false)
            })
        }
        catch (e) {
            setShowToolTip(false);
            setHandleAddBoxPopup(true);
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    const hideToolTipModal = () => {
        setShowToolTip(false)
        setIsDisabled(false)
    }

    // const validateTooltipText = (value) => {
    //     if (value?.trim() === '') {
    //         return 'Tooltip text cannot be empty or just whitespace';
    //     }
    //     return true;
    // };

    const beforeUpload = (file) => {
        return false;
    };
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const disallowedTypes = [
        'application/x-msdownload',
        'application/x-msdos-program',
        'application/vnd.android.package-archive',
        'text/html',
        'application/javascript',
        'text/javascript',
        'text/css',
        'application/octet-stream',
        'application/zip',
        'application/x-zip-compressed',
        'application/pdf',
        'application/sql',
        'stl',
        'video/mp4',
        'video/webm',
        'video/ogg',
        'video/avi',
        'video/mpeg',
        'video/quicktime',
        'video/x-msvideo',
        'video/x-ms-wmv',
        'video/x-flv'
    ];

    const handleChange = ({ fileList }) => {
        const filteredFiles = fileList.filter(file => {
            const fileType = file.type || file.name.split('.').pop().toLowerCase();
            if (disallowedTypes.includes(fileType)) {
                error(`${file.name} file is not allowed.`)
                return false;
            }
            return true;
        });


        if (filteredFiles.length > 1) {
            error('You can only upload one file.');
            return false;
            filteredFiles.splice(0, filteredFiles.length - 1);
        }
        setFileList(filteredFiles);
    };

    return (
        <>
            <Modal
                className='add-box-modal'
                show={handleAddBoxPopup}
                onHide={() => {
                    setHandleAddBoxPopup(false)
                    reset1()
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => {
                            setHandleAddBoxPopup(false)
                            reset1()
                        }}
                             className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Add Box</h6>
                        </div>

                        <Form onSubmit={handleSubmit1(onSubmit)}>
                            <div className='p-5'>
                                {/* <div className='text-center'>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the look.
                                    </p>
                                </div> */}

                                <div className="mb-2">
                                    <Form.Label>Add Thumbnail</Form.Label>
                                    <Upload fileList={fileList}
                                            onChange={handleChange}
                                            beforeUpload={beforeUpload}
                                            accept='.png, .jpg, .jpeg'
                                            multiple={false}
                                    >
                                        <UploadPngIcon />
                                    </Upload>
                                    {fileList.length == 0 ? <img width="50%" src={imageUrl} className={"box-thumbnail mt-3 mb-3"} /> : null}
                                </div>

                                <div className=''>
                                    <Form.Label>Box Title</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            maxLength={VALIDATIONS.LESSON_TITLE}
                                            autoComplete="off"
                                            // onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                                            name="lessonTitle"
                                            type="text"
                                            autoFocus={true}
                                            placeholder="Enter box title"
                                            {...register1("lessonTitle",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.LESSON_TITLE,
                                                        message: VALIDATIONS_TEXT.LESSON_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.LESSON_TITLE_REQUIRED
                                                    },
                                                    validate: (value) => {
                                                        //     // Custom validation to disallow leading and trailing spaces
                                                        //     if (value.trim() !== value) {
                                                        //         return "White spaces are not allowed";
                                                        //     }
                                                        const alphabetCount = value.match(/[a-zA-Z]/g)?.length || 0; // Count alphabets
                                                        if (alphabetCount < 2) {
                                                            return "Box title must contain at least 2 alphabets";
                                                        }
                                                        return true;
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors1.lessonTitle && <small className='text-red'>{errors1.lessonTitle.message}</small>}

                                </div>

                                <div className='mt-5'>
                                    <Button disabled={isDisabled} type='submit' className="w-100">Add</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ToolTip Modal */}


            <ThemeModalLayout
                show={showToolTip}
                onhide={hideToolTipModal}
                title={"Tool tip"}
                size={"md"}

            >
                <Form onSubmit={handleSubmit2(onSubmitToolTip)} className="toolTipForm p-4">
                    <Form.Label>Tooltip Text</Form.Label>
                    <InputGroup className='mb'>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            resize={false}
                            placeholder="What best describes what you put in the box?
                                        example: These resources will help
                                        paint a clear picture of who Bob Marley was, from his music, to his beliefs and
                                        preferences.
                                        This will become a tooltip for your client
                                        to preview box contents."
                            className={errors2.description ? "hasError" : ""}
                            {...register2('description', {
                                maxLength: {
                                    value: 255,
                                    message: "Tooltip text must not exceed 255 characters"
                                },
                                // validate: validateTooltipText
                            })}

                        />
                    </InputGroup>
                    {errors2.description && (
                        <Form.Text>{errors2.description.message}</Form.Text>
                    )}

                    <>
                        {
                            isDisabled ?
                                <div className='text-center mt-4'>
                                    <Loader />
                                </div>
                                :
                                <div className='text-center'>
                                    <Button disabled={isDisabled} type="submit" className="w-100 mt-4">Sounds Good</Button>
                                </div>
                        }
                    </>
                </Form>
            </ThemeModalLayout>

        </>
    )
}

export default AddBoxPopup
