import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    selectedTemplate: null,
    renderDatatable: false,
    userTemplates: [],
    defaultTemplates: []
}

export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setSelectedTemplate: (state,action) => {
            state.selectedTemplate = action.payload
        },
        setRenderDatatable: (state,action) => {
            state.renderDatatable = action.payload
        },
        setUserTemplates: (state,action) => {
            state.userTemplates = action.payload;
        },
        setDefaultTemplates: (state,action) => {
            state.defaultTemplates = action.payload;
        }
    },
})

export const { setUserTemplates,setDefaultTemplates,setRenderDatatable, setSelectedTemplate } = templateSlice.actions

export default templateSlice.reducer