import React, {useContext, useState} from 'react';
import {Button, Form, FormGroup, InputGroup, ProgressBar} from "react-bootstrap"
import {VALIDATIONS, VALIDATIONS_TEXT} from '../../constants/app-constants';
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {message, Upload} from 'antd';
import {UploadPngIcon} from '../../constants/svgs';
import {useDispatch, useSelector} from 'react-redux';
import {UploadFileToS3} from "../../utils/helpers";
import {AddBox, GetGroups} from "../../services/auth/auth.services";
import {setGroupsData} from "../../redux/slices/groupSlice";
import {handleAddResources, handleLessonBox, nextStep} from "../../redux/slices/tourSlice";
import {error} from "../../constants/msg";
import {setSelectedBoxData} from "../../redux/slices/boxSlice";
import {AddChangeAbleContext} from "../../App";
import Loader from "../loader/Loader";


const AddNewBoxForm = () => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isBoxLoading, setIsBoxLoading] = useState(false);
    const dispatch = useDispatch();
    const tour = useSelector((state) => state.tour);
    const { selectedGroupId, session } = useSelector((state) => state.group);
    const navigate = useNavigate();
    const [changeAble, setChangeAble] = useContext(AddChangeAbleContext);
    const [uploadProgress, setUploadProgress] = useState({});

    // const [uploadProgress, setUploadProgress] = useState(0);
    const [fileList, setFileList] = useState([]);

    const { register, control, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const onSubmit = async (data) => {
        setIsDisabled(true)
        let thumbnail = null;
        if (!selectedGroupId) {
            error("Please select group or create a new group");
            return;
        }
        if (fileList.length > 0) {

            const file = fileList[0];
            let s3Response = await UploadFileToS3(file, "boxes", (progress) => {
                setUploadProgress(prev => ({ ...prev, [file.uid]: progress }));
            });

            // let s3Response = await UploadFileToS3(fileList[0], "boxes")
            thumbnail = s3Response.url
        }
        setIsBoxLoading(true)
        await AddBox(selectedGroupId, thumbnail, data.name,null,  1, 1).then(async (response) => {
            dispatch(setSelectedBoxData(response.data.data))

            setChangeAble(1)
            if (session) {
                let groups = await GetGroups(session.id, 'asc')
                dispatch(setGroupsData(groups.data.data))
            }
            setIsBoxLoading(false)
            if (tour.tourStarted) {
                dispatch(nextStep())
            } else {
                dispatch(handleLessonBox(false))
                dispatch(handleAddResources(true))
            }
            setFileList([]);
            setIsDisabled(false)
        }).catch((e) => {
            error(e.response.data.message)
            setIsBoxLoading(false)
            return false;
        })
    }

    const beforeUpload = (file) => {
        return false;
    };
    // const handleChange = ({ fileList }) => {
    //     setFileList(fileList);
    // };

    const disallowedTypes = [
        'application/x-msdownload',
        'application/x-msdos-program',
        'application/vnd.android.package-archive',
        'text/html',
        'application/javascript',
        'text/javascript',
        'text/css',
        'application/octet-stream',
        'application/zip',
        'application/x-zip-compressed',
        'application/pdf',
        'application/sql',
        'stl',
        'video/mp4',
        'video/webm',
        'video/ogg',
        'video/avi',
        'video/mpeg',
        'video/quicktime',
        'video/x-msvideo',
        'video/x-ms-wmv',
        'video/x-flv'
    ];

    const handleChange = ({ fileList }) => {
        const filteredFiles = fileList.filter(file => {
            const fileType = file.type || file.name.split('.').pop().toLowerCase();
            if (disallowedTypes.includes(fileType)) {
                error(`${file.name} file is not allowed.`)
                return false;
            }
            return true;
        });


        if (filteredFiles.length > 1) {
            error('You can only upload one file.');
            return false;
            filteredFiles.splice(0, filteredFiles.length - 1);
        }
        setFileList(filteredFiles);
    };

    const handleUploadProgress = ({ percent }) => {
        setUploadProgress(percent);
    };

    const props = {
        name: 'file',
        multiple: false,
        accept: ".png",
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setUploadProgress(0); // Reset progress bar when done
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
                setUploadProgress(0); // Reset progress bar on error
            }
        },
        onProgress({ percent }) {
            handleUploadProgress({ percent });
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} className='add_new_box_form'>
                {isBoxLoading && <div className="wrapperLoader">
                    <Loader />
                </div>}
                <FormGroup className='mb-3'>
                    <Form.Label>What should we call this box</Form.Label>
                    <InputGroup className=''>
                        <Form.Control
                            maxLength={VALIDATIONS.LESSON_TITLE}
                            autoComplete="off"
                            // onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                            name="name"
                            type="text"
                            autoFocus={true}
                            placeholder="Enter text"
                            {...register("name",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.LESSON_TITLE,
                                        message: VALIDATIONS_TEXT.LESSON_TITLE_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.LESSON_TITLE_REQUIRED
                                    },
                                    validate: (value) => {
                                        // Custom validation to disallow leading and trailing spaces
                                        if (value.trim() !== value) {
                                            return "White spaces are not allowed";
                                        }
                                        const alphabetCount = value.match(/[a-zA-Z]/g)?.length || 0; // Count alphabets
                                        if (alphabetCount < 2) {
                                            return "Box title must contain at least 2 alphabets";
                                        }
                                        return true;
                                    },
                                })
                            }
                        />
                    </InputGroup>
                    {errors.name && <small className='text-red'>{errors.name.message}</small>}
                </FormGroup>
                <FormGroup className='mb-3'>
                    <Form.Label>Thumbnail</Form.Label>
                    <Upload
                        fileList={fileList}
                        onChange={handleChange}
                        beforeUpload={beforeUpload}
                        accept='.png, .jpg, .jpeg'
                        multiple={false}
                    >
                        <UploadPngIcon />
                    </Upload>
                    {/*{uploadProgress > 0 && (*/}
                    {/*    <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} />*/}
                    {/*)}*/}
                    {fileList.map(file => (
                        <div key={file.uid}>
                            {/*<span>{file.name}</span>*/}
                            <ProgressBar className="custom-progress-bar" now={uploadProgress[file.uid] || 0} label={`${Math.round(uploadProgress[file.uid] || 0)}%`} />
                        </div>
                    ))}
                </FormGroup>

                <div className='mt-2'>
                    <Button disabled={isDisabled || isBoxLoading} type='submit' className="w-100">Create Box</Button>
                </div>
            </Form>
        </>
    )
}

export default AddNewBoxForm