import { toast } from "react-toastify";

export const success = (msg) => toast.success(msg, {
    autoClose: 3000, // Success Msg Display for 3 sec
    hideProgressBar: true


});

export const error = (msg) => toast.error(msg, {
    autoClose: 6000, // Error Display for 6 sec
    hideProgressBar: true
});

export const errorMsg = (msg) => toast.error(msg, {
    autoClose: 6000, // Error Display for 6 sec
    hideProgressBar: true
});