import React, {useContext, useEffect, useState} from 'react'
import MyProfileCard from '../components/home/MyProfileCard'
import EditProfileForm from '../components/home/EditProfileForm';
import {Col, Row} from 'react-bootstrap';
import {GetCurrentUser, SetAuthUserLocalStorage} from '../services/auth/auth.services';
import {error} from "../constants/msg";

import {UserDataContext} from '../App';
import ProfessionalTable from '../components/profile/professional/ProfessionalTable';

const MyProfilePage = ({ margin,client }) => {
    const [show, setShow] = useState(false);
    const [userData, setUserData] = useContext(UserDataContext)

    const showPanel = () => {
        setShow(true)
    }

    const hidePanel = () => {
        setShow(false)
    }

    useEffect(() => {
        const getCurrentUser = async () => {
            try {
                const res = await GetCurrentUser()
                setUserData(res.data.data)
                SetAuthUserLocalStorage(res.data.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }
        getCurrentUser()

    }, [])


    return (
        <div className={`my-profile-page ${margin && "mt-4"}`}>
            <Row>
                <Col sm={7} md={3} lg={3} className="mb-4">
                    <div className={`${"left"} ${show ? "show" : "hide"}`}>
                        <MyProfileCard user={userData} showPanel={showPanel} />
                    </div>
                </Col>
                <Col sm={12} md={9} lg={9} className="mb-4">
                    <div className="right">
                        {show ?
                            <Row>
                                <Col md={6} xl={8} className='m-auto'>
                                    <div className="editFormWrapper">
                                        <EditProfileForm user={userData} hidePanel={hidePanel} setShow={setShow} />
                                    </div>
                                </Col>
                            </Row> : !client ?
                            <div className="table-container p-3 ">
                                <ProfessionalTable />
                            </div> : null}


                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default MyProfilePage