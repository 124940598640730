import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    tourStarted: false,
    activeStep: 0,
    addNewLession: false,
    showFormOfLessionForm: true,
    addResource: false,
    wayToGoModal: false,
    addToolTipModal: false,

}

export const tourSlice = createSlice({
    name: 'tour',
    initialState,
    reducers: {
        initTour: (state, action) => {
            state.tourStarted = true;
        },
        nextStep: (state, action) => {
            // debugger;
            if (state.activeStep == 0) {
                state.activeStep += 1;
                // first modal logic 
            } else if (state.activeStep == 1) {
                state.activeStep += 1;
                state.addNewLession = true;
                state.showFormOfLessionForm = false
            } else if (state.activeStep == 2) {
                state.showFormOfLessionForm = action.payload
                state.activeStep += 1;
                state.addNewLession = false;
                state.addResource = true
            } else if (state.activeStep == 3) {
                state.activeStep += 1;
                state.addResource = false
                state.wayToGoModal = true
            }

        },
        handleLessonBox: (state, action) => {
            state.addNewLession = action.payload;
        },
        handleShowLessonForm: (state, action) => {
            state.showFormOfLessionForm = action.payload
        },
        handleAddResources: (state, action) => {
            state.addResource = action.payload
        },
        handleToolTipModal: (state, action) => {
            state.addToolTipModal = action.payload
        },
        handleWayToGo: (state, action) => {
            state.wayToGoModal = action.payload
        },
        resetTour: () => initialState

    },
})

// Action creators are generated for each case reducer function
export const { initTour, nextStep, resetTour, handleLessonBox, handleAddResources, handleShowLessonForm, handleToolTipModal, handleWayToGo, showFormOfLessionForm } = tourSlice.actions

export default tourSlice.reducer