import React, {useContext, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {error, success} from "../constants/msg";
import {useLocation, useNavigate} from "react-router-dom";
import {UserDataContext} from "../App";
import {GetEmailTemplate, SaveEmailTemplate,} from "../services/auth/auth.services";
import Loader from "../components/loader/Loader";
import {Tabs} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setDefaultTemplates, setRenderDatatable, setUserTemplates,} from "../redux/slices/templateSlice";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const EmailSetupPage = (props) => {
  const location = useLocation();
  const [userData, setUserData] = useContext(UserDataContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [forceStatus, setForceStatus] = useState(false);
  const [isForceStatus, setIsForceStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [desc, setDesc] = useState("");
  const [activeTab, setActiveTab] = useState(0); // Initialize active tab state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isForceDisabled, setIsForceDisabled] = useState(false);

  const { userTemplates, renderDatatable, defaultTemplates } = useSelector(
    (state) => state.template
  );
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onTabChange = (k) => {
    setForceStatus(false);
    let description;
    if (k === 0) {
      setIsForceDisabled(true);
      description = defaultTemplates?.html_body;
    } else {
      setIsForceDisabled(false);
      let user_template = userTemplates?.find((item) => item.position === k);
      const force = user_template?.force === 1;

      setForceStatus(force);
      description = user_template?.html_body;
    }
    // let description = user_template?.html_body || defaultTemplates?.[k]?.html_body;
    const contentState = ContentState.createFromBlockArray(
      htmlToDraft(description).contentBlocks
    );
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    setActiveTab(k);
  };

  const editorConfiguration = {
    toolbar: [
      "undo",
      "redo",
      "|",
      "bold",
      "|",
      "italic",
      "|",
      "heading",
      "|",
      "outdent",
      "indent",
      "|",
      "link",
      "numberedList",
      "bulletedList",
    ],
    innerHeight: "20px",
  };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const getEmailTemplate = async () => {
    try {
      setIsLoading(true);
      const res = await GetEmailTemplate(userData.id);
      dispatch(setUserTemplates(res?.data?.data?.user_templates || []));
      dispatch(setDefaultTemplates(res?.data?.data?.default_templates));
      const force = res?.data?.data?.default_templates?.force === 1;
      setActiveTab(0);
      setIsForceDisabled(force);
      const contentState = ContentState.createFromBlockArray(
        htmlToDraft(res?.data?.data?.default_templates?.html_body).contentBlocks
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      setIsLoading(false);
      dispatch(setRenderDatatable(false));
    } catch (e) {
      setIsLoading(false);
      error(e.response.data.message);
      dispatch(setRenderDatatable(false));
    }
  };

  const onSubmit = async (data) => {
    setIsDisabled(true);
    try {
      let template_name;
      let id;
      if (activeTab === 0) {
        template_name = defaultTemplates?.template_name;
        id = defaultTemplates?.id;
      } else {
        let update_user_template = userTemplates?.find(
          (item) => item.position === activeTab
        );
        template_name = update_user_template?.template_name;
        id = update_user_template?.id;
      }
      let templateBodyText = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      const replacedDescription = templateBodyText
        .replace(/<h4/g, "<span")
        .replace(/<\/h4>/g, "</span>");

      let templateBody = replacedDescription;

      //   return modifiedDescription;

      const res = await SaveEmailTemplate(
        id,
        userData.id,
        templateBody,
        template_name,
        isForceStatus,
        activeTab
      );
      success(res.data.message);
      setForceStatus(false);
      setIsForceStatus(0);
      setIsDisabled(false);
    } catch (e) {
      error(e.response.data.message);
      setIsDisabled(false);
    }
    getEmailTemplate();
  };

  useEffect(() => {
    getEmailTemplate();
  }, []);

  useEffect(() => {
    if (renderDatatable) {
      getEmailTemplate();
    }
  }, [renderDatatable]);
  const tab1 = [
    { key: 0, label: defaultTemplates?.template_name || "Standard" },
  ];

  const tab2 = userTemplates.map((template, index) => ({
    key: index + 1,
    label: template.template_name,
  }));

  const tabs = [...tab1, ...tab2];

  const handleCheckboxChange = (forceStatus) => {
    setForceStatus(forceStatus);
    if (forceStatus) {
      setIsForceStatus(1);
      let user_template = userTemplates?.find(
        (item) => item.position === activeTab
      );
      let description = user_template?.html_body;
      setDesc(description);
      setValue("description", description);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <div className="common-page email-setup-page email-setup-container p-4">
          <div className="d-flex flex-column flex-wrap w-100">
            <Tabs className="themeTabs" onChange={onTabChange} items={tabs} />

            <EmailForm
              forceStatus={forceStatus}
              setDesc={setDesc}
              tabName={activeTab}
              isDisabled={isDisabled}
              onSubmit={onSubmit}
              desc={desc}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
        </div>
      )}
    </>
  );

  function EmailForm({
    setDesc,
    forceStatus,
    isDisabled,
    tabName,
    onSubmit,
    desc,
    handleCheckboxChange,
  }) {
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm({
      mode: "onChange",
    });

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="email-setup-wrapper">
          <div className="position-relative">
            <div id="email-setup-editor">
              <Editor
                editorState={editorState}
                toolbar={{
                  options: [
                    "inline",
                    "fontSize",
                    "fontFamily",
                    "textAlign",
                    "list",
                    "link"
                  ],
                  fontSize: {
                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 40],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    default: 12
                  },
                  inline: {
                    inDropdown: false,
                    className: "editor-toolbar",
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                  // link: { inDropdown: true },
                  link: {
                    inDropdown: false, // Ensure the link option is not in a dropdown
                    showOpenOptionOnHover: true, // Make the popup appear on hover
                    popupClassName: false, // Add your custom class to style the popup
                    options: ['link', 'unlink'], // Ensure only link/unlink options are present
                    defaultTargetOption: '_blank', // Set the default behavior for target
                  }
                }}
                toolbarClassName="editorToolbar"
                wrapperClassName="wrapperClassName"
                editorClassName="editorTemplateContainer"
                editorStyle={{ fontSize: 16 }}
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            {errors["description"] && (
              <div>
                <small className="text-red">
                  {errors["description"].message}
                </small>
              </div>
            )}

            {activeTab !== 0 && !isForceDisabled && (
              <div className="force-company-standard">
                <h5>Force Company Standard?</h5>
                <Form.Check
                  type="checkbox"
                  label="Force"
                  className="squareCheckbox"
                  name="force"
                  checked={forceStatus}
                  {...register("force", {
                    onChange: (e) => {
                      handleCheckboxChange(e.target.checked);
                    },
                  })}
                />
              </div>
            )}
          </div>

          <Button
            id={`save-email-template-${tabName}`}
            disabled={isDisabled}
            className="mt-4"
            type="submit"
          >
            Save Email Template
          </Button>
        </div>
      </Form>
    );
  }
};

export default EmailSetupPage;
