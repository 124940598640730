import React, {useState} from 'react';
import {Button, Form, InputGroup, ProgressBar} from 'react-bootstrap';
import {message, Upload} from 'antd';
import {useForm} from 'react-hook-form';
import {AllFilesFormatUploadIcon} from '../../constants/svgs';
import {useDispatch, useSelector} from 'react-redux';
import {handleAddResources, nextStep} from '../../redux/slices/tourSlice';
import {error, success} from "../../constants/msg";
import {returnFileType, UploadFileToS3} from "../../utils/helpers";
import {GetAuthUserLocalStorage, UploadMultipleEresource} from "../../services/auth/auth.services";
import Loader from '../loader/Loader';
import {setReloadBoxData} from "../../redux/slices/boxSlice";

const AddBoxPopupForm = ({ onhide }) => {
    const { register, handleSubmit, clearErrors, setError, formState: { errors } } = useForm(
        { mode: "onChange" }
    );
    const dispatch = useDispatch();
    const tour = useSelector((state) => state.tour);
    const { selectedBoxData } = useSelector((state) => state.box);
    const [fileList, setFileList] = useState([]);
    const [isResourceLoading, setIsResourceLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});
    const [linkTitle, setLinkTitle] = useState("");
    const [link, setLink] = useState("");

    // Add box poup on submit data
    const onSubmit = async (data) => {
        let auth_user = GetAuthUserLocalStorage();
        if (!auth_user) {
            error("Auth not found");
            return true;
        }
        if (fileList.length == 0 && !data.link) {
            error("Attachments or link is required");
            return true;
        }
        if (!selectedBoxData) {
            error("Box is not selected");
            return true;
        }
        setIsDisabled(true)
        let multiple_data_payload = [];
        if (fileList.length > 0) {
            for (let i = 0; i < fileList.length; i++) {

                const file = fileList[i];
                let s3Response = await UploadFileToS3(file, "resources", (progress) => {
                    setUploadProgress(prev => ({ ...prev, [file.uid]: progress }));
                });
                multiple_data_payload.push({
                    description: "Dummy",
                    size: fileList[i].size,
                    subadmin_user_id: auth_user.id,
                    title: fileList[i].name,
                    type: returnFileType(fileList[i]),
                    url: s3Response.url,
                    group_id: selectedBoxData.group_id,
                    box_id: selectedBoxData.id
                });
            }
        }
        if (data.link) {
            multiple_data_payload.push({
                description: "Dummy",
                size: 0,
                subadmin_user_id: auth_user.id,
                title: data.linkTitle,
                type: "link",
                url: null,
                link: data.link,
                group_id: selectedBoxData.group_id,
                box_id: selectedBoxData.id
            });
        }
        setIsResourceLoading(true);
        await UploadMultipleEresource(multiple_data_payload).then((data) => {
            setIsResourceLoading(false);
            setIsDisabled(false)
            success(data.message)
            if (tour.tourStarted) {
                dispatch(nextStep())
            } else {
                setIsResourceLoading(false)
                dispatch(setReloadBoxData(true))
                dispatch(handleAddResources(false))
            }
        }).catch((e) => {
            setIsResourceLoading(false);
            setIsDisabled(false)
            error(e.response.data.message)
        });
    };

    const beforeUpload = (file) => {
        return false;
    };

    const disallowedTypes = [
        'application/x-msdownload', // .exe files
        'application/x-msdos-program', // another type for .exe files
        'application/vnd.android.package-archive', // .apk files
        'text/html', // HTML files
        'application/javascript', // JavaScript files
        'text/javascript', // Alternative MIME type for JavaScript files
        'text/css', // CSS files
        'application/octet-stream', // iOS application files (.ipa)
        'application/zip', // .zip files
        'application/x-zip-compressed',
        'application/sql'
        // Add other disallowed MIME types here
    ];

    const handleChange = ({ fileList }) => {
        const filteredFiles = fileList.filter(file => {
            if (disallowedTypes.includes(file.type)) {
                message.error(`${file.name} file is not allowed.`);
                return false;
            }
            return true;
        });
        setFileList(filteredFiles);
    };

    const handleLinkChange = (e) => {
        const value = e.target.value;
        setLink(value);
        if (!value) {
            setLinkTitle('');
        }
    };

    const props = {
        name: 'file',
        multiple: true,
        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf, .png, .jpeg, video/mp4,video/x-m4v,video/*",
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className='addboxForm py-3 px-3 position-relative'>
            {isResourceLoading && <div className="wrapperLoader">
                <Loader />
            </div>}
            <Form.Group controlId="formFile">
                <Upload
                    fileList={fileList}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                    // multiple={true}
                >
                    <AllFilesFormatUploadIcon />
                </Upload>
                {fileList.map(file => (
                    <div key={file.uid}>
                        {/*<span>{file.name}</span>*/}
                        <ProgressBar className="custom-progress-bar" now={uploadProgress[file.uid] || 0} label={`${Math.round(uploadProgress[file.uid] || 0)}%`} />
                    </div>
                ))}
            </Form.Group>
            <div className="seperator d-flex align-items-center gap-2 w-100">
                <hr style={{ backgroundColor: "#979797", width: "100%", display: "block" }} />
                <span style={{ color: "#979797" }}>or</span>
                <hr style={{ backgroundColor: "#979797", width: "100%", display: "block" }} />
            </div>

            <Form.Label>Link</Form.Label>
            <InputGroup className='mb'>
                <Form.Control
                    type="url"
                    placeholder="Paste your link here"
                    className={errors.video_url ? "hasError" : ""}
                    onKeyUp={handleLinkChange}
                    {...register("link", {
                        required: {
                            value: false,
                            message: "Link is required",
                        },
                        pattern: {
                            value: /(?:https?|ftp):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|]/,
                            message: "Invalid URL format",
                        }
                    })}
                />
            </InputGroup>
            {errors.video_url && (
                <Form.Text>{errors.video_url.message}</Form.Text>
            )}

            {link && (
                <Form.Group controlId="formLinkTitle" className='mb'>
                    <Form.Label>Link Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter the title for your link"
                        onChange={(e) => setLinkTitle(e.target.value)}
                        {...register("linkTitle", {
                            required: {
                                value: true,
                                message: "Link title is required",
                            }
                        })}
                    />
                    {errors.linkTitle && (
                        <Form.Text>{errors.linkTitle.message}</Form.Text>
                    )}
                </Form.Group>
            )}

            <div className="action_button d-flex gap-3 justify-content-between mt-3">
                <Button type="submit" className='w-50 fs-6' disabled={isDisabled}>I am done for Now</Button>
                <Button className="btn-transparent no-bg w-50 fs-6" onClick={onhide}>Never Mind</Button>
            </div>
        </Form>
    );
};

export default AddBoxPopupForm;
