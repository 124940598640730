import React, {useContext, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {RemoveBoxPopupContext} from "../../App"
import {useNavigate} from 'react-router-dom'
import {DeleteBox} from "../../services/auth/auth.services"
import {error, success} from "../../constants/msg";
import {useDispatch} from "react-redux";


const RemoveBoxPopup = ({ setShowSelectedResource, selectedBox, setSelectedBox, mergeGroupAndBoxes, setMergeGroupAndBoxes }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [handleRemoveBoxPopup, setHandleRemoveBoxPopup] = useContext(RemoveBoxPopupContext);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const deleteBox = async () => {
        const { id, index, group_index } = selectedBox
        setIsDisabled(true)
        try {
            let temp = [...mergeGroupAndBoxes]
            const res = await DeleteBox(id)
            setIsDisabled(false)
            setHandleRemoveBoxPopup(false)
            temp[group_index].boxes.splice(index, 1)
            setMergeGroupAndBoxes(temp)
            success(res.data.message)
            // dispatch(setReloadGroups(true))
            setSelectedBox({
                id: "",
                index: "",
                name: "",
                group_index: ""
            })
            setShowSelectedResource(null)

        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <div>
            <Modal
                className='logout-modal'
                show={handleRemoveBoxPopup}
                onHide={() => setHandleRemoveBoxPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleRemoveBoxPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Delete Box</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Deleting this box will also remove all resources contained inside
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button disabled={isDisabled} onClick={() => deleteBox()} className="btn-common me-4">Delete</Button>
                                <Button onClick={() => setHandleRemoveBoxPopup(false)} className="btn-common no-bg">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RemoveBoxPopup