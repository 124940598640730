import React, {useEffect, useState} from 'react'
import {Button, Form, FormGroup, InputGroup, Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form';
import {FaTimes} from 'react-icons/fa';
import {SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT} from '../../../constants/app-constants';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {error, success} from '../../../constants/msg';
import {AddProfessional, UpdateProfessional} from "../../../services/auth/auth.services";
import {useDispatch} from "react-redux";
import {setRenderDatatable} from "../../../redux/slices/professionalSlice";
import Loader from "../../loader/Loader";

const ProfessionalForm = ({ addModalOpen, setAddModalOpen, isEditMode, rowData }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, setValue, watch, formState: { errors, isDirty, isValid } } = useForm({
        mode: "onChange"
    });
    const dispatch = useDispatch();
    const onSubmit = async (data) => {
        let payload = data;
        try {
            setIsLoading(true)
            let res = null;
            if (!isEditMode) {
                payload = { ...data, device_type: "web", device_token: "ABC123" };
                res = await AddProfessional(payload)
            } else {
                if (!payload.password) {
                    delete payload.password;
                }
                res = await UpdateProfessional(rowData.id, payload)
            }
            success(res.data.message);
            dispatch(setRenderDatatable(true))
            setIsLoading(false)
            setAddModalOpen(false);
        }
        catch (e) {
            error(e.response.data.message)
            setIsLoading(false)
        }
    };


    // Setting the init values
    useEffect(() => {
        if (isEditMode) {
            setValue("name", rowData?.name)
            setValue("email", rowData?.email)
            setValue("phone", rowData?.details?.phone)
        }
    }, [rowData])


    return (
        <Modal
            className='logout-modal'
            size="md"
            show={addModalOpen}
            onHide={() => { setAddModalOpen(false) }}
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="header">
                    <div onClick={() => { setAddModalOpen(false) }} className="fa-times"><FaTimes /></div>
                </div>

                <div className='content-container'>
                    <div className='text-center border-bottom py-4'>
                        <h6 className='p-0 m-0 '>{isEditMode ? "Edit" : "Add"} Professional</h6>
                    </div>
                    <div className="p-5">
                        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup className='form-group'>
                                <Form.Label>Name *</Form.Label>
                                <InputGroup className=''>
                                    <Form.Control
                                        onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                        name="name"
                                        type="text"
                                        placeholder="Enter client name *"
                                        maxLength={VALIDATIONS.NAME}
                                        autoComplete="off"
                                        {...register("name",
                                            {
                                                maxLength: {
                                                    value: VALIDATIONS.NAME,
                                                    message: VALIDATIONS_TEXT.NAME_MAX
                                                },
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.CLIENT_NAME_REQUIRED
                                                },
                                            })
                                        }
                                    />
                                </InputGroup>
                                {errors.name && <small className='text-red'>{errors.name.message}</small>}
                            </FormGroup>
                            <FormGroup className='form-group'>
                                <Form.Label>Email  *</Form.Label>
                                <InputGroup className=''>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        placeholder="Enter client’s email address"
                                        maxLength={VALIDATIONS.EMAIL}
                                        autoComplete="off"
                                        disabled={isEditMode ? true : false}
                                        {...register("email",
                                            {
                                                maxLength: {
                                                    value: VALIDATIONS.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                                },
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                },
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                }
                                            })
                                        }
                                    />
                                </InputGroup>
                                {errors.email && <small className='text-red'>{errors.email.message}</small>}
                            </FormGroup>
                            <FormGroup className='form-group'>
                                <Form.Label>Password  *</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        name="password"
                                        className="password-input"
                                        placeholder="Enter your password"
                                        type={!showPassword ? "password" : "text"}
                                        autocomplete="off"
                                        maxLength={VALIDATIONS.PASSWORD_MAX}
                                        {...register("password",
                                            {
                                                minLength: {
                                                    value: VALIDATIONS.PASSWORD_MIN,
                                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.PASSWORD_MAX,
                                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                },
                                                pattern: {
                                                    value: /^.*(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                                                    message: VALIDATIONS_TEXT.INVALID_PASSWORD_FORMAT
                                                },
                                                required: {
                                                    value: isEditMode ? false : true,
                                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                },
                                            })
                                        }
                                    />
                                    <InputGroup.Text>
                                        {
                                            !showPassword ?
                                                <AiOutlineEye className="eye-icon" onClick={() => setShowPassword(!showPassword)} /> :
                                                <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                                        }
                                    </InputGroup.Text>
                                </InputGroup>
                                {errors.password && <small className='text-red'>{errors.password.message}</small>}
                            </FormGroup>
                            <FormGroup className='form-group'>
                                <Form.Label>Contact Number *</Form.Label>
                                <InputGroup className=''>
                                    <Form.Control
                                        onKeyDown={(e) => {
                                            if (
                                                !(e.key >= "0" && e.key <= "9") && // Allow numbers
                                                e.key !== "+" && // Allow plus sign
                                                e.key !== "-" && // Allow dash
                                                e.key !== "Backspace" // Allow backspace
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        name="phone"
                                        type="text"
                                        placeholder="12341234567"
                                        maxLength={VALIDATIONS.PHONE_MAX}
                                        {...register("phone",
                                            {
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.PHONE_REQUIRED
                                                },
                                                minLength: {
                                                    value: VALIDATIONS.PHONE_MIN,
                                                    message: VALIDATIONS_TEXT.PHONE_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.PHONE_MAX,
                                                    message: VALIDATIONS_TEXT.PHONE_MAX
                                                },
                                                pattern: {
                                                    value: /^[+]?[0-9]+$/,
                                                    message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                },
                                            })
                                        }
                                    />
                                </InputGroup>
                                {errors.phone && <small className='text-red'>{errors.phone.message}</small>}
                            </FormGroup>

                            <>
                                {
                                    isLoading ?
                                        <div className='text-center mt-4'>
                                            <Loader />
                                        </div>
                                        :
                                        <div className='text-center'>
                                            <FormGroup className='form-group m-0'>
                                                <Button className="btn-common me-4 text-capitalize w-100" type="submit" disabled={isLoading}>
                                                    {isEditMode ? "Update" : "Add"}
                                                </Button>
                                            </FormGroup>
                                        </div>
                                }
                            </>
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default ProfessionalForm