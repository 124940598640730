import React from "react";

import {LinkIcon} from "../../constants/svgs";
import NotesPopup from "../popup/NotesPopup";

const LinkCard = ({
                    data,
                    showTitle,
                    redirect,
                    setSelectedData,
                    setRowModalOpen,
                    setModalType
                  }) => {
  const handleClick = () => {
    if (!redirect) {
      window.open(data.link, "_blank");
    } else {
      setSelectedData(data);
      setRowModalOpen(true);
      setModalType(true);
    }
  };

  return (
      <>
        <NotesPopup />
        <div className="my-card file-card pb-3 p-2">
          <div
              className="bg-div bg-light-grey py-4"
              onClick={handleClick}
              style={{ cursor: "pointer" }}
          >
            <div className="file-icon-container">
              <LinkIcon color={"#000"} />
            </div>
          </div>
          {showTitle && (
              <span className="d-inline-block mt-2">{data?.title}</span>
          )}
        </div>
      </>
  );
};

export default LinkCard;
