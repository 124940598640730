import React, { useContext, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import NotificationCard from '../home/NotificationCard'
import { DeleteAccountPopupContext } from "../../App"
import { useNavigate } from 'react-router-dom'
import { error, success } from '../../constants/msg'
import { DeleteStripeCard } from '../../services/auth/auth.services'

const DeletePaymentCardPopup = ({ deleteCardPopup, setDeleteCardPopup, id, setUserCards }) => {

    const handleDeleteCard = async (cardId) => {
        if (cardId !== null) {
            try {
                await DeleteStripeCard(cardId).then((res) => {
                    if (res.data.success) {
                        setUserCards(res.data.data)
                        setDeleteCardPopup(false)
                        success(res.data.message)
                    } else {
                        error('Something went wrong. Try again.')
                        setDeleteCardPopup(false)
                    }
                })
            } catch (e) {
                setDeleteCardPopup(false)
                console.log(e)
            }
        }
    }

    return (
        <div>
            <Modal
                className='delete-account-modal'
                show={deleteCardPopup}
                onHide={() => setDeleteCardPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setDeleteCardPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Delete Stripe Card</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Are you sure you would like to Delete Stripe Card?
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button onClick={() => setDeleteCardPopup(false)} className="btn-common me-4">Cancel</Button>
                                <Button onClick={() => handleDeleteCard(id)} className="btn-common no-bg">Delete</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DeletePaymentCardPopup