import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import Assets from '../../constants/images';
import {
    Login,
    ResendVerificationCode,
    SetAuthUserLocalStorage,
    SetTokenLocalStorage,
    StorageDeviceToken
} from "../../services/auth/auth.services";
import {UserDataContext} from "../../App";
import {constant, roles} from "../../utils/constants";
import {error, success} from "../../constants/msg";
import Loader from "../loader/Loader";

import {GENERAL_ERROR_MESSAGES, VALIDATIONS, VALIDATIONS_TEXT} from '../../constants/app-constants';

const SigninForm = ({ btnText, isForgetText }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useContext(UserDataContext);
    const [showPassword, setShowPassword] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [deviceToken, setDeviceToken] = useState(null);
    const [selectedRole, setSelectedRole] = useState()
    const location = useLocation();
    console.log("location.pathname", location.pathname)
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const getStorageDeviceToken = async () => {
            const res = await StorageDeviceToken();
            setDeviceToken(JSON.parse(res));
        };
        getStorageDeviceToken();
    }, []);

    useEffect(() => {
        if (location.pathname === "/signin/trainer") {
            setSelectedRole(4)
        } else {
            setSelectedRole(5)
        }
    }, [location.pathname])

    const onSubmit = async (data) => {
        let { email, password } = data;
        setIsDisabled(true);
        try {
            const res = await Login(email, password, deviceToken ? deviceToken.device_type : constant.DEVICE_TYPE, deviceToken ? deviceToken.device_token : constant.DEVICE_TOKEN, selectedRole);
            if (res.data.data.role_id === roles.ROLE_PROFESSIONAL) {
                error(GENERAL_ERROR_MESSAGES.PROFESSIONAL_LOGIN);
            } else {
                if (res.data.data.role_id === roles.ROLE_USER) {
                    success(res.data.message);
                    setUserData(res.data.data);
                    SetTokenLocalStorage(res.data.data.access_token);
                    SetAuthUserLocalStorage(res.data.data);
                    navigate(`/current-session`);
                } else {
                    if (res.data?.data?.verified === 1) {
                        setTimeout(() => {
                            if (res.data.data.current_subscription) {
                                if (res.data.data.current_subscription.package_id === 3 && res.data.data.user_2fa === false) {
                                    navigate(`/verification`, { state: { user: res.data.data, navigateLink: "sub-admin/manage-group", isResetPassword: false, TwoFa: true } });
                                } else {
                                    setUserData(res.data.data);
                                    SetTokenLocalStorage(res.data.data.access_token);
                                    SetAuthUserLocalStorage(res.data.data);
                                    navigate(`/sub-admin/manage-group`);
                                }
                            } else {
                                setUserData(res.data.data);
                                SetTokenLocalStorage(res.data.data.access_token);
                                SetAuthUserLocalStorage(res.data.data);
                                navigate(`/sub-admin/subscription`);
                            }
                        }, 1500);
                    } else {
                        const resendRes = await ResendVerificationCode(res.data?.data?.email, false);
                        success(resendRes.data.message)
                        if (res.data.data.current_subscription) {
                            navigate(`/verification`, { state: { user: res.data.data, navigateLink: "signin", isResetPassword: false } });
                        } else {
                            navigate(`/verification`, { state: { user: res.data.data, navigateLink: "signin", isResetPassword: false } });
                        }
                    }
                }
            }
        } catch (e) {
            setIsDisabled(false);
            error(e.response?.data?.message);
        }
    };

    return (
        <div className='forms signin-form p-5 py-xl-4 py-xxl-5 mb-4'>
            <div className='text-center border-bottom pb-4'>
                <img id="logo" className='img-fluid' src={Assets.Logo} alt="Logo" />
            </div>
            <div>
                <div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mt-4'>
                            <Form.Label>E-Mail Address  *</Form.Label>
                            <InputGroup className=''>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Enter email address *"
                                    maxLength={VALIDATIONS.EMAIL}
                                    {...register("email", {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    })}
                                />
                            </InputGroup>
                            {errors.email && <small className='text-red'>{errors.email.message}</small>}
                        </div>

                        <div className='mt-4'>
                            <Form.Label>Password  *</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="password"
                                    className="password-input"
                                    placeholder="Password *"
                                    type={!showPassword ? "password" : "text"}
                                    maxLength={VALIDATIONS.PASSWORD_MAX}
                                    {...register("password", {
                                        minLength: {
                                            value: VALIDATIONS.PASSWORD_MIN,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PASSWORD_MAX,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                        },
                                    })}
                                />
                                <InputGroup.Text>
                                    {
                                        !showPassword ?
                                            <AiOutlineEye className="eye-icon" onClick={() => setShowPassword(!showPassword)} /> :
                                            <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                                    }
                                </InputGroup.Text>
                            </InputGroup>
                            {errors.password && <small className='text-red'>{errors.password.message}</small>}
                        </div>

                        {/* <div className='mt-4'>
                            <Form.Label>Select Role *</Form.Label>
                            <div className='select_role'>
                                <Form.Check
                                    type='radio'
                                    label='Trainer'
                                    name='selected_role'
                                    id='trainer'
                                    value={roles.ROLE_SUB_ADMIN}
                                    {...register("selected_role", { required: true })}
                                />
                                <Form.Check
                                    type='radio'
                                    label='Client'
                                    name='selected_role'
                                    id='client'
                                    value={roles.ROLE_USER}
                                    {...register("selected_role", { required: true })}
                                />
                            </div>
                            {errors.selected_role && <small className='text-red'>Please select a role</small>}
                        </div> */}

                        {/*<div className='text-center'>*/}
                        {/*    <Button disabled={isDisabled} className="w-100 mt-4 btn-signin" type="submit">{btnText}</Button>*/}
                        {/*</div>*/}

                        <>
                            {
                                isDisabled ?
                                    <div className='text-center mt-4'>
                                        <Loader />
                                    </div>
                                    :
                                    <div className='text-center'>
                                        <Button disabled={isDisabled} className="w-100 mt-4 btn-signin" type="submit">{btnText}</Button>
                                    </div>
                            }
                        </>

                        {
                            isForgetText &&
                            <div className='mt-4 text-center'>
                                <NavLink to="/forgot-password" state={{ signInLink:location.pathname }}>Forgot Password?</NavLink>
                            </div>
                        }

                        {location.pathname === "/signin/trainer" && (
                            <p className='text-center mt-4 mb-0'>
                                Don't have an account? <NavLink className="link text-end" to="/signup">Sign Up</NavLink>
                            </p>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default SigninForm;
