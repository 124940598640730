import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"


const ConfirmationModal = ({ deleteModalOpen, setDeleteModalOpen, title, description, deleteFunc, confirmBtnText, rejectBtnText }) => {

    return (
        <div>
            <Modal
                className='logout-modal'
                show={deleteModalOpen}
                onHide={() => { setDeleteModalOpen(false) }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => { setDeleteModalOpen(false) }} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>{title}</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    {description}
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button onClick={() => { setDeleteModalOpen(false) }} className="btn-common me-4 text-capitalize">{rejectBtnText}</Button>
                                <Button onClick={deleteFunc} className="btn-common no-bg text-capitalize">{confirmBtnText}</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default ConfirmationModal