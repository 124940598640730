import React, { useContext, useEffect, useState } from 'react'

import Assets from '../../constants/images'
import { Breadcrumb } from "react-bootstrap"
import { NotificationPopupContext, ProfilePopupContext, UserDataContext } from "../../App"
import NotificationPopup from '../popup/NotificationPopup'
import ProfilePopup from '../popup/ProfilePopup'
import { NavLink, useLocation } from 'react-router-dom'
import { s3Credential } from '../../utils/constants'



const Header = ({ showBreadcrumb, heading, homeLink, breadcrumbLink }) => {
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    const [handleProfilePopupContextPopup, setHandleProfilePopupContextPopup] = useContext(ProfilePopupContext);
    const [userData, setUserData] = useContext(UserDataContext);
    const location = useLocation();
    const [isHome, setIsHome] = useState(false);

    useEffect(() => {
        setIsHome(location.pathname === `/${homeLink}`);
    }, [location.pathname, homeLink]);
    return (
        <header className='header pb-4'>
            <NotificationPopup />
            <ProfilePopup />

            <div className='d-flex align-items-center justify-content-between'>
                <div className={showBreadcrumb ? "d-none d-md-block " : "d-none d-md-block"}>
                    {
                        showBreadcrumb &&
                        <Breadcrumb>
                            <Breadcrumb.Item className={isHome ? 'home' : ''}>
                                <NavLink to={`/${homeLink}`}>Home</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }
                    <h5 className='p-0 m-0 mt-1'>{heading}</h5>
                </div>

                <div className="me-4">
                    <NavLink to={"/current-session"}>
                        <img id="logo" className='w-100' src={Assets.Logo} alt="Logo" />
                    </NavLink>
                </div>

                <div className='d-flex align-items-center'>
                    {/* <div className='circle-div me-3 me-md-4' onClick={() => setHandleNotificationPopup(true)}>
                        <div className="notify"></div>
                        <BsFillBellFill />
                    </div> */}

                    <div className='circle-div' onClick={() => setHandleProfilePopupContextPopup(true)}>
                        <img onError={(e) => e.target.src = Assets.ProfilePlaceHolder} src={userData?.details?.image ? s3Credential.s3EndPoint + userData?.details?.image : Assets.AvatarLarge} alt="Avatar" />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header