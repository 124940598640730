import React, {useRef, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import ReactPlayer from 'react-player'
import Assets from '../../constants/images';
import {FaPause, FaPlay} from "react-icons/fa";
import AddNewBoxForm from './AddNewBoxForm';
import {useDispatch, useSelector} from 'react-redux';
import {handleShowLessonForm} from '../../redux/slices/tourSlice';

const AddNewBoxContent = ({ playing, setPlaying, activeKey }) => {
    const tour = useSelector((state) => state.tour);
    const [tempShow, setTempShow] = useState(true)
    const tooltipRef = useRef(null);
    const dispatch = useDispatch();

    const handleNextStep = (tour) => {
        setTempShow(false)
        dispatch(handleShowLessonForm(true))
    }

    const handleClick = () => {
        setTempShow(false)

        // if (onClick) {
        //     onClick(); // Call the provided onClick handler if available
        // }
    };

    return (
        <>
            <Row>
                <Col xl={6}>
                    <div className="addnewContent">
                        <p>Groups (like the one you created just now) are organizational units that contain multiple boxes under a broader theme or category. They help structure the content in a way that makes it easy for you to navigate and find related boxes. By grouping boxes, you can organize how different subjects connect and access a wide range of information within a specific field. Groups can also be used to help maintain semi-consistent training from client to client, by ordering groups based on how you train, (example: Lesson 1, Lesson 2…). Even though the boxes within these lesson groups may not be related to each other, they are usually covered in sequence making it easy to select what you need.</p>
                        <br/>
                        <p>A "box" is a collection of resources focused on a specific topic. These resources can include various types of content such as videos, images, and documents. Think of a box as a comprehensive toolkit that provides everything your client needs to understand and very specific topic. By only sending relevant boxes, you ensure your client can easily access and utilize the information without having to sift through unrelated content.</p>

                        {/*<p>Add group by entering title. Don’t worry about what you put here, we can change this later.</p>*/}
                        {/* <p>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.  </p>
                        <p>These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form. That being the case, there is really no point in your continuing to read them. </p>
                        <p>After all, you have many other things you should be doing. Who's paying you to waste this time, This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. </p>
                        <p>He who searches for meaning here will be sorely disappointed.  These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation.</p>
                        <p>He who searches for meaning here will be sorely disappointed.  These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation.</p> */}
                    </div>
                </Col>
                <Col xl={5}>
                    <div className="d-flex flex-column gap-4">
                        <div className="addnewVideoPlayer position-relative">
                            {/* {tour.tourStarted && tour.activeStep == 2 && tempShow && <div className="step3 new-lesson-step" onClick={handleClick} style={{ 'cursor': 'pointer' }}>
                                <CustomToolTip position={"bottomLeft"} >
                                    <p>General descriptor for Professional to guide them in creating the best lesson plan they can. Video guide available.</p>
                                    <Button onClick={handleNextStep} style={{ display: 'none' }}>Next</Button>
                                </CustomToolTip>
                            </div>} */}
                            <ReactPlayer
                                className='box-video-player'
                                width='100%'
                                height='100%'
                                url={Assets?.Dummy_URL}
                                controls={false}
                                playIcon={"red"}
                                playing={playing}
                                loop={true}

                            />
                            <div className="playPauseButton" onClick={() => setPlaying(!playing)}>
                                {playing ? <FaPause /> : <FaPlay />}
                            </div>
                        </div>
                        <div className="newBoxForm">
                            < AddNewBoxForm />
                            {/*{tour?.showFormOfLessionForm && < AddNewBoxForm />}*/}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default AddNewBoxContent