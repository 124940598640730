import React, {useContext, useEffect, useState} from 'react'
import {Button, Form} from "react-bootstrap"
import {useLocation, useNavigate} from "react-router-dom"
import {useForm} from "react-hook-form";

import OtpInput from "react-otp-input";
import {error, success} from "../../constants/msg";

import Assets from '../../constants/images';
import {
    ResendVerificationCode,
    StorageDeviceToken,
    VerifyOtpCode,
    VerifyResetPasswordOtpCode
} from "../../services/auth/auth.services";
import {UserDataContext} from '../../App';
import {constant} from '../../utils/constants';
import Loader from "../loader/Loader";

const VerificationCodeForm = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [oerror, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [counter, setCounter] = useState(30);
    const location = useLocation();
    const [user, setUser] = useState(location?.state?.user)
    const [navigateLink, setNavigateLink] = useState(location?.state?.navigateLink)
    const [signInLink, setSignInLink] = useState(location?.state?.signInLink)

    const [isResetPassword, setIsResetPassword] = useState(location?.state?.isResetPassword)
    const [isTwoFa, setIsTwoFa] = useState(location?.state?.TwoFa ? location?.state?.TwoFa : false)
    const [userData, setUserData] = useContext(UserDataContext)
    const [deviceToken, setDeviceToken] = useState(null);

    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const getStorageDeviceToken = async () => {
            const res = await StorageDeviceToken();
            setDeviceToken(JSON.parse(res))
        }
        getStorageDeviceToken()
    }, [])

    useEffect(() => {
        let timer = counter > 0 && setInterval(() => {
            setCounter(counter - 1)
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [counter])

    const handleOtpChange = (otp) => {
        setOtp(otp)
        if (otp.length === 0) {
            setError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setError(false)
        }
    }

    const onSubmit = async (data) => {
        if (otp.length === 0) {
            setError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setIsDisabled(true)
            try {
                if (isResetPassword) {
                    const res = await VerifyResetPasswordOtpCode(otp)
                    setError(false)
                    success(res.data.message)
                    navigate(`/${navigateLink}`, {
                        state: {
                            email: user.email,
                            code: otp,
                            signInLink:signInLink
                        }
                    })
                    setIsDisabled(false)
                }
                else {
                    const res = await VerifyOtpCode(user.email, otp, deviceToken ? deviceToken.device_type : constant.DEVICE_TYPE, deviceToken ? deviceToken.device_token : constant.DEVICE_TOKEN)
                    setError(false)
                    success(res.data.message)
                    navigate(`${navigateLink}`, {
                        state: {
                            email: user.email,
                            code: otp
                        }
                    })

                    setIsDisabled(false)
                }
            }
            catch (e) {
                error(e)
                console.log("e===",e)
                setIsDisabled(false)
            }
        }
    }

    const resendCode = async () => {
        setCounter(30)
        try {
            let twoFa = false
            if (isTwoFa) {
                twoFa = true
            }
            if (isResetPassword) {
                const res = await ResendVerificationCode(user.email);
                success(res.data.message)
            } else {
                console.log("user.email, twoFa", user.email, twoFa)
                const res = await ResendVerificationCode(user.email, twoFa);
                success(res.data.message)
            }

        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <div className='forms verification-code-form p-5 py-xl-4 py-xxl-5 mb-4 mb-md-2'>
            <div className='text-center border-bottom pb-4'>
                <img id="logo" className='img-fluid' src={Assets.Logo} alt="Logo" />
            </div>
            <div>
                <div className='text-center mt-4 mb-5'>
                    <h2 className='p-0 m-0'>Verification</h2>
                    <span className='d-block mt-4'>Enter the 4 digit code you received on email</span>
                </div>
                <div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className=''>
                            <OtpInput
                                className="otp-input"
                                value={otp}
                                onChange={(otp) => handleOtpChange(otp)}
                                numInputs={4}
                                isInputNum={true}
                                placeholder="----"
                                hasErrored={true}
                                focusStyle="focus"
                            />
                            {oerror && <small className="text-red ms-1">{errorMessage}</small>}

                            {/*<div className='text-center'>*/}
                            {/*    <Button disabled={!isValid || isDisabled} type="submit" className="w-100 mt-5">Submit</Button>*/}
                            {/*</div>*/}

                            <>
                                {
                                    isDisabled ?
                                        <div className='text-center mt-4'>
                                            <Loader />
                                        </div>
                                        :
                                        <div className='text-center'>
                                            <Button disabled={!isValid || isDisabled} type="submit" className="w-100 mt-5">Submit</Button>
                                        </div>
                                }
                            </>

                            {
                                counter > 0 ?
                                    <div className='text-center mt-5 d-flex align-items-center justify-content-center'>
                                        <h6 className='p-0 m-0'>Resend in:</h6>
                                        <h5 className='p-0 m-0 ms-2'> 00:{counter < 10 && '0'}{counter}</h5>
                                    </div>
                                    :
                                    <div className='text-center mt-5 d-flex align-items-center justify-content-center'>
                                        <h6 className='p-0 m-0 resend-code' onClick={() => resendCode()}>Resend Code</h6>
                                    </div>
                            }
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default VerificationCodeForm

