import React, {useState} from 'react'
import {Button, NavLink} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import CancelSubscriptionPopup from '../popup/CancelSubscriptionPopup';

const SubscriptionPlanCard = ({ navigateLink, showBtn, data, permissions, handleScreen, userCurrentSubscription }) => {
    const navigate = useNavigate();
    const [deleteSubscriptionPopup, setDeleteSubscriptionPopup] = useState(false)
    return (
        <>
            <CancelSubscriptionPopup cancelSubscriptionPopup={deleteSubscriptionPopup} setCancelSubscriptionPopup={setDeleteSubscriptionPopup} />
            <div className='subscripion-card p-4'>
                <div className='border-bottom pb-2 mb-4 d-flex justify-content-between align-items-center'>
                    <h5 className='fw-medium'>{data.name}</h5>
                    <h4 className='fw-bold'>${data.monthly_price}</h4>
                </div>
                <div>
                    <ul>
                        {
                            permissions.map((v, i) => {
                                return (
                                    (v.type !== 'text') ?
                                        <li key={i} className={(parseInt(v.value) > 0) ? 'show' : ''}>
                                            {v.name}
                                        </li>
                                        :
                                        <li key={i} className={(v.value !== '') && 'show'}>
                                            {v.name}: {v.value}
                                        </li>
                                )
                            })
                        }
                    </ul>
                </div>
                {/* <div>
                    {
                        data.is_free &&
                        <div className='text-center border-top pt-4 mt-4 fixed-height'>
                            <h4>{data.name}</h4>
                        </div>
                    }
                    {
                        !data.is_free &&
                        <div className="fixed-height">
                            <div className='d-flex align-items-center justify-content-between border-top pt-4 mt-4'>
                                <h4>{data.currency === 'USD' && data.monthly_price ? '$ ' + data.monthly_price : data.currency + ' ' + data.monthly_price}</h4>
                                <h4>{data.currency === 'USD' && data.yearly_price ? '$ ' + data.yearly_price : data.currency + ' ' + data.yearly_price}</h4>
                            </div>

                            <div className='d-flex align-items-center justify-content-between'>
                                {data.monthly_price ? <small>Per Month</small> : ''}
                                {data.yearly_price ? <small>Per Year</small> : ''}
                            </div>
                        </div>
                    }
                </div> */}
                {showBtn && data.id !== userCurrentSubscription?.package?.id ?
                <div className='text-center mt-5'>
                        <Button onClick={() => navigate('/sub-admin/payment-method', { state: { data: data, navigateLink: navigateLink, userCurrentSubscription: userCurrentSubscription } })}
                            className='w-100'>{data.id !== userCurrentSubscription?.package?.id ?
                                userCurrentSubscription?.package?.id === 3 ? 'Downgrade' : 'Subscribe'
                            :
                                'Upgrade'
                            } {data.is_free ? 'Free' : ''}</Button>

                </div> : null
                }
            </div>
            {userCurrentSubscription?.next_renewal_date ? <p className='text-blue fw-bold mt-3 fw-500'>Next Payment on {userCurrentSubscription?.next_renewal_date}</p> : null}
            {userCurrentSubscription && data.id === userCurrentSubscription?.package?.id &&
                <div className="box-small w-100 mt-3" onClick={() => setDeleteSubscriptionPopup(true)}>
                    <NavLink className='m-0 p-0 text-orange fw-500'>Cancel Subscription</NavLink>
                </div>
            }
        </>

    )
}

export default SubscriptionPlanCard
