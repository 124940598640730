import React, {useEffect, useState} from 'react'
import {PinkPdfIcon} from '../../../../constants/svgs'
import {Button, Modal} from "react-bootstrap"
import {error, success} from '../../../../constants/msg';
import {useDispatch} from "react-redux";
import {DeleteEResource} from "../../../../services/auth/auth.services";
import {setReloadBoxData} from "../../../../redux/slices/boxSlice";
import PdfViewer from "../../../home/PdfViewer";

const PdfComponent = ({ rowData, setRowModalOpen, getResourceBoxes }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [fileExtension, setFileExtension] = useState("");
    const [fileData, setFileData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        const extension = rowData.media_asset.split('.').pop().toLowerCase();
        setFileExtension(extension);

        setFileData({file_url:rowData.media_asset})

    }, [rowData.media_asset]);


    const handleDelete = async () => {
        setIsDeleting(true);
        await DeleteEResource(rowData.id).then((data) => {
            setIsDeleting(false);
            success(data.data.message);
            dispatch(setReloadBoxData(true))
            setRowModalOpen(false)
            getResourceBoxes()
        }).catch((e) => {
            setIsDeleting(false);
            error(e.response.data.message)
        })
    }
    // console.log("rowData===", rowData)
    // const extension = rowData.media_asset.split('.').pop().toLowerCase();
    // setFileData({file_url:rowData.media_asset})
    // setFileExtension(extension)

    const handleOpen = () => {
        setShowModal(true);
        // setRowModalOpen(false)
    }

    const handleClose = () => {
        setShowModal(false);
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center gap-4">
                <div className="pdf_icon">
                    <PinkPdfIcon />
                </div>
                <p className='text-white'>{rowData.files}.{rowData.media_type}</p>
                <div className="action_button d-flex gap-3 mx-auto">
                    <Button className='btn btn-primary d-flex align-items-center justify-content-center' onClick={handleOpen}>Open</Button>
                    <Button className="btn-common no-bg" onClick={handleDelete}>Delete</Button>
                </div>
            </div>

            <Modal
                className="mediaModalClient logout-modal show_modal_mobile"
                size="lg"
                show={showModal}
                onHide={handleClose}
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="modal_body">
                    {/*{rowData != null ? (*/}
                    <div className="right p-2 p-sm-4 h-100">
                        <div className="content-container p-4 h-100">
                            {/*{returnCardInformation(rowData, 0)}*/}

                            <div className={fileExtension === 'pdf' ? "pdfWrapper" : "docxWrapper"}>
                                <PdfViewer data={fileData} setShowModal={setShowModal}/>
                            </div>
                        </div>
                    </div>
                    {/*) : (*/}
                    {/*    ""*/}
                    {/*)}*/}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PdfComponent
