import React, {useContext, useEffect, useState} from 'react'
import {Tabs} from 'antd';
import AddNewBoxContent from './AddNewBoxContent';
import {AddGroupPopupContext} from '../../App';
import {useDispatch, useSelector} from "react-redux";
import {setSelectedGroupId} from "../../redux/slices/groupSlice";

const AddNewBoxWrap = () => {
    const dispatch = useDispatch();
    const [playing, setPlaying] = useState(false);
    const [tabs, setTabs] = useState([]);
    const [handleAddGroupPopup, setHandleAddGroupPopup] = useContext(AddGroupPopupContext);
    const { groupsData } = useSelector((state) => state.group);
    const { selectedGroupId } = useSelector((state) => state.group);
    const [activeKey, setActiveKey] = useState(selectedGroupId ? selectedGroupId : 1);
    const handleTabChange = (e) => {
        setPlaying(false);
        setActiveKey(e)
        dispatch(setSelectedGroupId(e))
    };
    useEffect(() => {
        if (selectedGroupId != activeKey) {
            setActiveKey(selectedGroupId)
        }
    }, [selectedGroupId])

    let tabsData = [];
    if (groupsData.length > 0) {
        for (let i = 0; i < groupsData.length; i++) {
            tabsData.push({
                key: groupsData[i].id,
                label: groupsData[i].name,
                childern: false
            });
        }
    }
    return (
        <div className="mt-3 d-flex">
            <div className="d-flex flex-column tabs-add-box">
                {/* Additon of scroll */}
                <Tabs
                    defaultActiveKey={selectedGroupId}
                    tabPosition="left"
                    onChange={handleTabChange}
                    items={tabsData}
                    direction='vertical'
                    children={false}
                    renderTabBar={false}
                    style={{ height: 400 }}
                />
                <p className='openAddModal_Instep cursor-pointer mt-2' onClick={() => setHandleAddGroupPopup(true)}>+ Something else</p>
            </div>
            <AddNewBoxContent playing={playing} setPlaying={setPlaying} activeKey={activeKey} />
        </div>
    )
}

export default AddNewBoxWrap