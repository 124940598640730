import React from "react";
import {MusicPlayButton} from "../../constants/svgs";

const VideoCard = ({
                       data,
                       downloadFile,
                       click,
                       setClick,
                       setRowModalOpen,
                       setSelectedData,
                       setModalType
                   }) => {
    return (
        <div
            onClick={() => {
                setClick(!click);
                setRowModalOpen(true);
                setSelectedData(data);
                setModalType(true);
            }}
            className="my-card file-card p-2 pb-3"
        >
            {/* <Button onClick={()=>downloadFile(data?.file_url)} className='download-btn'><MdDownloadForOffline /></Button> */}
            <div className="bg-div bg-light-grey py-4">
                <div className="file-icon-container bg-white">
                    <MusicPlayButton />
                </div>
            </div>
            <span className="d-inline-block mt-2">{data?.title}</span>
        </div>
    );
};

export default VideoCard;
