import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { VideoPopupContext } from "../../App"



import Player from '../home/Player';

const VideoPopup = ({ data }) => {
    const [handleVideoPopup, setHandleVideoPopup] = useContext(VideoPopupContext);

    return (
        <div>

            <Modal
                className='player-modal'
                show={handleVideoPopup}
                onHide={() => setHandleVideoPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleVideoPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='p-1 bg-white br-20'>
                        <Player data={data} isAudioPlayer={false} show1="d-none" show2="d-block" paddingY="py-4" />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default VideoPopup