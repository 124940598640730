import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';


const RightPanel = ({ children }) => {
    return (
        <div className='right'>
            <Container fluid className='my-5'>
                <Row className='justify-content-center'>
                    <Col xs={12}>
                        {children}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default RightPanel