import React from 'react'
import { Col, Row } from 'react-bootstrap'


const SettingPage = ({ children1, children2 }) => {
    return (
        <div className="setting-page general_layout">
            <Row className='h-100 align-items-stretch'>
                <Col sm={12} md={5} lg={4} xl={3} >
                    {children1}
                </Col>

                <Col sm={12} md={7} lg={8} xl={9} >
                    {children2}
                </Col>
            </Row>
        </div>
    )
}

export default SettingPage
