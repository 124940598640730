import React, {useContext, useEffect, useState} from 'react'
import {Button, Form, InputGroup, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {EditBoxPopupContext} from "../../App"
import {useForm} from "react-hook-form";
import {VALIDATIONS, VALIDATIONS_TEXT} from '../../constants/app-constants';

import {useNavigate} from "react-router-dom";
import {UploadPngIcon} from "../../constants/svgs";
import {Upload} from "antd";
import {useDispatch} from "react-redux";
import ThemeModalLayout from "./ThemeModalLayout";
import {UploadFileToS3} from "../../utils/helpers";
import {error, success} from "../../constants/msg";
import {EditBox} from "../../services/auth/auth.services";
import {setReloadGroups} from "../../redux/slices/groupSlice";
import {setSelectedBoxData} from "../../redux/slices/boxSlice";
import Loader from "../loader/Loader";

const EditBoxPopup = ({ selectedBox, mergeGroupAndBoxes, setMergeGroupAndBoxes }) => {
    const [handleEditBoxPopup, setHandleEditBoxPopup] = useContext(EditBoxPopupContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false)

    const [fileList, setFileList] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const beforeUpload = (file) => {
        return false;
    };
    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };
    useEffect(() => {
        reset({
            lessonTitle: selectedBox.name,
            description: selectedBox.description,
            thumbnail: selectedBox.thumbnail
        })
    }, [selectedBox])

    const onSubmit = async (data) => {
        setShowToolTip(true);
        setHandleEditBoxPopup(false);
    }

    const onSubmitToolTip = async (data) => {
        setIsDisabled(true)
        const { lessonTitle, description } = data
        try {
            let thumbnail = null;
            if (fileList.length > 0) {
                let s3Response = await UploadFileToS3(fileList[0], "boxes")
                thumbnail = s3Response.url
            }
            const res = await EditBox(selectedBox.id, lessonTitle, description, null, thumbnail)
            setFileList([])
            mergeGroupAndBoxes[selectedBox.group_index].boxes[selectedBox.index].name = lessonTitle
            setMergeGroupAndBoxes(mergeGroupAndBoxes)
            success(res.data.message)
            // setHandleEditBoxPopup(false)
            setIsDisabled(false)
            setShowToolTip(false)
            dispatch(setReloadGroups(true))
            dispatch(setSelectedBoxData(null))

        }
        catch (e) {
            error(e.response.data.message)
            // setTimeout(async () => {
            setIsDisabled(false)
            // }, 1500)
        }
    }

    const hideToolTipModal = () => {
        setShowToolTip(false)
        // setHandleEditBoxPopup(false)
        reset({ lessonTitle: "", description: "" })
    }

    return (
        <>
            <Modal
                className='add-box-modal'
                show={handleEditBoxPopup}
                onHide={() => {
                    setHandleEditBoxPopup(false)
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => {
                            setHandleEditBoxPopup(false)
                            reset({ lessonTitle: "", description: "", thumbnail:"" })
                        }}
                             className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Edit Box</h6>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='p-5'>

                                <Upload fileList={fileList}
                                        onChange={handleChange}
                                        beforeUpload={beforeUpload}
                                        accept='.png, .jpg, .jpeg'
                                >
                                    <UploadPngIcon />
                                </Upload>
                                {selectedBox?.thumbnail && fileList.length === 0 ? (
                                    <div className="d-flex justify-content-center">
                                        <img
                                            width="50%"
                                            src={selectedBox?.thumbnail}
                                            className="box-thumbnail mt-3 mb-3"
                                        />
                                    </div>
                                ) : null}
                                <div className=''>
                                    <Form.Label>Box Title</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            maxLength={VALIDATIONS.LESSON_TITLE}
                                            autoComplete="off"
                                            // onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                                            name="lessonTitle"
                                            type="text"
                                            autoFocus={true}
                                            placeholder="Enter box title"
                                            {...register("lessonTitle",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.LESSON_TITLE,
                                                        message: VALIDATIONS_TEXT.LESSON_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.LESSON_TITLE_REQUIRED
                                                    },
                                                    // validate: (value) => {
                                                    //     // Custom validation to disallow leading and trailing spaces
                                                    //     if (value.trim() !== value) {
                                                    //         return "White spaces are not allowed";
                                                    //     }
                                                    //     return true;
                                                    // },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.lessonTitle && <small className='text-red'>{errors.lessonTitle.message}</small>}
                                </div>

                                <div className='mt-5'>
                                    <Button disabled={isDisabled} type='submit' className="w-100">Update</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ToolTip Modal */}

            <ThemeModalLayout
                show={showToolTip}
                onhide={hideToolTipModal}
                title={"Tool tip"}
                size={"md"}

            >
                <Form onSubmit={handleSubmit(onSubmitToolTip)} className="toolTipForm p-4">
                    <Form.Label>Tooltip Text</Form.Label>
                    <InputGroup className='mb'>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            resize={false}
                            placeholder="What best describes what you put in the box?
                                        example: These resources will help
                                        paint a clear picture of who Bob Marley was, from his music, to his beliefs and
                                        preferences.
                                        This will become a tooltip for your client
                                        to preview box contents."
                            className={errors.tooltipText ? "hasError" : ""}
                            {...register('description', {
                                maxLength: {
                                    value: 255,
                                    message: "Tooltip text must not exceed 255 characters"
                                }
                            })}

                        />
                    </InputGroup>
                    {errors.description && (
                        <Form.Text>{errors.description.message}</Form.Text>
                    )}

                    <>
                        {
                            isDisabled ?
                                <div className='text-center mt-4'>
                                    <Loader />
                                </div>
                                :
                                <Button disabled={isDisabled} type="submit" className="w-100 mt-4">Sounds Good</Button>
                        }
                    </>
                </Form>
            </ThemeModalLayout>
        </>
    )
}

export default EditBoxPopup