import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    groupsData: [],
    isGroupLoading: false,
    selectedGroupId: null,
    session: null,
    reloadGroups: false
}

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setIsGroupLoading: (state,action)=>{
            state.isGroupLoading = action.payload
        },
        setGroupsData: (state,action) => {
            state.groupsData = action.payload
        },
        setSelectedGroupId: (state, action) => {
            state.selectedGroupId = action.payload
        },
        setSessionData: (state, action) => {
            state.session = action.payload
        },
        setReloadGroups: (state, action) => {
            state.reloadGroups = action.payload
        },
    },
})

export const { setSelectedGroupId,setIsGroupLoading,setGroupsData, setSessionData, setReloadGroups } = groupSlice.actions

export default groupSlice.reducer