import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    selectedProfessional: null,
    renderDatatable: false,
    professionals: []
}

export const professionalSlice = createSlice({
    name: 'professional',
    initialState,
    reducers: {
        setSelectedProfessional: (state,action) => {
            state.selectedProfessional = action.payload
        },
        setRenderDatatable: (state,action) => {
            state.renderDatatable = action.payload
        },
        setProfessionals: (state,action) => {
            state.professionals = action.payload;
        }
    },
})

export const { setProfessionals,setRenderDatatable, setSelectedProfessional } = professionalSlice.actions

export default professionalSlice.reducer