import React from 'react';

const CustomToolTip = ({ position, children }) => {
    return (
        <>
            <div className={`${"customToolTip"} ${position}`} >
                {children}
            </div >
        </>
    )
}

export default CustomToolTip