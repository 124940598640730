import React, { useState } from 'react';
import { Button, Image } from "react-bootstrap"
import { error, success } from '../../../../constants/msg';
import { DeleteEResource } from "../../../../services/auth/auth.services";
import { setReloadBoxData } from "../../../../redux/slices/boxSlice";
import { useDispatch } from "react-redux";
import Loader from '../../../loader/Loader';
import { FaTimes } from 'react-icons/fa';


const ImageComponent = ({ rowData, setRowModalOpen, getResourceBoxes }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = async () => {
        setIsDeleting(true);
        await DeleteEResource(rowData.id).then((data) => {
            success(data.data.message);
            setIsDeleting(false);
            dispatch(setReloadBoxData(true))
            setRowModalOpen(false)
            getResourceBoxes()
        }).catch((e) => {
            setIsDeleting(false);
            error(e.response.data.message)
        })
    }

    const handleDownload = () => {
        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = rowData.media_asset; // URL of the image to download
        downloadLink.setAttribute('download', rowData.files); // Set filename for the downloaded image

        // Append the anchor element to the body
        document.body.appendChild(downloadLink);

        // Programmatically trigger a click event on the anchor element
        downloadLink.click();

        // Remove the anchor element from the body
        document.body.removeChild(downloadLink);
    }

    return (
        <>
            <div className="d-flex flex-column gap-4">
               
                <div className={`${"image_box"} ${rowData.media_asset && "show"}`}>
                    {rowData.media_asset ? <Image src={rowData.media_asset} /> : <Loader />}
                </div>
                <div className="action_button d-flex gap-3 mx-auto">
                    {/*<a href={rowData.media_asset} download={true} target="_blank">*/}
                    {/*    <Button>Download</Button>*/}
                    {/*</a>*/}
                    <Button className="btn-common no-bg" onClick={handleDelete} disabled={isDeleting}>Delete</Button>
                </div>
            </div >
        </>
    )
}

export default ImageComponent