import React, {useContext, useState} from 'react'
import {Button, Form, InputGroup, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {AddGroupPopupContext, GroupsContext} from "../../App"
import {useForm} from "react-hook-form";
import {VALIDATIONS, VALIDATIONS_TEXT} from '../../constants/app-constants';
import {error, success} from "../../constants/msg";

import {useNavigate} from "react-router-dom";
import {AddGroup} from '../../services/auth/auth.services';
import {nextStep, resetTour} from '../../redux/slices/tourSlice';
import {useDispatch, useSelector} from 'react-redux';
import CustomToolTip from '../Tootip/CustomToolTip';
import {setGroupsData, setSelectedGroupId} from "../../redux/slices/groupSlice";
import Loader from "../loader/Loader";

const AddGroupPopup = ({ session, isStateUpdate, mergeGroupAndBoxes, setMergeGroupAndBoxes, sortingOrder }) => {
    const [handleAddGroupPopup, setHandleAddGroupPopup] = useContext(AddGroupPopupContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const tour = useSelector((state) => state.tour);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [handleGroups, setHandleGroups] = useContext(GroupsContext);
    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });



    // ========= Tour Steps Function =========

    const handleNextStep = () => {
        dispatch(nextStep())
    }

    const onSubmit = async (data) => {
        const id = session.id
        const { groupTitle } = data
        if (id) {
            setIsDisabled(true)
            try {
                const newGroupPosition = mergeGroupAndBoxes.length > 0 ? mergeGroupAndBoxes[mergeGroupAndBoxes.length - 1].position != 0 ? mergeGroupAndBoxes[mergeGroupAndBoxes.length - 1].position + 1 : mergeGroupAndBoxes.length + 1 : 1
                const res = await AddGroup(id, groupTitle, 1, newGroupPosition)
                dispatch(setSelectedGroupId(res.data.data.id))
                reset({ groupTitle: "" })
                success(res.data.message)
                if (isStateUpdate === true) {
                    const newlyAddedGroup = res.data.data;
                    newlyAddedGroup.boxes = []
                    let temp = [...mergeGroupAndBoxes]
                    if (sortingOrder === 'desc') {
                        temp.unshift(newlyAddedGroup)
                    } else {
                        temp.push(newlyAddedGroup)
                    }
                    setMergeGroupAndBoxes(temp)
                    setHandleGroups(temp)
                    dispatch(setGroupsData(temp))
                }
                setHandleAddGroupPopup(false)
                setIsDisabled(false);
                // setTimeout(() => {
                //     navigate("/sub-admin/manage-group", {
                //         state: {
                //             session: session, data: {
                //                 group_id: res.data.data.id,
                //                 box_id: ""
                //             }
                //         }
                //     })
                //     setIsDisabled(false)
                // }, 1500)
            }
            catch (e) {
                error(e.response.data.message)
                setTimeout(async () => {
                    setIsDisabled(false)
                }, 1500)
            }
        }
        else {
            error("No active company available")
        }
        if (!tour.addNewLession) {
            { tour.tourStarted && handleNextStep() }
        }
    }

    const handleCloseModal = () => {
        setHandleAddGroupPopup(false)
        { tour.tourStarted && tour.activeStep == 1 && dispatch(resetTour()) }
        reset({ groupTitle: "" })
    }

    return (
        <Modal
            className='add-group-modal '
            show={(tour.tourStarted && tour.activeStep == 1) || handleAddGroupPopup}
            onHide={handleCloseModal}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body className='position-relative'>
                <div className="header">
                    <div onClick={handleCloseModal}
                        className="fa-times"><FaTimes /></div>
                </div>

                <div className='content-container'>
                    <div className='text-center border-bottom py-4'>
                        <h6 className='p-0 m-0 '>Add Group</h6>
                    </div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='p-5'>
                            {/* <div className='text-center'>
                            <p>
                                This is dummy copy. It is not meant to be read.
                                It has been placed here solely to demonstrate the look.
                            </p>
                        </div> */}

                            <div className=''>
                                <Form.Label>Group Title</Form.Label>
                                <InputGroup className=''>
                                    <Form.Control
                                        maxLength={VALIDATIONS.GROUP_TITLE}
                                        autoComplete="off"
                                        // onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                                        name="groupTitle"
                                        type="text"
                                        placeholder="Enter group title"
                                        autoFocus={true}
                                        {...register("groupTitle",
                                            {
                                                maxLength: {
                                                    value: VALIDATIONS.GROUP_TITLE,
                                                    message: VALIDATIONS_TEXT.GROUP_TITLE_MAX
                                                },
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.GROUP_TITLE_REQUIRED
                                                },
                                                validate: (value) => {
                                                //     // Custom validation to disallow leading and trailing spaces
                                                //     if (value.trim() !== value) {
                                                //         return "White spaces are not allowed";
                                                //     }
                                                    const alphabetCount = value.match(/[a-zA-Z]/g)?.length || 0; // Count alphabets
                                                    if (alphabetCount < 2) {
                                                        return "Group title must contain at least 2 alphabets";
                                                    }
                                                    return true;
                                                },
                                            })
                                        }
                                    />
                                </InputGroup>
                                {errors.groupTitle && <small className='text-red'>{errors.groupTitle.message}</small>}
                            </div>

                            {/*<div className='mt-5'>*/}
                            {/*    <Button disabled={isDisabled} type='submit' className="w-100">Add</Button>*/}
                            {/*</div>*/}

                            <>
                                {
                                    isDisabled ?
                                        <div className='text-center mt-4'>
                                            <Loader />
                                        </div>
                                        :
                                        <div className='mt-5'>
                                            <Button disabled={isDisabled} type='submit' className="w-100">Add</Button>
                                        </div>
                                }
                            </>
                        </div>
                    </Form>
                </div>
                {tour.tourStarted && tour.activeStep == 1 &&
                    <div className="step2">
                        <CustomToolTip position={"topLeft"}>
                            <p>Add group by entering title.<br/>Don’t worry about what you put here, <br/>we can change this later</p>
                            <Button onClick={handleNextStep} style={{ display: 'none' }}>Next</Button>
                        </CustomToolTip>
                    </div>}
            </Modal.Body>

        </Modal >
    )
}

export default AddGroupPopup