import React, {useState} from 'react'
import {LinkIcon} from '../../../../constants/svgs'
import {Button} from "react-bootstrap"
import {error, success} from '../../../../constants/msg';
import {useDispatch} from "react-redux";
import {DeleteEResource} from "../../../../services/auth/auth.services";
import {setReloadBoxData} from "../../../../redux/slices/boxSlice";

const LinkOpenComponent = ({ rowData, setRowModalOpen, getResourceBoxes }) => {
    console.log("rowData", rowData)
    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = async () => {
        setIsDeleting(true);
        await DeleteEResource(rowData.id).then((data)=>{
            success(data.data.message);
            setIsDeleting(false);
            dispatch(setReloadBoxData(true))
            setRowModalOpen(false)
            getResourceBoxes()
        }).catch((e) => {
            setIsDeleting(false);
            error(e.response.data.message)
        })
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center gap-4 pt-5 pb-4">
            <div className="pdf_icon">
                <LinkIcon />
            </div>
            <a className='text-white text-decoration-underline cursor-pointer' href={rowData.media_asset} target='_blank'>{rowData.files}</a>
            <div className="action_button d-flex gap-3 mx-auto">
                <a className='btn btn-primary d-flex align-items-center justify-content-center' href={rowData.media_asset} target='_blank'>Open</a>
                <Button className="btn-common no-bg" onClick={handleDelete} disabled={isDeleting}>Delete</Button>
            </div>
        </div>
    )
}

export default LinkOpenComponent