import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import SubscriptionPlanCard from '../components/home/SubscriptionPlanCard'
import {GetCurrentSubscription, GetSubscriptionPackages} from "../services/auth/auth.services";
import {error} from "../constants/msg";
import Loader from '../components/loader/Loader'

const SubscriptionUpgradePage = () => {
    // const navigate = useNavigate()
    const [subscriptionPackages, setSubscriptionPackages] = useState([]);
    const [loading, setLoading] = useState(true)
    const [currentLoading, setCurrentLoading] = useState(true)
    // const [userCurrent_subscription, setUserCurrent_subscription] = useState(true)
    const [userCurrentSubscription, setUserCurrentSubscription] = useState(null)

    useEffect(() => {
        // const user = GetAuthUserLocalStorage()
        // setUserCurrent_subscription(user.current_subscription)
        const getSubscriptionPackages = async (session_id) => {
            try {
                await GetSubscriptionPackages()
                    .then((res) => {
                        if (res.data.success) {
                            setSubscriptionPackages(res.data.data)
                        }
                        setLoading(false)
                    });
            } catch (e) {
                setLoading(false)
                error(e.response.data.message)
            }
        }

        const getCurrentSubscription = async () => {
            setCurrentLoading(true)
            try {
                await GetCurrentSubscription()
                    .then((res) => {
                        if (res.data.success) {
                            setUserCurrentSubscription(res.data.data)
                        }
                        setCurrentLoading(false)
                    });
            } catch (e) {
                setCurrentLoading(false)
            }
        }
        getCurrentSubscription()
        getSubscriptionPackages()
    }, [])


    const [isMonthly, setIsMonthly] = useState(true);

    const handleSwitchChange = () => {
        setIsMonthly(!isMonthly);
    };

    return (
        <Container className='h-100'>
            <div className='subscription-page d-flex flex-column bg-white p-4 h-100'>
                <div className='mb-4'>
                    <h5 className='orange-color'>Current Subscription</h5>
                </div>

                {/* Subscription text for choose plan */}
                {/* <div className="subscription_box">
                    <div className="heading d-flex align-items-center gap-4">
                        <h6 className='text-blue m-0'>Subscription</h6>
                        <p className='m-0'>Choose your plan</p>
                    </div>
                    <div className="d-flex align-items-center custom_switch">
                        <label className={`label ${isMonthly ? "active" : null}`}>Monthly</label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label={""}
                            checked={!isMonthly}
                            onChange={handleSwitchChange}
                        />
                        <label className={`label ${!isMonthly ? "active" : null}`}>Yearly</label>
                    </div>
                </div>*/}
                {loading || currentLoading ?
                    <div className='w-100 text-center d-flex align-items-center justify-content-center h-100'>
                        <Loader />
                    </div>
                    :
                    <Row
                        className='justify-content-center justify-content-sm-start justify-content-md-center justify-content-lg-start'>
                        {
                            subscriptionPackages?.map((v, i) => {
                                return (
                                    <Col key={i} sm={6} md={9} lg={6} className="mb-4">
                                        <SubscriptionPlanCard navigateLink="/sub-admin/settings/subscription-upgrade" showBtn={true} data={v}
                                            permissions={JSON.parse(v?.data)} userCurrentSubscription={userCurrentSubscription} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }
            </div>
        </Container>
    )
}

export default SubscriptionUpgradePage