import React from 'react'
import {NavLink} from 'react-router-dom'
import Assets from '../../constants/images'

const CurrentSessionCard = ({ index, data, groupBy = false }) => {
  const returnImage = (resources = []) => {
    if (resources.length > 0) {
      let firstResource = resources[0];
      if (firstResource?.type === "pdf" || firstResource?.type === "document") {
        return Assets.PDF_CARD
      }
      else if (firstResource?.type === "image") {
        return Assets.IMAGE_CARD
      }
      else if (firstResource?.type === "mp3" || firstResource?.type === "audio") {
        return Assets.AUDIO_CARD
      }
      else if (firstResource?.type === "video" || firstResource?.type === "mp4" || firstResource?.type === "m4v") {
        return Assets.VIDEO_CARD
      } else {
        return Assets.LINK_CARD
      }
    } else {
      return Assets.Art1
    }
  }
  return (
    <div className='current-session-card text-center p-2'>
      {/* <div className='file-icon-container' onClick={() => setHandleNotesPopup(true)}>
        <FileIcon />
      </div> */}
      <NavLink to={`/current-session/${groupBy === true ? data?.box1?.id : data?.box?.id}`}>
        <img src={returnImage(data?.box1?.resources)} alt="session thumbnail" />
        <h6 className='p-0 m-0 mt-2'>{groupBy === true ? data?.box1?.name : data?.box?.name}</h6>
      </NavLink>
    </div>
  )
}

export default CurrentSessionCard