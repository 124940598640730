import React from 'react'
import { Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"

const ThemeModalLayout = ({ show, onhide, children, title, size, noBorder }) => {
    return (
        <div>
            <Modal
                className='theme-modal border-0'
                show={show}
                onHide={onhide}
                size={size}
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <div className="closeIcon">
                    <div onClick={onhide}
                        className="fa-times"><FaTimes /></div>
                </div>
                <Modal.Header className={`${noBorder ? "border-0" : null} ${'justify-content-center'}`}>
                    <Modal.Title className='text-center'>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ThemeModalLayout