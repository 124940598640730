export const wizardSteps = [
    {
        intro: 'Welcome to Grahamity. Let\'s get you started.',
    },
    {
        element: '#add-group-btn',
        intro: 'Create your first content group.'
    },
    {
        element: '#first-accordion-item-btn',
        intro: 'Create your first content box.',
    },
    {
        element: '#add-content-box',
        intro: 'Upload content into your box.',
    },
    {
        element: '#email-setup-editor',
        intro: 'Create an email template.',
        position: 'top',
    },
    {
        element: '#save-email-template',
        intro: 'Save the email template.',
    },
    {
        element: '#add-client-btn',
        intro: 'Add your first client.'
    },
    {
        intro: 'You have finished the setup!',
    },

];