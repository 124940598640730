import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import {DeleteIcon, EditIcon} from '../../../constants/svgs';
import ConfirmationModal from '../../popup/ConfirmationModal';
import {error, success} from '../../../constants/msg';
import {customTableStyles} from '../../../utils/constants';
import ProfessionalForm from './ProfessionalForm';
import {DeleteProfessional, GetProfessionals} from "../../../services/auth/auth.services";
import {useDispatch, useSelector} from "react-redux";
import {setProfessionals, setRenderDatatable} from "../../../redux/slices/professionalSlice";


const ProfessionalTable = () => {
    const {professionals,renderDatatable} = useSelector((state) => state.professional);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const dispatch = useDispatch();

    const getProfessionals = async () => {
        try {
            let params = {};
            setLoading(true)
            params['paginate'] = true;

            if (page) {
                params['page'] = page;
            }

            if (perPage) {
                params['per_page'] = perPage;
            }
            const res = await GetProfessionals(params)
            setTotalRows(res.data?.total);
            dispatch(setProfessionals(res.data?.data))
            dispatch(setRenderDatatable(false))
            setLoading(false)
        }
        catch (e) {
            error(e.response.data.message)
            setLoading(false)
            dispatch(setRenderDatatable(false))
        }
    }
    useEffect(()=>{
        getProfessionals();
    },[page, perPage])
    useEffect(()=>{
        if (renderDatatable){
            getProfessionals();
        }
    },[renderDatatable])

    // Columns configuration
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Email Address',
            selector: row => row.email,
        },
        {
            name: 'Contact Number',
            selector: row => row.details.phone,
        },
        {
            name: 'Actions',
            cell: row => (
                <div className='d-flex gap-4'>
                    <span onClick={() => handleEdit(row)} className='cursor-pointer'><EditIcon /></span>
                    <span onClick={() => handleDelete(row)} className='cursor-pointer'><DeleteIcon /></span>
                </div>
            ),
        },
    ];

    // Handle edit button click
    const handleEdit = (row) => {
        setRowData(row)
        setAddModalOpen(true)
        setIsEditMode(true)
    };

    // Handle delete button click
    const handleDelete = (row) => {
        setRowData(row)
        setDeleteModalOpen(true);
    };

    //Function to accept delete API request

    const handleAcceptFunc = async () => {
        try {
            setIsDeleting(true)
            const res = await DeleteProfessional(rowData.id)
            success(res.data.message);
            setRowData(null)
            dispatch(setRenderDatatable(true))
            setIsDeleting(false)
            setDeleteModalOpen(false);
        }
        catch (e) {
            error(e.response.data.message)
            setIsDeleting(false)
            setDeleteModalOpen(false);
        }
    }

    const handleOpenAddfunc = () => {
        setIsEditMode(false)
        setAddModalOpen(true)
    }
    return (
        <div>
            <div className="titleheader">
                <h6 className='blue-color fw-500 m-0'>Professionals</h6>
                <h6 className='add_professional orange-color m-0 cursor-pointer' onClick={handleOpenAddfunc}>+ Add Professionals</h6>
            </div>
            <DataTable
                data={professionals}
                columns={columns}
                customStyles={customTableStyles}
                // fixedHeader
                // fixedHeaderScrollHeight='500px'
                progressPending={loading}
                responsive
                selectableRows={false}
                striped
                noRowsPerPage={true}
                highlightOnHover
                pagination
                paginationTotalRows={totalRows}
                paginationServer
                onChangePage={(page) => {
                    setPage(page);
                }}
                onChangeRowsPerPage={(currentRowsPerPage) => {
                    setPerPage(currentRowsPerPage)
                }}
                progressComponent={<Loader />}
                noDataComponent={'No record available'}
            />


            {(addModalOpen) && <ProfessionalForm editModalOpen={editModalOpen} addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} isEditMode={isEditMode} rowData={rowData} />}


            {/* Delete Modal */}
            {deleteModalOpen && (
                <>
                    <ConfirmationModal
                        title={"Alert"}
                        description={"Are you sure you want to delete this professional ?"}
                        deleteFunc={handleAcceptFunc}
                        deleteModalOpen={deleteModalOpen}
                        confirmBtnText={"yes"}
                        rejectBtnText={"no"}
                        setDeleteModalOpen={setDeleteModalOpen}
                    />
                </>
            )}
        </div>
    );
};

export default ProfessionalTable;
