import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {useNavigate} from 'react-router-dom'
import {UserDataContext} from '../../App'
import {error, success} from '../../constants/msg'

import {CancelStripeSubscription, GetCurrentUser, SetAuthUserLocalStorage} from '../../services/auth/auth.services'

const CancelSubscriptionPopup = ({ cancelSubscriptionPopup, setCancelSubscriptionPopup }) => {
    const [userData, setUserData] = useState(UserDataContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    const handleCancelSubscription = async () => {
        setIsDisabled(true)
        try {
            await CancelStripeSubscription().then(async (res) => {
                if (res.data.success) {
                    const response = await GetCurrentUser()
                    setUserData(response.data.data)
                    SetAuthUserLocalStorage(response.data.data)
                    setCancelSubscriptionPopup(false)
                    success(res.data.message)
                    setTimeout(() => {
                        setIsDisabled(false)
                        navigate("/sub-admin/manage-group")
                    }, 1500)
                } else {
                    error('Something went wrong. Try again.')
                    setCancelSubscriptionPopup(false)
                    setTimeout(() => {
                        setIsDisabled(false)
                        navigate("/sub-admin/settings/subscription-upgrade")
                    }, 1500)
                }
            })
        } catch (e) {
            setCancelSubscriptionPopup(false)
            console.log(e)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div>
            <Modal
                className='delete-account-modal'
                show={cancelSubscriptionPopup}
                onHide={() => setCancelSubscriptionPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setCancelSubscriptionPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>
                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Cancel Subscription</h6>
                        </div>
                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Are you sure to cancel your subscription? You won't be able to access the features until you subscribe again.
                                </p>
                            </div>
                            <div className='mt-5'>
                                <Button onClick={() => setCancelSubscriptionPopup(false)} className="btn-common me-4">Cancel</Button>
                                <Button disabled={isDisabled} onClick={() => handleCancelSubscription()} className="btn-common no-bg">Confirm</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CancelSubscriptionPopup