import React, {useContext, useEffect, useRef, useState} from "react";
import {Accordion, Button, Col, Image, Modal, Row, Tooltip,} from "react-bootstrap";
import {
  AddGroupIconMedium,
  AddGroupIconSmall,
  AddResourceIcon,
  InfoIcon,
  RecordSomethingThumbnail,
  WayToGoIcon,
} from "../constants/svgs";
import {
  AddBoxPopupContext,
  AddChangeAbleContext,
  AddGroupPopupContext,
  AddSessionPopupContext,
  CurrentSessionContext,
  EditBoxPopupContext,
  EditDeleteGroupPopupContext,
  EditGroupPopupContext,
  GroupsContext,
  RemoveBoxPopupContext,
  RemoveDeleteGroupPopupContext,
  RemoveGroupPopupContext,
  UserDataContext,
} from "../App";
import AddGroupPopup from "../components/popup/AddGroupPopup";
import AddBoxPopup from "../components/popup/AddBoxPopup";
import AudioCardSmall from "../components/home/AudioCardSmall";
import ImageCardSmall from "../components/home/ImageCardSmall";
import VideoCardSmall from "../components/home/VideoCardSmall";
import PdfCardSmall from "../components/home/PdfCardSmall";
import ImagePopup from "../components/popup/ImagePopup";
import PdfPopup from "../components/popup/PdfPopup";
import AudioPopup from "../components/popup/AudioPopup";
import VideoPopup from "../components/popup/VideoPopup";
import {
  EditBox,
  EditGroup,
  GetBoxes,
  GetCurrentSubscription,
  GetDeletedGroupBoxes,
  GetEssionsListing,
  GetGroups,
  GetResourceBoxes,
  SaveResourceInBoxes,
  SaveResourceInDeletedGroupBoxes,
  UploadEresource,
} from "../services/auth/auth.services";
import {error} from "../constants/msg";

import RemoveResourcePopup from "../components/popup/RemoveResourcePopup";
import {UploadFileToS3WithProgressBar} from "../utils/helpers";

import {useNavigate} from "react-router-dom";
import {constant} from "../utils/constants";
import {RiDeleteBin6Line, RiEdit2Line} from "react-icons/ri";

import RemoveGroupPopup from "../components/popup/RemoveGroupPopup";
import RemoveBoxPopup from "../components/popup/RemoveBoxPopup";
import EditGroupPopup from "../components/popup/EditGroupPopup";
import EditBoxPopup from "../components/popup/EditBoxPopup";
import EditDeletedBoxPopup from "../components/popup/EditDeletedBoxPopup";
import RemoveDeletedBoxPopup from "../components/popup/RemoveDeletedBoxPopup";
import AddSessionPopup from "../components/popup/AddSessionPopup";

import MoveDeletedBoxPopup from "../components/popup/MoveDeletedBoxPopup";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import SubAdminBoxTable from "../components/tables/SubAdminBoxTable/SubAdminBoxTable";
import ThemeModalLayout from "../components/popup/ThemeModalLayout";
import AddBoxPopupForm from "../components/Forms/AddBoxPopupForm";
import AddNewBoxWrap from "../components/AddNewBox/AddNewBoxWrap";
import CustomToolTip from "../components/Tootip/CustomToolTip";
import {useDispatch, useSelector} from "react-redux";
import {handleAddResources, handleLessonBox, handleToolTipModal, nextStep, resetTour,} from "../redux/slices/tourSlice";
import Assets from "../constants/images";
import {setGroupsData, setReloadGroups, setSessionData,} from "../redux/slices/groupSlice";
import {setSelectedBoxData} from "../redux/slices/boxSlice";
import Loader from "../components/loader/Loader";

const ManageGroupPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tour = useSelector((state) => state.tour);
  const { groupsData, reloadGroups } = useSelector((state) => state.group);
  const { selectedBoxData } = useSelector((state) => state.box);
  const [placement, setPlacement] = useState("");
  const fileUploadRefGroup = useRef(null);
  const fileUploadRefBox = useRef(null);
  const [isDisabledGroup, setIsDisabledGroup] = useState(false);
  const [isDisabledBox, setIsDisabledBox] = useState(false);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [loadingBox, setLoadingBox] = useState(false);
  const [loadingDeletedGroupBox, setLoadingDeletedGroupBox] = useState(false);
  const [session, setSession] = useState();
  const [groups, setGroups] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [resourceBoxes, setResourceBoxes] = useState([]);
  const [mergeGroupAndBoxes, setMergeGroupAndBoxes] = useState([]);

  const [showSelectedResource, setShowSelectedResource] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [deletedGroupData, setDeletedGroupData] = useState([]);
  const [activeDeletedGroupBox, setActiveDeletedGroupBox] = useState(false);
  const [count, setCount] = useState(0);
  const [accordionActiveKey, setAccordionActiveKey] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileBoxModal, setFileBoxModal] = useState(false);
  const [handleGroups, setHandleGroups] = useContext(GroupsContext);
  const [selectedGroup, setSelectedGroup] = useState({
    index: "",
    id: "",
  });

  const [selectedBox, setSelectedBox] = useState({
    index: "",
    id: "",
    name: "",
    description: "",
    group_index: "",
    group_id: "",
  });

  const [fileSize, setFileSize] = useState(0);
  const [now, setNow] = useState(0);
  const [now2, setNow2] = useState(0);
  // *********************** working context ***********************

  const [handleRemoveGroupPopup, setHandleRemoveGroupPopup] = useContext(
      RemoveGroupPopupContext
  );
  const [handleRemoveBoxPopup, setHandleRemoveBoxPopup] = useContext(
      RemoveBoxPopupContext
  );
  const [handleAddGroupPopup, setHandleAddGroupPopup] =
      useContext(AddGroupPopupContext);
  const [handleAddBoxPopup, setHandleAddBoxPopup] =
      useContext(AddBoxPopupContext);
  const [handleEditGroupPopup, setHandleEditGroupPopup] = useContext(
      EditGroupPopupContext
  );
  const [handleEditBoxPopup, setHandleEditBoxPopup] =
      useContext(EditBoxPopupContext);
  const [userData, setUserData] = useContext(UserDataContext);

  const [handleEditDeletedGroupPopup, setHandleEditDeletedGroupPopup] =
      useContext(EditDeleteGroupPopupContext);
  const [handleRemoveDeletedGroupPopup, setHandleRemoveDeletedGroupPopup] =
      useContext(RemoveDeleteGroupPopupContext);
  const [handleMoveDeletedBoxPopup, setHandleMoveDeletedBoxPopup] =
      useState(false);

  const [handleCurrentSession, setHandleCurrentSession] = useContext(
      CurrentSessionContext
  );
  const [handleAddSessionPopup, setHandleAddSessionPopup] = useContext(
      AddSessionPopupContext
  );
  const [sessionCreated, setSessionCreated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isGroupLoading, setIsGroupLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // *********************** working context ***********************

  // States to set "url's" of different resources inside popup
  const [pdfPopupItem, setPdfPopupItem] = useState({ file_url: "" });
  const [imagePopupItem, setImagePopupItem] = useState({ file_url: "" });
  const [videoPopupItem, setVideoPopupItem] = useState({ file_url: "" });
  const [audioPopupItem, setAudioPopupItem] = useState({
    file_url: "",
    title: "",
  });
  const [userCurrentSubscription, setUserCurrentSubscription] = useState(true);
  const [load, setLoad] = useState(true);
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [isDropDisabled, setIsDropDisabled] = useState(false);
  const [addNewBox, setAddNewBox] = useState(false);
  const [changeAble, setChangeAble] = useContext(AddChangeAbleContext);

  const returnCardInformation = (v, i) => {
    // if (v.type === "text") {
    //   return (
    //     <TextViewer data={v} key={i} />
    //   )
    // }
    if (v.type === "pdf" || v.type === "document") {
      return (
          <PdfCardSmall
              setSelectedResource={setSelectedResource}
              setPdfPopupItem={setPdfPopupItem}
              data={v}
              key={i}
          />
      );
    } else if (v.type === "image") {
      return (
          <ImageCardSmall
              setSelectedResource={setSelectedResource}
              setImagePopupItem={setImagePopupItem}
              data={v}
              key={i}
          />
      );
    } else if (v.type === "mp3" || v.type === "audio") {
      return (
          <AudioCardSmall
              setSelectedResource={setSelectedResource}
              setAudioPopupItem={setAudioPopupItem}
              isAudioPlayer={true}
              show1="d-block"
              show2="d-none"
              paddingY="py-5"
              data={v}
              key={i}
          />
      );
    } else if (v.type === "video" || v.type === "mp4" || v.type === "m4v") {
      return (
          <VideoCardSmall
              setSelectedResource={setSelectedResource}
              setVideoPopupItem={setVideoPopupItem}
              isAudioPlayer={false}
              show1="d-block"
              show2="d-none"
              paddingY="py-5"
              data={v}
              key={i}
          />
      );
    }
  };

  const returnFileType = (file) => {
    let fileType = file.name.split(".");
    fileType = fileType[fileType.length - 1].toLowerCase();

    if (
        fileType === "png" ||
        fileType === "jpg" ||
        fileType === "jpeg" ||
        fileType === "jfif" ||
        fileType === "webp"
    ) {
      fileType = "image";
    } else if (
        fileType === "pdf" ||
        fileType === "docx" ||
        fileType === "doc" ||
        fileType === "txt" ||
        fileType === "xlsx" ||
        fileType === "tiff" ||
        fileType === "exe" ||
        fileType === "apk"
    ) {
      fileType = "document";
    } else if (fileType === "mp3") {
      fileType = "mp3";
    } else if (fileType === "mp4" || fileType === "mov" || fileType === "m4v") {
      fileType = "video";
    } else {
      fileType = "invalid";
    }
    return fileType;
  };

  const handleFileUploadInBox = async (e, box_id, group_id) => {
    let file = e.target.files[0];
    if (file) {
      setFileSize(file.size);
      setIsDisabledBox(true);
      setLoadingBox(true);
      let fileType = returnFileType(file);
      if (fileType == "invalid") {
        error("Invalid file type");
        fileUploadRefBox.current.value = null;
        setLoadingBox(false);
        setTimeout(() => {
          setIsDisabledBox(false);
        }, 1500);
      } else {
        try {
          await UploadFileToS3WithProgressBar(file, constant.resourceDirName)
              .on("httpUploadProgress", function (progress) {
                let progressPercentage = Math.round(
                    (progress.loaded / progress.total) * 100
                );
                setNow(progressPercentage);
              })
              .promise()
              .then(async (document) => {
                let title = file.name;
                let description = "Dummy";
                let url = document.Key;
                await UploadEresource(
                    title,
                    description,
                    url,
                    fileType,
                    fileSize,
                    userData.id
                )
                    .then(async (res) => {
                      const resourse_id = res.data.data.id;
                      await SaveResourceInBoxes(resourse_id, box_id, group_id)
                          .then((response) => {
                            const uploadResourceData = res.data.data;
                            const resourseBoxData = response.data.data;
                            let obj = {
                              box: {
                                id: resourseBoxData.box_id,
                                group_id: resourseBoxData.group_id,
                              },
                              box_id: resourseBoxData.box_id,
                              group: {
                                id: resourseBoxData.group_id,
                              },
                              group_id: resourseBoxData.group_id,
                              id: uploadResourceData.id,
                              resource: {
                                ...uploadResourceData,
                                resource_boxId: resourseBoxData.id,
                              },
                              resource_id: resourseBoxData.resource_id,
                              resource_boxId: resourseBoxData.id,
                            };
                            updateStateOnFileUpload(
                                obj,
                                resourseBoxData.group_id,
                                resourseBoxData.box_id,
                                false
                            );
                            fileUploadRefBox.current.value = null;
                            setTimeout(() => {
                              setIsDisabledBox(false);
                              // success(response.data.message)
                            }, 1100);
                            setIsUploaded(true);
                            setLoadingBox(false);
                            setNow(0);
                          })
                          .catch((e) => {
                            fileUploadRefBox.current.value = null;
                            error(e.response.data.message);
                            setTimeout(() => {
                              setIsDisabledBox(false);
                            }, 1000);
                            setLoadingBox(false);
                          });
                    })
                    .catch((e) => {
                      fileUploadRefBox.current.value = null;
                      error("File is too large");
                      setTimeout(() => {
                        setIsDisabledBox(false);
                      }, 1000);
                      setLoadingBox(false);
                    });
              });
        } catch (e) {
          fileUploadRefBox.current.value = null;
          setTimeout(() => {
            setIsDisabledBox(false);
          }, 1000);
        }
      }
    }
  };

  const handleFileUploadInDeletedGroupBox = async (e, box_id) => {
    let file = e.target.files[0];

    if (file) {
      setFileSize(file.size);
      setIsDisabledBox(true);
      setLoadingDeletedGroupBox(true);
      let fileType = returnFileType(file);
      if (fileType == "invalid") {
        error("Invalid file type");
        fileUploadRefBox.current.value = null;
        setLoadingDeletedGroupBox(false);
        setTimeout(() => {
          setIsDisabledBox(false);
        }, 1500);
      } else {
        try {
          await UploadFileToS3WithProgressBar(
              file,
              constant.resourceDirName
          ).then(async (document) => {
            const title = file.name;
            const description = "Dummy";
            const fileSize = file.size;

            await UploadEresource(
                title,
                description,
                document.url,
                fileType,
                fileSize,
                userData.id
            )
                .then(async (res) => {
                  const resourse_id = res.data.data.id;
                  await SaveResourceInDeletedGroupBoxes(resourse_id, box_id)
                      .then((response) => {
                        const uploadResourceData = res.data.data;
                        const resourseBoxData = response.data.data;
                        let obj = {
                          box: {
                            id: resourseBoxData.box_id,
                            group_id: null,
                          },
                          box_id: resourseBoxData.box_id,
                          group: {
                            id: null,
                          },
                          group_id: null,
                          id: uploadResourceData.id,
                          resource: {
                            ...uploadResourceData,
                            resource_boxId: resourseBoxData.id,
                          },
                          resource_id: resourseBoxData.resource_id,
                          resource_boxId: resourseBoxData.id,
                        };
                        updateStateOnFileUploadInDeletedGroupBox(
                            obj,
                            box_id,
                            false
                        );
                        fileUploadRefBox.current.value = null;
                        setTimeout(() => {
                          setIsDisabledBox(false);
                          // success(response.data.message)
                        }, 1100);

                        setLoadingDeletedGroupBox(false);
                      })
                      .catch((e) => {
                        fileUploadRefBox.current.value = null;
                        error(e.response.data.message);
                        setTimeout(() => {
                          setIsDisabledBox(false);
                        }, 1000);
                        setLoadingDeletedGroupBox(false);
                      });
                })
                .catch((e) => {
                  fileUploadRefBox.current.value = null;
                  error("File is too large");
                  setTimeout(() => {
                    setIsDisabledBox(false);
                  }, 1000);
                  setLoadingDeletedGroupBox(false);
                });
          });
        } catch (e) {
          fileUploadRefBox.current.value = null;
          setTimeout(() => {
            setLoadingDeletedGroupBox(false);
            setIsDisabledBox(false);
          }, 1000);
        }
      }
    }
  };

  const updateStateOnFileUpload = (obj, group_id, box_id, bool) => {
    setShowSelectedResource(null);
    let array = [];
    let temp = [...resourceBoxes];
    temp.unshift(obj);
    // if (sortingOrder === 'desc') {
    //     temp.unshift(obj)
    // } else {
    //     temp.push(obj)
    // }
    if (bool) {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].group_id == group_id && temp[i].box == null) {
          array.push(temp[i].resource);
        }
      }
      setResourceBoxes(temp);
      // setShowSelectedResource(array)
      setShowSelectedResource(null);
    } else {
      for (let i = 0; i < temp.length; i++) {
        if (
            temp[i].group_id == group_id &&
            temp[i].box !== null &&
            temp[i].box_id == box_id
        ) {
          array.push(temp[i].resource);
        }
      }
      setResourceBoxes(temp);
      setShowSelectedResource(array);
    }
  };

  const updateStateOnFileUploadInDeletedGroupBox = (obj, box_id, bool) => {
    let temp = [...deletedGroupData];
    let index = temp.findIndex((val) => val.id == box_id);
    temp[index].resources.unshift(obj.resource);
    // if (sortingOrder === 'desc') {
    //     temp[index].resources.unshift(obj.resource)
    // } else {
    //     temp[index].resources.push(obj.resource)
    // }
    setDeletedGroupData(temp);
    setShowSelectedResource(temp[index].resources);
  };

  const handleSelectedResources = (group_id, box_id, bool) => {
    let array = [];
    // for group everytime
    if (bool) {
      for (let i = 0; i < resourceBoxes.length; i++) {
        if (
            resourceBoxes[i].group_id == group_id &&
            resourceBoxes[i].box == null
        ) {
          array.push(resourceBoxes[i].resource);
        }
      }
      // setShowSelectedResource(array)
      setShowSelectedResource(null);
    } else {
      for (let i = 0; i < resourceBoxes.length; i++) {
        if (
            resourceBoxes[i].group_id == group_id &&
            resourceBoxes[i].box !== null &&
            resourceBoxes[i].box_id == box_id
        ) {
          array.push(resourceBoxes[i].resource);
        }
      }
      setShowSelectedResource(array);
    }
  };

  const getGroups = async (session_id) => {
    setIsGroupLoading(true);
    setIsDisabledBox(true);
    let sortBy = sortingOrder;
    try {
      await GetGroups(session_id, sortBy).then(async (res) => {
        dispatch(setGroupsData(res.data.data));
        dispatch(setReloadGroups(false));
        setGroups(res.data.data);
        setIsDisabledBox(false);
        setIsGroupLoading(false);
      });
    } catch (e) {
      setIsDisabledBox(false);
      setIsGroupLoading(false);
      error(e.response.data.message);
    }
  };

  const getBoxes = async (session_id) => {
    setIsLoading(true);
    let sortBy = sortingOrder;
    try {
      await GetBoxes(session_id, sortBy).then(async (res) => {
        setBoxes(res.data.data);
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      error(e.response.data.message);
    }
  };

  const getEsession = async () => {
    setIsLoading(true);
    try {
      await GetEssionsListing(userData.id).then(async (res) => {
        if (res.data.data && res.data.data.length > 0) {
          setHandleCurrentSession(res.data.data[0].name);
          setHandleAddSessionPopup(false);
          let session_id = res.data.data[0].id;
          try {
            setSession(res.data.data[0]);
            dispatch(setSessionData(res.data.data[0]));
            getGroups(session_id);
            getBoxes(session_id);
            getDeletedGroupBoxes(session_id);
            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          } catch (e) {
            setIsLoading(false);
            setIsError(true);
            error(e.response.data.message);
          }
        } else {
          setHandleAddSessionPopup(true);
          setIsLoading(false);
          setIsError(true);
        }
      });
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
      error(e.response.data.message);
    }
  };

  const getResourceBoxes = async () => {
    setIsLoading(true);
    let array = [];
    // let sortBy = sortingOrder
    try {
      await GetResourceBoxes().then(async (res) => {
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          if (
              typeof data[i].resource !== "undefined" &&
              data[i].resource !== null
          ) {
            data[i].resource.resource_boxId = data[i].id;
          }
          array.push(data[i]);
        }
        setResourceBoxes(array);
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      });
    } catch (e) {
      setIsLoading(false);
      error(e.response.data.message);
    }
  };

  const getDeletedGroupBoxes = async (session_id) => {
    try {
      await GetDeletedGroupBoxes(session_id).then(async (res) => {
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].resources.length; j++) {
            let index = data[i].box_resources.findIndex(
                (val) => val.resource_id == data[i].resources[j].id
            );
            data[i].resources[j].resource_boxId =
                data[i].box_resources[index].id;
          }
        }
        setDeletedGroupData(data);
      });
    } catch (e) {
      error(e.response.data.message);
    }
  };

  const getCurrentSubscription = async () => {
    setIsLoading(true);
    try {
      await GetCurrentSubscription().then((res) => {
        setIsLoading(false);
        if (res.data.success && res.data.data) {
          setUserCurrentSubscription(res.data.data);
        } else {
          console.log("navigate to subscription");
          navigate(`/sub-admin/subscription`);
        }
      });
    } catch (e) {
      setIsLoading(false);
      console.log("Current Subscription Error");
    }
  };

  useEffect(() => {
    getCurrentSubscription();
    getEsession();
    getResourceBoxes();
  }, [userData, sessionCreated, sortingOrder]);

  useEffect(() => {
    if (changeAble == 1) {
      getCurrentSubscription();
      getEsession();
      getResourceBoxes();
      setChangeAble(0);

      // localStorage.setItem('changeAble',false);
    }
  }, [changeAble]);
  useEffect(() => {
    let array = [];
    if (boxes && groups) {
      let temp = [...groups];
      for (let i = 0; i < temp.length; i++) {
        for (let j = 0; j < boxes.length; j++) {
          if (temp[i].id === boxes[j].group_id) {
            array.push(boxes[j]);
          }
        }
        temp[i] = { ...temp[i], boxes: array };
        array = [];
      }
      setMergeGroupAndBoxes(temp);

      if (selectedGroup) {
        setAccordionActiveKey(selectedGroup.index);
      }
    }
  }, [groups, boxes]);

  useEffect(() => {
    const getDeletedGroupBoxes = async () => {
      try {
        await GetDeletedGroupBoxes(session.id).then((res) => {
          let data = res.data.data;
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].resources.length; j++) {
              let index = data[i].box_resources.findIndex(
                  (val) => val.resource_id == data[i].resources[j].id
              );
              data[i].resources[j].resource_boxId =
                  data[i].box_resources[index].id;
            }
          }
          setDeletedGroupData(data);
        });
      } catch (e) {
        error(e.response.data.message);
      }
    };

    if (count > 0) {
      getDeletedGroupBoxes();
    }
  }, [count]);

  useEffect(() => {
    if (reloadGroups) {
      getGroups(session?.id);
    }
  }, [reloadGroups]);
  /* set selected box in redux */
  useEffect(() => {
    if (selectedBox?.group_id && selectedBox?.id) {
      dispatch(setSelectedBoxData(selectedBox));
    } else {
      dispatch(setSelectedBoxData(null));
    }
  }, [selectedBox]);

  // ========= Tour Steps Function =========

  const handleNextStep = () => {
    dispatch(nextStep());
  };

  // useEffect(() => {
  //     const checkUserSubscription = async () => {
  //         try {
  //             const response = await GetCurrentUser()
  //             if (response.data.data.current_subscription === null) {
  //                 setTimeout(() => {
  //                     setUserData(response.data.data)
  //                     SetAuthUserLocalStorage(response.data.data)
  //                     navigate(`/sub-admin/subscription`)
  //                 }, 1500)
  //             } else {
  //                 var g1 = new Date();
  //                 var g2 = new Date(response.data.data.current_subscription.end_at);
  //                 if (g1.getTime() > g2.getTime()) {
  //                     setTimeout(() => {
  //                         setUserData(response.data.data)
  //                         SetAuthUserLocalStorage(response.data.data)
  //                         navigate(`/sub-admin/subscription`)
  //                     }, 1500)
  //                 }
  //             }
  //         }
  //         catch (e) {
  //             error(e.response.data.message)
  //         }
  //     }

  //     checkUserSubscription();
  // }, [load]);

  // useEffect(() => {
  //     if (loadingBox == true) {
  //         if (now < 97) {
  //             setTimeout(() => {
  //                 setNow(now + 1)
  //             }, ((1 / 1000) * fileSize * (1 / 100)))
  //         }
  //     }
  //     else if (loadingDeletedGroupBox == true) {
  //         if (now2 < 97) {
  //             setTimeout(() => {
  //                 setNow2(now2 + 1)
  //             }, ((1 / 1000) * fileSize * (1 / 100)))
  //         }
  //     }
  //     else {
  //         setNow(0)
  //         setNow2(0)
  //     }
  // }, [loadingBox, now, now2, loadingDeletedGroupBox])

  useEffect(() => {
    if (isUploaded) {
      getGroups(session?.id);
      getBoxes(session?.id);
      setIsUploaded(false);
    }
  }, [isUploaded]);

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 992) {
      setTimeout(() => {
        setPlacement("bottom");
      }, 1500);
    }
  }, [window.innerWidth]);

  const renderTooltip = (message) => (
      <Tooltip style={{ fontSize: "12px" }}>{message}</Tooltip>
  );

  // const setGroupsSortingOrder = (data) => {
  //     setSelectedGroup({
  //         index: "",
  //         id: "",
  //     })
  //     setSelectedBox({
  //         index: "",
  //         id: "",
  //         name: "",
  //         group_index: "",
  //         group_id: ""
  //     })
  //     setShowSelectedResource(null)
  //     setSortingOrder(data)
  // }

  // ************* DRAG AND DROP GROUP **********************
  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "#fd3051" : "transparent",
    padding: isDragging ? "12px" : 0,
    ...draggableStyle,
  });
  const reorderGroups = (array, startIndex, endIndex) => {
    const result = array;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndGroup = async (result) => {
    if (!result.destination) {
      return;
    }
    const updatedMergeGroupAndBoxes = reorderGroups(
        mergeGroupAndBoxes,
        result?.source?.index,
        result?.destination?.index
    );
    updatedMergeGroupAndBoxes.map((group, i) =>
        editGroupOrder(group.id, i + 1)
    );
    setMergeGroupAndBoxes(updatedMergeGroupAndBoxes);
  };

  const editGroupOrder = async (id, position) => {
    setIsDropDisabled(true);
    try {
      await EditGroup(id, null, position);
      setIsDropDisabled(false);
    } catch (e) {
      console.log(e.response.data.message);
      setTimeout(async () => {
        setIsDropDisabled(false);
      }, 1500);
    }
  };

  // ************* DRAG AND DROP GROUP **********************

  // ************* DRAG AND DROP BOX **********************

  const reorderBoxes = (array, startIndex, endIndex) => {
    const result = array;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndBox = async (result, group, i) => {
    if (!result.destination) {
      return;
    }
    // console.log('onDragEndBox', result, group, i)
    const updatedBoxes = reorderBoxes(
        group.boxes,
        result?.source?.index,
        result?.destination?.index
    );
    // console.log('updatedBoxes', updatedBoxes)
    updatedBoxes.map((box, i) => editBoxesOrder(box.id, i + 1));
    // setMergeGroupAndBoxes(updatedMergeGroupAndBoxes)
  };

  const editBoxesOrder = async (id, position) => {
    setIsDropDisabled(true);
    try {
      await EditBox(id, null, position);
      setIsDropDisabled(false);
    } catch (e) {
      setIsDropDisabled(false);
    }
  };

  // ************* DRAG AND DROP BOX **********************

  const openAddGroupModal = () => {
    setHandleAddGroupPopup(true);

    if (props.stepsEnabled) {
      props.onCloseIntro();
      props.setShouldIntroReopen(true);
    }
  };

  useEffect(() => {
    if (props.triggerStepAction === 1) {
      setAccordionActiveKey(0);
    }
    if (props.triggerStepAction === 2) {
      setAccordionActiveKey(0);
      if (!mergeGroupAndBoxes.length) return;
      const firstGroup = mergeGroupAndBoxes[0];
      const firstBox = firstGroup.boxes[0];
      if (!firstBox) return;
      setSelectedBox({
        index: firstBox.index,
        id: firstBox.id,
        name: firstBox.name,
        description: firstBox.description,
        thumbnail: firstBox.thumbnail,
        group_index: firstBox.group_index,
        group_id: firstBox.group_id,
      });
    }
  }, [props.triggerStepAction]);

  useEffect(() => {
    if (tour.tourStarted == true) {
      setSelectedBox({ name: null, description: null, thumbnail: null });
      setShowSelectedResource(null);
    }
  }, [tour.tourStarted]);

  useEffect(() => {
    const openHelpBasedOnCond = () => {
      if (props.lastSavedStep === 1) {
        props.setIntroFlags({ ...props.introFlags, hasGroup: true });
        props.onHelp(2);
        return;
      }
      if (props.lastSavedStep === 2) {
        props.setIntroFlags({ ...props.introFlags, hasBox: true });
        props.onHelp(3);
        return;
      }
    };
    if (props.shouldIntroReopen) {
      props.setShouldIntroReopen(false);
      openHelpBasedOnCond();
    }
  }, [mergeGroupAndBoxes, mergeGroupAndBoxes.boxes]);

  const handleHideLessonxBox = () => {
    dispatch(handleLessonBox(false));
    dispatch(resetTour());
  };

  const handleHideResource = () => {
    dispatch(handleAddResources(false));
    if (tour.tourStarted) {
      dispatch(resetTour());
    }
  };

  const handleOpenAddResource = () => {
    dispatch(handleAddResources(true));
  };

  const handleCloseWayToo = () => {
    dispatch(resetTour());
  };

  useEffect(() => {
    if (tour.wayToGoModal) {
      const timer = setTimeout(() => {
        handleCloseWayToo();
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timer if the component unmounts or the modal closes
    }
  }, [tour.wayToGoModal, handleCloseWayToo]);

  const handleHideTooltip = () => {
    dispatch(handleToolTipModal(false));
  };
  return (
      <div className="manage-group-page">
        <AddGroupPopup
            session={session}
            isStateUpdate={true}
            mergeGroupAndBoxes={mergeGroupAndBoxes}
            setMergeGroupAndBoxes={setMergeGroupAndBoxes}
            sortingOrder={sortingOrder}
        />
        <AddSessionPopup
            sessionCreated={sessionCreated}
            setSessionCreated={(value) => {
              setSessionCreated(value);
              if (props.shouldIntroReopen) {
                props.setShouldIntroReopen(false);
                props.onHelp();
              }
            }}
            isStateUpdate={handleAddSessionPopup}
        />
        <EditGroupPopup
            selectedGroup={selectedGroup}
            mergeGroupAndBoxes={mergeGroupAndBoxes}
            setMergeGroupAndBoxes={setMergeGroupAndBoxes}
        />
        <EditBoxPopup
            selectedBox={selectedBox}
            mergeGroupAndBoxes={mergeGroupAndBoxes}
            setMergeGroupAndBoxes={setMergeGroupAndBoxes}
        />
        <EditDeletedBoxPopup
            selectedBox={selectedBox}
            deletedGroupData={deletedGroupData}
            setDeletedGroupData={setDeletedGroupData}
        />
        <RemoveDeletedBoxPopup
            setShowSelectedResource={setShowSelectedResource}
            selectedBox={selectedBox}
            deletedGroupData={deletedGroupData}
            setDeletedGroupData={setDeletedGroupData}
        />
        <MoveDeletedBoxPopup
            selectedBox={selectedBox}
            mergeGroupAndBoxes={mergeGroupAndBoxes}
            handleMoveDeletedBoxPopup={handleMoveDeletedBoxPopup}
            setHandleMoveDeletedBoxPopup={setHandleMoveDeletedBoxPopup}
        />

        <AddBoxPopup
            setMergeGroupAndBoxes={setMergeGroupAndBoxes}
            mergeGroupAndBoxes={mergeGroupAndBoxes}
            selectedGroup={selectedGroup}
            sortingOrder={sortingOrder}
        />
        <ImagePopup data={imagePopupItem} />
        <PdfPopup data={pdfPopupItem} />
        <AudioPopup data={audioPopupItem} />
        <VideoPopup data={videoPopupItem} />
        {activeDeletedGroupBox && (
            <RemoveResourcePopup
                deletedGroupData={deletedGroupData}
                setDeletedGroupData={setDeletedGroupData}
                activeDeletedGroupBox={activeDeletedGroupBox}
                resourceBoxes={resourceBoxes}
                setResourceBoxes={setResourceBoxes}
                showSelectedResource={showSelectedResource}
                setShowSelectedResource={setShowSelectedResource}
                selectedResource={selectedResource}
                setIsResourceDeleted={setIsUploaded}
            />
        )}
        <RemoveGroupPopup
            redirectStatus={false}
            count={count}
            setCount={setCount}
            deletedGroupData={deletedGroupData}
            setDeletedGroupData={setDeletedGroupData}
            setShowSelectedResource={setShowSelectedResource}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            mergeGroupAndBoxes={mergeGroupAndBoxes}
            setMergeGroupAndBoxes={setMergeGroupAndBoxes}
        />
        <RemoveBoxPopup
            setShowSelectedResource={setShowSelectedResource}
            selectedBox={selectedBox}
            setSelectedBox={setSelectedBox}
            mergeGroupAndBoxes={mergeGroupAndBoxes}
            setMergeGroupAndBoxes={setMergeGroupAndBoxes}
        />

        {/* =============== All Modals =============== */}

        {tour.addResource && (
            <ThemeModalLayout
                show={tour.addResource}
                onhide={handleHideResource}
                title={`${"File Box #"} ${selectedBoxData?.name}`}
                size={"md"}
            >
              <div className="position-relative step-four-wrapper">
                <AddBoxPopupForm onhide={handleHideResource} />
                {tour.tourStarted && tour.activeStep == 3 && (
                    <>
                      {/* First Step */}
                      <div className="step4_a">
                        <CustomToolTip position={"bottomRight"}>
                          <p className="mb-2">Record Something</p>
                          <RecordSomethingThumbnail />
                        </CustomToolTip>
                      </div>
                      {/* Second Step */}
                      <div className="step4_b">
                        <CustomToolTip position={"bottomRight"}>
                          <p className="mb-2">Write Something</p>
                          <p class="border-gradient text-white">
                            Put together your documents and upload them (PDF is preferred)
                          </p>
                        </CustomToolTip>
                      </div>
                      {/* Third Step */}
                      <div className="step4_c">
                        <CustomToolTip position={"topLeft"}>
                          <p className="mb-2">Make a video</p>
                          <Image src={Assets.videoImageThumb} />
                        </CustomToolTip>
                      </div>
                      {/* Fourth Step */}
                      <div className="step4_d">
                        <CustomToolTip position={"topLeft"}>
                          <p>
                            Remember: The most helpful boxes are the ones with several
                            different kinds of things in them... Too many pictures?
                            Try making a video. Too much writing? Try giving them a
                            picture!
                          </p>
                          <Button
                              onClick={handleNextStep}
                              style={{ display: "none" }}
                          >
                            Next
                          </Button>
                        </CustomToolTip>
                      </div>
                    </>
                )}
              </div>
            </ThemeModalLayout>
        )}

        {tour.addNewLession && (
            <ThemeModalLayout
                show={tour.addNewLession}
                onhide={handleHideLessonxBox}
                title={"New Lesson box"}
                size={"lg"}
                noBorder={false}
            >
              <AddNewBoxWrap />
            </ThemeModalLayout>
        )}

        {/*{tour.addToolTipModal && <ThemeModalLayout*/}
        {/*    show={tour.addToolTipModal}*/}
        {/*    onhide={handleHideTooltip}*/}
        {/*    title={"Tool tip"}*/}
        {/*    size={"md"}*/}

        {/*>*/}
        {/*    <ToolTipForm />*/}
        {/*</ThemeModalLayout>}*/}

        {tour.wayToGoModal && (
            <Modal
                className="theme-modal border-0 "
                show={tour.wayToGoModal}
                size={"sm"}
                centered
                onHide={handleCloseWayToo}
                aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="position-relative">
                {/* {tour.tourStarted && (
              <div className="step4">
                <CustomToolTip position={"bottomDown"}>
                  <p>Hooray box fades out over 3-4 seconds</p>
                  <Button
                    onClick={handleCloseWayToo}
                    style={{ display: "none" }}
                  >
                    Okay
                  </Button>
                </CustomToolTip>
              </div>
            )} */}
                <div className="way-to-go-box d-flex flex-column justify-content-center align-items-center gap-3">
                  <WayToGoIcon />
                  <h4 className="m-0">Way to Go!</h4>
                </div>
              </div>
            </Modal>
        )}

        <Row>
          <Col lg={6} xl={3} className="mb-4">
            <div
                className={`left p-3 dashbordAddGroup_left ${
                    mergeGroupAndBoxes.length > 0 ? "has_group" : ""
                }`}
            >
              {isGroupLoading ? (
                  <div className="mt-4 text-center">
                    <Loader />
                  </div>
              ) : (
                  <>
                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement={placement ? placement : "right"} overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_GROUP)}> */}
                    <div id="add-group-btn" className="box w-100 px-3 py-2 mb-2">
                      {/* <span className="custom-tooltip" tooltip-msg={TOOL_TIP_MESSAGES.CREATE_GROUP} ><BsFillInfoCircleFill /></span> */}
                      <div
                          className="add_new_group_btn"
                          onClick={() => openAddGroupModal()}
                      >
                        <div className="icon-container">
                          <AddGroupIconMedium />
                        </div>
                        <h6 className="m-0 p-0">Add New Group</h6>
                      </div>
                    </div>
                    {/* </OverlayTrigger> */}

                    {(mergeGroupAndBoxes.length > 0 ||
                        deletedGroupData.length > 0) && (
                        <div className="holder">
                          <div className="accordian-container ">
                            <div className="mt-2">
                              {/* {
                                         sortingOrder === 'asc' ?
                                             <span onClick={!isDisabledBox ? () => setGroupsSortingOrder('desc') : undefined}>
                                                 <RiSortDesc className='me-2 icon text-grey' />
                                             </span>
                                             :
                                             <span onClick={!isDisabledBox ? () => setGroupsSortingOrder('asc') : undefined}>
                                                 <RiSortAsc className='me-2 icon text-grey' />
                                             </span>
                                     } */}
                            </div>

                            {/* ******* DRAG AND DROP ******** */}
                            <DragDropContext onDragEnd={onDragEndGroup}>
                              <Droppable
                                  droppableId="droppable"
                                  isDropDisabled={isDropDisabled}
                              >
                                {(provided, snapshot) => (
                                    <Accordion
                                        defaultActiveKey="0"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                      {mergeGroupAndBoxes.length > 0 &&
                                      mergeGroupAndBoxes.map((group, i) => (
                                          <Accordion.Item
                                              key={i}
                                              className="border-bottom"
                                              eventKey={i}
                                          >
                                            <Draggable
                                                key={group?.id.toString()}
                                                draggableId={group?.id.toString()}
                                                index={i}
                                            >
                                              {(provided, snapshot) => (
                                                  <Accordion.Header
                                                      className="py-4"
                                                      eventKey={i}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided.draggableProps.style
                                                      )}
                                                      aria-disabled={isDisabledBox}
                                                      onClick={
                                                        !isDisabledBox
                                                            ? () => {
                                                              setShowSelectedResource(null);
                                                              setSelectedGroup({
                                                                id: group.id,
                                                                index: i,
                                                                name: group.name,
                                                              });
                                                              setSelectedBox({
                                                                id: "",
                                                                index: "",
                                                                name: "",
                                                                description: "",
                                                                group_id: "",
                                                                group_index: "",
                                                              });
                                                              setActiveDeletedGroupBox(
                                                                  false
                                                              );
                                                              handleSelectedResources(
                                                                  group.id,
                                                                  null,
                                                                  true
                                                              );
                                                            }
                                                            : undefined
                                                      }
                                                  >
                                          <span className="title-group">
                                            {group.name}
                                          </span>
                                                    <span
                                                        className="delete-btn-container"
                                                        onClick={() => {
                                                          setHandleRemoveGroupPopup(true);
                                                          setActiveDeletedGroupBox(false);
                                                          setSelectedGroup({
                                                            id: group.id,
                                                            index: i,
                                                            name: group.name,
                                                          });
                                                        }}
                                                    >
                                            <RiDeleteBin6Line className="me-2 icon text-grey" />
                                          </span>

                                                    <span
                                                        className="edit-btn-container"
                                                        onClick={() => {
                                                          setSelectedGroup({
                                                            id: group.id,
                                                            index: i,
                                                            name: group.name,
                                                          });
                                                          setActiveDeletedGroupBox(false);
                                                          setHandleEditGroupPopup(true);
                                                        }}
                                                    >
                                            <RiEdit2Line className="me-2 icon text-grey" />
                                          </span>
                                                  </Accordion.Header>
                                              )}
                                            </Draggable>
                                            <Accordion.Body>
                                              {/* <OverlayTrigger trigger={['hover', 'focus']} placement={placement ? placement : "right"} overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_BOX)}> */}
                                              <div className="box-small w-100 p-2 mb-3">
                                                {/* <span className="text-orange custom-tooltip" tooltip-msg={TOOL_TIP_MESSAGES.CREATE_BOX} ><BsFillInfoCircleFill /></span> */}
                                                <div
                                                    aria-disabled={isDisabledBox}
                                                    onClick={
                                                      !isDisabledBox
                                                          ? () => {
                                                            setActiveDeletedGroupBox(
                                                                false
                                                            );
                                                            setSelectedGroup({
                                                              id: group.id,
                                                              index: i,
                                                              name: group.name,
                                                            });
                                                            setHandleAddBoxPopup(true);
                                                          }
                                                          : undefined
                                                    }
                                                    className="d-flex align-items-center"
                                                >
                                                  <div className="icon-container">
                                                    <AddGroupIconSmall />
                                                  </div>
                                                  <h6 className="mb-0 p-0 ms-3">
                                                    + Add New Box
                                                  </h6>
                                                </div>
                                              </div>
                                              {/* </OverlayTrigger> */}

                                              <DragDropContext
                                                  onDragEnd={(result) =>
                                                      onDragEndBox(result, group, i)
                                                  }
                                              >
                                                <Droppable
                                                    droppableId="droppable-box"
                                                    isDropDisabled={isDropDisabled}
                                                >
                                                  {(provided, snapshot) => (
                                                      <div
                                                          {...provided.droppableProps}
                                                          ref={provided.innerRef}
                                                      >
                                                        {group.boxes.length > 0 &&
                                                        group.boxes.map((box, j) => (
                                                            <Draggable
                                                                key={box?.id.toString()}
                                                                draggableId={box?.id.toString()}
                                                                index={j}
                                                            >
                                                              {(provided, snapshot) => (
                                                                  <div
                                                                      ref={provided.innerRef}
                                                                      {...provided.draggableProps}
                                                                      {...provided.dragHandleProps}
                                                                      style={getItemStyle(
                                                                          snapshot.isDragging,
                                                                          provided
                                                                              .draggableProps
                                                                              .style
                                                                      )}
                                                                      key={j}
                                                                      className="sub-description cutbox d-flex align-items-center justify-content-between"
                                                                      aria-disabled={
                                                                        isDisabledBox
                                                                      }
                                                                      onClick={
                                                                        !isDisabledBox
                                                                            ? () => {
                                                                              setShowSelectedResource(
                                                                                  null
                                                                              );
                                                                              setSelectedGroup(
                                                                                  {
                                                                                    id: "",
                                                                                    index: "",
                                                                                    name: "",
                                                                                  }
                                                                              );

                                                                              setSelectedBox({
                                                                                id: box.id,
                                                                                index: j,
                                                                                name: box.name,
                                                                                description:
                                                                                box.description,
                                                                                thumbnail:
                                                                                box.thumbnail,
                                                                                group_id:
                                                                                box.group_id,
                                                                                group_index:
                                                                                i,
                                                                              });

                                                                              setActiveDeletedGroupBox(
                                                                                  false
                                                                              );
                                                                              handleSelectedResources(
                                                                                  box.group_id,
                                                                                  box.id,
                                                                                  false
                                                                              );
                                                                            }
                                                                            : undefined
                                                                      }
                                                                  >
                                                                    <h6
                                                                        className={`py-2 m-0`}
                                                                    >
                                                          <span
                                                              style={{
                                                                cursor: "pointer",
                                                              }}
                                                          >
                                                            {box.name}
                                                          </span>
                                                                    </h6>

                                                                    <div className="edit-delete-wrapper">
                                                          <span
                                                              className="me-1"
                                                              onClick={() => {
                                                                setSelectedGroup({
                                                                  id: "",
                                                                  index: "",
                                                                  name: "",
                                                                });

                                                                setSelectedBox({
                                                                  id: box.id,
                                                                  index: j,
                                                                  name: box.name,
                                                                  description:
                                                                  box.description,
                                                                  group_id:
                                                                  box.group_id,
                                                                  group_index: i,
                                                                });

                                                                setActiveDeletedGroupBox(
                                                                    false
                                                                );
                                                                setHandleEditBoxPopup(
                                                                    true
                                                                );
                                                              }}>
                                                            <RiEdit2Line className="me-1 icon text-grey" />
                                                          </span>

                                                                      <span
                                                                          className=""
                                                                          onClick={() => {
                                                                            setActiveDeletedGroupBox(
                                                                                false
                                                                            );
                                                                            setHandleRemoveBoxPopup(
                                                                                true
                                                                            );
                                                                          }}
                                                                      >
                                                            <RiDeleteBin6Line className="me-2 icon text-grey" />
                                                          </span>
                                                                    </div>
                                                                  </div>
                                                              )}
                                                            </Draggable>
                                                        ))}
                                                      </div>
                                                  )}
                                                </Droppable>
                                              </DragDropContext>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                      ))}
                                    </Accordion>
                                )}
                              </Droppable>
                            </DragDropContext>
                            {/* ******* DRAG AND DROP ******** */}

                            <div className="mt-3">
                              {isLoading ? (
                                  ""
                              ) : (
                                  <>
                                    {deletedGroupData.length > 0 &&
                                    deletedGroupData.map((box, i) => {
                                      return (
                                          <div
                                              key={i}
                                              className="deleted-group-data d-flex align-items-center mb-2 justify-content-between"
                                          >
                                            <h6
                                                className="m-0 title"
                                                onClick={() => {
                                                  setSelectedBox({
                                                    id: box.id,
                                                    index: i,
                                                    name: box.name,
                                                    group_id: null,
                                                    group_index: null,
                                                  });
                                                  setActiveDeletedGroupBox(true);
                                                  setShowSelectedResource(box?.resources);
                                                }}
                                            >
                                              {box.name}
                                            </h6>
                                            <div>
                                              {/*<span*/}
                                              {/*    className='icon text-grey me-2'*/}
                                              {/*    onClick={() => {*/}
                                              {/*        setSelectedBox({*/}
                                              {/*            id: box.id,*/}
                                              {/*            index: i,*/}
                                              {/*            name: box.name,*/}
                                              {/*            group_id: null,*/}
                                              {/*            group_index: null*/}
                                              {/*        })*/}

                                              {/*        setHandleMoveDeletedBoxPopup(true)*/}
                                              {/*    }}*/}
                                              {/*>*/}
                                              {/*    <MdDriveFileMoveOutline />*/}
                                              {/*</span>*/}

                                              <span
                                                  className="icon text-grey me-2"
                                                  onClick={() => {
                                                    setSelectedBox({
                                                      id: box.id,
                                                      index: i,
                                                      name: box.name,
                                                      group_id: null,
                                                      group_index: null,
                                                    });

                                                    setHandleEditDeletedGroupPopup(true);
                                                  }}
                                              >
                                        <RiEdit2Line />
                                      </span>

                                              <span
                                                  className="icon text-grey"
                                                  onClick={() => {
                                                    setSelectedBox({
                                                      id: box.id,
                                                      index: i,
                                                      name: box.name,
                                                      group_id: null,
                                                      group_index: null,
                                                    });

                                                    setHandleRemoveDeletedGroupPopup(
                                                        true
                                                    );
                                                  }}
                                              >
                                        <RiDeleteBin6Line />
                                      </span>
                                            </div>
                                          </div>
                                      );
                                    })}
                                  </>
                              )}
                            </div>
                          </div>
                        </div>
                    )}
                  </>
              )}
            </div>
          </Col>

          <Col lg={6} xl={9} className="mb-4">
            {tour.tourStarted && tour.activeStep == 0 && (
                <div className="mask" onClick={() => dispatch(resetTour())}></div>
            )}
            <div className="right p-3 ">
              {tour.tourStarted && tour.activeStep == 0 && (
                  <div className="position-relative mask-wrapper">
                    <div className="step1">
                      <CustomToolTip position={"topLeft"}>
                        <p>
                          Welcome, Let’s build one session component or “box”.
                        </p>
                        <Button onClick={handleNextStep}>Next</Button>
                      </CustomToolTip>
                    </div>
                  </div>
              )}
              <div className="holder">
                <Row
                    className={`${
                        showSelectedResource === null ||
                        showSelectedResource.length <= 0
                            ? "h-100"
                            : ""
                    } `}
                >
                  <Col
                      xs={12}
                      xl={12}
                      className={`${
                          showSelectedResource === null ||
                          showSelectedResource.length <= 0
                              ? "h-100"
                              : ""
                      } `}
                  >
                    {/* Change here selectedBox?.name */}
                    {selectedBoxData && (
                        <Row className="align-items-center mb-4">
                          <Col xl={6}>
                            <div className="d-flex align-items-center gap-3">
                              <h4 className="sec-head m-0">
                                {selectedBoxData && selectedBoxData.name
                                    ? selectedBoxData.name
                                    : "Resource"}
                              </h4>
                              {selectedBoxData && selectedBoxData.description ? (
                                  <div className="toolTipWrapper">
                                    <InfoIcon />
                                    <div className="toolTipBaloon left_center">
                                      {selectedBoxData.description}
                                    </div>
                                  </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col xl={6} className="justify-content-end">
                            {selectedBoxData ? (
                                <div
                                    className="d-flex align-items-center justify-content-end gap-2 cursor-pointer"
                                    onClick={handleOpenAddResource}
                                >
                                  <AddResourceIcon />
                                  <h4 className="sec-head m-0">Add Resources</h4>
                                </div>
                            ) : null}
                          </Col>
                        </Row>
                    )}

                    <Row
                        className={`${
                            showSelectedResource === null ? "h-100" : ""
                        } `}
                    >
                      {/* Change here  showSelectedResource*/}
                      {!selectedBoxData ? (
                          <>
                            <Col
                                className="d-flex align-items-center mt-2 text-center justify-content-center h-100 my-auto position-relative maskWrapper"
                                xs={12}
                            >
                              <div className="">
                                <h3 className="no-items">
                                  Please select box to see available resources
                                </h3>
                              </div>
                            </Col>
                          </>
                      ) : (
                          <SubAdminBoxTable selectedBox={selectedBox} />
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
  );
};

export default ManageGroupPage;
