export const VALIDATIONS = {
    NAME: 20,
    PHONE_MIN: 10,
    PHONE_MAX: 16,
    BIO_MAX: 255,
    EMAIL: 255,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 32,
    VERIFICATION_CODE: 5,
    CARD_NUMBER: 16,
    CVV: 3,
    TEAM_TITLE_MAX: 50,
    NOTIFICATION_TITLE_MAX: 50,
    NOTIFICATION_DESCRIPTION_MAX: 255,
    LOCATION_MAX: 255,
    CAPTION_MAX: 255,
    GROUP_TITLE: 32,
    LESSON_TITLE: 32,
    SESSION_TITLE: 32,
    ADDRESS_MAX: 200,
}

export const VALIDATIONS_TEXT = {
    SESSION_TITLE_REQUIRED: "Company name is required",
    ORGANIZATION_NAME_REQUIRED: "Organization name is required",
    CLIENT_NAME_REQUIRED: "Client name is required",
    NAME_REQUIRED: "Name is required",
    GROUP_TITLE_REQUIRED: "Title is required",
    GROUP_TITLE_INVALID: "Group title must contain at least one alphabetic character and cannot contain commas",
    LESSON_TITLE_REQUIRED: "Title is required",
    EMAIL_REQUIRED: "Email is required",
    EMAIL_FORMAT: "Invalid email format",
    PASSWORD_REQUIRED: "Password is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    CURRENT_PASSWORD_REQUIRED: "Current Password is required",
    NEW_PASSWORD_REQUIRED: "New Password is required",
    PHONE_REQUIRED: "Contact number is required",
    TEAM_TITLE_REQUIRED: "Team title is required",
    NOTIFICATION_TITLE_REQUIRED: "Notification title is required",
    NOTIFICATION_DESCRIPTION_REQUIRED: "Notification description is required",
    PAGE_CONTENT_REQUIRED: "Page Content is required",
    ADDRESS_REQUIRED: "Address is required",


    SESSION_TITLE_MAX: "Max length is " + VALIDATIONS.SESSION_TITLE,
    GROUP_TITLE_MAX: "Max length is " + VALIDATIONS.GROUP_TITLE,
    LESSON_TITLE_MAX: "Max length is " + VALIDATIONS.GROUP_TITLE,
    NAME_MAX: "Max length is " + VALIDATIONS.NAME,
    EMAIL_MAX: "Max length is " + VALIDATIONS.EMAIL,
    PASSWORD_MIN: "Min length is " + VALIDATIONS.PASSWORD_MIN,
    PASSWORD_MAX: "Max length is " + VALIDATIONS.PASSWORD_MAX,
    CARD_NUMBER_MIN: "Min length is " + VALIDATIONS.CARD_NUMBER,
    CARD_NUMBER_MAX: "Max length is " + VALIDATIONS.CARD_NUMBER,
    PHONE_MIN: "Min length is " + VALIDATIONS.PHONE_MIN,
    PHONE_MAX: "Max length is " + VALIDATIONS.PHONE_MAX,
    BIO_MAX: "Max length is " + VALIDATIONS.BIO_MAX,
    CVV_MIN: "Min length is " + VALIDATIONS.CVV,
    CVV_MAX: "Max length is " + VALIDATIONS.CVV,
    TEAM_TITLE_MAX: "Max length is " + VALIDATIONS.TEAM_TITLE_MAX,
    NOTIFICATION_TITLE_MAX: "Max length is " + VALIDATIONS.NOTIFICATION_TITLE_MAX,
    NOTIFICATION_DESCRIPTION_MAX: "Max length is " + VALIDATIONS.NOTIFICATION_DESCRIPTION_MAX,
    LOCATION_MAX: "Max length is " + VALIDATIONS.LOCATION_MAX,
    CAPTION_MAX: "Max length is " + VALIDATIONS.CAPTION_MAX,
    ADDRESS_MAX: "Max length is " + VALIDATIONS.ADDRESS_MAX,
    VERIFICATION_CODE: "Length is " + VALIDATIONS.VERIFICATION_CODE,
    INVALID_FORMAT: "Invalid format",
    INVALID_PASSWORD_FORMAT: "Password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase , 1 Numeric and 1 Special Character."
}

export const SPECIAL_CHARACTER_ARRAY = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", ";", ":", "\\", "|", "<", ",", ">", ".", "?", "/"
]

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ">", ".", "?", "/"
]

export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = [
    "e", "E", "-", "+", "."
]

export const GENERAL_ERROR_MESSAGES = {
    PROFESSIONAL_LOGIN: "Credentials are not valid. Use your sub-admin account's credentials.",
}

export const TOOL_TIP_MESSAGES = {
    CREATE_GROUP: "Groups are a way to organize your curriculum.  It really depends on your client progress flow how you want to organize groups.  If you usually follow the same flow from session 1 to session 2, then simple names like Session 1 Session 2 may make more sense.  If you are all over the place with your clients needs, main grouping topics may make sense. Example: Fitness Trainer Group titles: Diets, Cardio, Hit, Strength/ resistance training.",
    CREATE_BOX: "Boxes are the individual subjects sent to your clients. In general, a box contents should be limited to an extremely narrow topic.  Try not to go over 10 minutes in video, 3 pages written out, or 5-10 images.  If you do need to go over, you probably should break the box into two smaller ones. Example: Dog Training Tricks ← Too general.",
    CREATE_RESOURCE: "Supported file types: .jpg, .jpeg, .png, .jfif, .tiff, .mp4, .m4v,.mov, .mp3, .pdf, .docx, .txt, .xlsx. These are the files your clients will see when opening your box.  To help you be most effective try to reduce the resources added here to be only relevant to the box itself.  If you need to link to an external resource a PDF/txt document with those links inside should suffice in most cases. Example: Photography changing film in camera. Resources: image.ipg, video.mp4, document.pdf"
}
