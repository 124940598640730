import React from "react";
import {FileIconXLarge} from "../../constants/svgs";

const PdfFileCard = ({
                       click,
                       setClick,
                       data,
                       showTitle,
                       redirect,
                       setSelectedData,
                       setRowModalOpen,
                       setModalType
                     }) => {
  const handleClick = () => {
    if (!redirect) {
      window.open(data.file_url, "_blank");
    } else {
      setSelectedData(data);
      setRowModalOpen(true);
      setModalType(false)
    }
  };

  return (
      <div
          onClick={() => setClick(!click)}
          className="my-card file-card pb-3 p-2"
      >
        <div
            className="bg-div bg-light-grey py-4"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
        >
          <div className="file-icon-container bg-white">
            <FileIconXLarge />
          </div>
        </div>
        {showTitle && <span className="d-inline-block mt-2">{data?.title}</span>}
      </div>
  );
};

export default PdfFileCard;
