import React, { useContext, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import NotificationCard from '../home/NotificationCard'
import { DeleteAccountPopupContext } from "../../App"
import { useNavigate } from 'react-router-dom'

const DeleteAccountPopup = () => {
    const [handleDeleteAccountPopup, setHandleDeleteAccountPopup] = useContext(DeleteAccountPopupContext);
    const navigate = useNavigate()

    const handleDeleteAccount = ()=>{
        setHandleDeleteAccountPopup(false)
        navigate("/signin")
    }

    return (
        <div>
            <Modal
                className='delete-account-modal'
                show={handleDeleteAccountPopup}
                onHide={()=>setHandleDeleteAccountPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleDeleteAccountPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Delete Account Alert</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Are you sure you would like to Delete
                                    of your Grahamity account?
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button onClick={() => setHandleDeleteAccountPopup(false)} className="btn-common me-4">Cancel</Button>
                                <Button onClick={()=>handleDeleteAccount()} className="btn-common no-bg">Delete</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DeleteAccountPopup