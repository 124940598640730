import React, {useContext} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {LogoutPopupContext} from "../../App"
import {useNavigate} from 'react-router-dom'
import {EmptyLocalStorage, GetAuthUserLocalStorage, Logout} from "../../services/auth/auth.services"


const LogoutPopup = ({ btnText1, btnText2, heading, title }) => {
    const [handleLogoutPopup, setHandleLogoutPopup] = useContext(LogoutPopupContext);
    const navigate = useNavigate()
    const user = GetAuthUserLocalStorage()
    const user_id = user?.role_id
    // console.log("user", user)
    const handleLogout = async () => {
        try {
            await Logout().then(async (res) => {
                if (res.data.success) {
                    if (user_id === 4) {
                        navigate("/signin/trainer")
                        EmptyLocalStorage()
                        setHandleLogoutPopup(false)
                    } else {
                        navigate("/signin/client")
                        EmptyLocalStorage()
                        setHandleLogoutPopup(false)
                    }


                } else {
                    console.log(res.data.message)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <Modal
                className='logout-modal'
                show={handleLogoutPopup}
                onHide={() => setHandleLogoutPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleLogoutPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>{heading}</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    {title}
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button onClick={() => setHandleLogoutPopup(false)} className="btn-common me-4">{btnText1}</Button>
                                <Button onClick={() => handleLogout()} className="btn-common no-bg">{btnText2}</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LogoutPopup