import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ChangePasswordForm from '../components/authentication/ChangePasswordForm'

const ChangePasswordPage = () => {
  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center">
        <Col xs={12} sm={9} md={8} lg={6} xl={5} xxl={4} className="my-auto">
          <div className='my-5'>
            <ChangePasswordForm navigateLink="change-password" />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ChangePasswordPage