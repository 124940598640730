import React, { useState, useContext } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";

import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ChangePassword, SetAuthUserLocalStorage } from "../../services/auth/auth.services"
import { UserDataContext } from '../../App';

const ChangePasswordForm = ({ navigateLink }) => {
  const navigate = useNavigate();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [userData, setUserData] = useContext(UserDataContext);

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
    mode: "onChange"
  });

  const password = watch("newPassword");


  const clearInputs = () => {
    reset({
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    })
  }

  const onSubmit = async (data) => {
    const { currentPassword, newPassword, confirmPassword } = data
    setIsDisabled(true)

    try {
      await ChangePassword(currentPassword, newPassword, confirmPassword).then((res) => {
        let temp = { ...userData }
        temp.change_password = 1
        setUserData(temp)
        SetAuthUserLocalStorage(temp)
        clearInputs()
        success(res.data.message)

        setTimeout(() => {
          navigate(`/${navigateLink}`)
          setIsDisabled(false)
        }, 1500)
      })
    }
    catch (e) {
      error(e.response.data.errors[0].message)
      setTimeout(async () => {
        setIsDisabled(false)
      }, 1500)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      <div className='forms change-password-form p-5'>
        <div className='text-center border-bottom pb-4'>
          <h4 className='p-0 m-0'>Change Password</h4>
        </div>

        <div className='mt-5'>
          <div className='mt-4'>
            <Form.Label>Current Password</Form.Label>
            <InputGroup>
              <Form.Control
                name="currentPassword"
                type={!showCurrentPassword ? "password" : "text"}
                className="password-input"
                placeholder="Enter your current password"
                maxLength={VALIDATIONS.PASSWORD_MAX}
                {...register("currentPassword",
                  {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX
                    },
                    // pattern: {
                    //   value: /^.*(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                    //   message: VALIDATIONS_TEXT.INVALID_PASSWORD_FORMAT
                    // },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.CURRENT_PASSWORD_REQUIRED
                    },
                  })
                }

              />
              <InputGroup.Text>
                {
                  !showCurrentPassword ?
                    <AiOutlineEye className="eye-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)} /> :
                    <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                }
              </InputGroup.Text>
            </InputGroup>
            {errors.currentPassword && <small className='text-red'>{errors.currentPassword.message}</small>}


          </div>

          <div className='mt-4'>
            <Form.Label>New Password</Form.Label>
            <InputGroup>
              <Form.Control
                name="newPassword"
                type={!showNewPassword ? "password" : "text"}
                className="password-input"
                placeholder="Enter your new password"
                maxLength={VALIDATIONS.PASSWORD_MAX}
                {...register("newPassword",
                  {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX
                    },
                    pattern: {
                      value: /^.*(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                      message: VALIDATIONS_TEXT.INVALID_PASSWORD_FORMAT
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.NEW_PASSWORD_REQUIRED
                    },
                  })
                }

              />
              <InputGroup.Text>
                {
                  !showNewPassword ?
                    <AiOutlineEye className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)} /> :
                    <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)} />
                }
              </InputGroup.Text>
            </InputGroup>
            {errors.newPassword && <small className='text-red'>{errors.newPassword.message}</small>}
          </div>

          <div className='mt-4'>
            <Form.Label>Confirm New Password </Form.Label>
            <InputGroup>
              <Form.Control
                name="confirmPassword"
                type={!showConfirmPassword ? "password" : "text"}
                className="password-input"
                placeholder="Enter your new password"
                maxLength={VALIDATIONS.PASSWORD_MAX}
                {...register("confirmPassword",
                  {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED
                    },
                    validate: (value) =>
                      value === password || "The Passwords do not match"
                  })
                }
              />
              <InputGroup.Text>
                {
                  !showConfirmPassword ?
                    <AiOutlineEye className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> :
                    <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                }
              </InputGroup.Text>
            </InputGroup>
            {errors.confirmPassword && <small className='text-red'>{errors.confirmPassword.message}</small>}
          </div>

          <div className='text-center'>
            <Button disabled={isDisabled} className="w-100 mt-5 btn-signin" type="submit">Change Password</Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ChangePasswordForm