import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player'
import { error, success } from '../../../../constants/msg';
import { Button } from "react-bootstrap"
import Duration from './Duration';
import { FaPause, FaPlay } from "react-icons/fa";
import { LoopDisable, LoopIcon } from '../../../../constants/svgs';
import { GoMute, GoUnmute } from "react-icons/go";
import { DeleteEResource } from "../../../../services/auth/auth.services";
import { useDispatch } from "react-redux";
import { setReloadBoxData } from "../../../../redux/slices/boxSlice";

const VideoPlayer = ({ rowData, setRowModalOpen, getResourceBoxes }) => {
    const [url, setUrl] = useState(null);

    const [playing, setPlaying] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loop, setLoop] = useState(false);
    const [played, setPlayed] = useState(0);
    const [volume, setVolume] = useState(1);
    const [muted, setMuted] = useState(false);
    const [duration, setDuration] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const playerRef = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (rowData?.media_asset) {
            setUrl(rowData?.media_asset)
        }
    }, [rowData])
    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleToggleLoop = () => {
        setLoop(!loop);
    };

    const handleVolumeChange = (e) => {
        setVolume(parseFloat(e.target.value));
    };

    const handleToggleMuted = () => {
        setMuted(!muted);
    };

    const handleSeekChange = (e) => {
        setPlayed(parseFloat(e.target.value));
    };

    const handleSeekMouseUp = (e) => {
        playerRef.current.seekTo(parseFloat(e.target.value));
    };

    /// ----- Action buttons function
    const handleDelete = async () => {
        setIsDeleting(true);
        await DeleteEResource(rowData.id).then((data) => {
            setIsDeleting(false);
            success(data.data.message);
            dispatch(setReloadBoxData(true))
            setRowModalOpen(false)
            getResourceBoxes()
        }).catch((e) => {
            setIsDeleting(false);
            error(e.response.data.message)
        })
    }

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secondsRemainder = Math.floor(seconds % 60);
        return `${minutes}:${secondsRemainder < 10 ? '0' : ''}${secondsRemainder}`;
    };
    console.log("url:", url)
    return (
        <div className='videoPlayerComponent gap-3'>
            <div className='player-wrapper'>
                <ReactPlayer
                    ref={playerRef}
                    className='react-player'
                    width='100%'
                    height='100%'
                    url={url}
                    playing={playing}
                    loop={loop}
                    volume={volume}
                    muted={muted}
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    onEnded={() => setPlaying(loop)}
                    onProgress={(state) => {
                        // Update time slider if not currently seeking
                        if (!seeking) {
                            setPlayed(state.played);
                        }
                    }}
                    onDuration={(duration) => setDuration(duration)}
                />
            </div>

            <div className='controls music-player-main d-flex flex-column w-100'>
                <div className="rhap_container p-0 gap-3">
                    <div className="d-flex justify-content-between align-items-center seek_bar w-100 gap-2">
                        <div className="rhap_time rhap_current-time">{formatDuration(duration * played)}</div>
                        <input
                            type='range'
                            min={0}
                            max={0.999999}
                            step='any'
                            value={played}
                            onChange={handleSeekChange}
                            onMouseUp={handleSeekMouseUp}
                        />
                        <div class="rhap_time rhap_total-time"> <Duration seconds={duration} /></div>
                    </div>
                    <div className="d-flex justify-content-center gap-4 align-items-center w-100">
                        <div onClick={handleToggleLoop}>{loop ? <LoopDisable /> : <LoopIcon />}</div>
                        <Button className='rhap_play-pause-button cursor-pointer' onClick={handlePlayPause}>{playing ? <FaPause /> : <FaPlay />}</Button>
                        {/* <input type='range' min={0} max={1} step='any' value={volume} onChange={handleVolumeChange} /> */}
                        <div className='text-white volumneControl cursor-pointer' onClick={handleToggleMuted}>{muted ? <GoMute />
                            : <GoUnmute />
                        }</div>
                    </div>
                </div>
            </div>

            <div className="action_button d-flex gap-3 mx-auto mt-2">
                {/*<a href={url} download={true} target="_blank">*/}
                {/*    <Button>Download</Button>*/}
                {/*</a>*/}
                <Button className="btn-common no-bg" onClick={handleDelete} disabled={isDeleting}>Delete</Button>
            </div>
            {/* <div className='state'>
                <p>Duration: <Duration seconds={duration} /></p>
                <p>Volume: {volume.toFixed(3)}</p>
                <p>Played: {played.toFixed(3)}</p>
                <p>Remaining: <Duration seconds={duration * (1 - played)} /></p>
            </div> */}
        </div>
    );
};

export default VideoPlayer;
