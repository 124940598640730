import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "./layouts/authLayout/AuthLayout";
import Assets from "./constants/images";
import SigninForm from "./components/authentication/SigninForm";
import VerificationCodeForm from "./components/authentication/VerificationCodeForm";
import CurrentSessionPage from "./pages/CurrentSessionPage";
import FullLayout from "./layouts/fullLayout/FullLayout";
import MainLayout from "./layouts/mainLayout/MainLayout";
import CurrentSessionDescriptionPage from "./pages/CurrentSessionDescriptionPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import {
  CurrentSessionContext,
  NotificationPopupContext,
  ProfilePopupContext,
  SubAdminProfilePopupContext,
  UserDataContext,
} from "./App";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ForgotPasswordForm from "./components/authentication/ForgotPasswordForm";
import ResetPasswordForm from "./components/authentication/ResetPasswordForm";
import SubscriptionPage from "./pages/SubscriptionPage";
import SubscriptionUpgradePage from "./pages/SubscriptionUpgradePage";
import SubAdminFullLayout from "./layouts/subAdminLayout/subAdminFullLayout/SubAdminFullLayout";
import PaymentMethodPage from "./pages/PaymentMethodPage";
import AddGroupPage from "./pages/AddGroupPage";
import SubAdminMainLayout from "./layouts/subAdminLayout/subAdminMainLayout/SubAdminMainLayout";
import MyProfilePage from "./pages/MyProfilePage";
import SettingPage from "./pages/SettingPage";
import ClientHistoryPage from "./pages/ClientHistoryPage";
import EmailSetupPage from "./pages/EmailSetupPage";
import HomePage from "./pages/HomePage";
import ManageGroupPage from "./pages/ManageGroupPage";
import SideNavigation from "./components/home/SideNavigation";
import SubAdminChangePasswordPage from "./pages/SubAdminChangePasswordPage";
// import SubAdminSubscriptionUpgradePage from './pages/SubAdminSubscriptionUpgradePage'
import ProtectedRoute from "./ProtectedRoute";
import { roles } from "./utils/constants";
import PublicRoute from "./PublicRoute";
import ManageSpecificGroupPage from "./pages/ManageSpecificGroupPage";
import SignupForm from "./components/authentication/SignupForm";
import RememberSessionForm from "./components/authentication/RememberSessionForm";
import EmailSetupPageTest from "./pages/EmailSetupPageTest";

const Navigation = () => {
  const [handleCurrentSession, setHandleCurrentSession] = useContext(
    CurrentSessionContext
  );
  const [handleProfilePopup, setHandleProfilePopup] =
    useContext(ProfilePopupContext);
  const [handleNotificationPopup, setHandleNotificationPopup] = useContext(
    NotificationPopupContext
  );
  const [handleSubAdminProfilePopup, setHandleSubAdminProfilePopup] =
    useContext(SubAdminProfilePopupContext);
  const [userData, setUserData] = useContext(UserDataContext);
  const [groupName, setGroupName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setHandleNotificationPopup(false);
    setHandleProfilePopup(false);
    setHandleSubAdminProfilePopup(false);

    document.body.scrollTop = 0;
  }, [navigate, location]);

  return (
    <Routes>
      {/* Client Routes */}
      <Route path="/" element={<Navigate to="/signin/trainer" />} />
      <Route element={<PublicRoute />}>
        <Route path="/signup" element={
          <AuthLayout
            backgroundImage={Assets.BackgroundImage2}
            children={<SignupForm btnText="Sign Up" />}
          />
        } />
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path="/signin/trainer"
          element={
            <AuthLayout
              backgroundImage={Assets.BackgroundImage2}
              children={
                <SigninForm
                  btnText="Log In"
                  navigateLink="verification"
                  isForgetText={true}
                  istrainerRole={true}
                />
              }
            />
          }
        />
        <Route
          path="/signin/client"
          element={
            <AuthLayout
              backgroundImage={Assets.BackgroundImage2}
              children={
                <SigninForm
                  btnText="Log In"
                  navigateLink="verification"
                  isForgetText={true}
                  istrainerRole={false}
                />
              }
            />
          }
        />
        <Route
          path="/signin/trainer"
          element={
            <AuthLayout
              backgroundImage={Assets.BackgroundImage2}
              children={
                <SigninForm
                  btnText="Log In"
                  navigateLink="verification"
                  isForgetText={true}
                  istrainerRole={true}
                />
              }
            />
          }
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path="/verification"
          element={
            <AuthLayout
              backgroundImage={Assets.BackgroundImage2}
              children={<VerificationCodeForm navigateLink="current-session" />}
            />
          }
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path="/forgot-password"
          element={
            <AuthLayout
              backgroundImage={Assets.BackgroundImage1}
              children={<ForgotPasswordForm />}
            />
          }
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path="/code-verification"
          element={
            <AuthLayout
              backgroundImage={Assets.BackgroundImage2}
              children={<VerificationCodeForm navigateLink="reset-password" />}
            />
          }
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path="/reset-password"
          element={
            <AuthLayout
              backgroundImage={Assets.BackgroundImage2}
              children={<ResetPasswordForm />}
            />
          }
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path="/remember-session/:id"
          element={
            <AuthLayout
              backgroundImage={Assets.BackgroundImage2}
              children={<RememberSessionForm />}
            />
          }
        />
      </Route>
      <Route element={<ProtectedRoute requiredRole={[roles.ROLE_USER]} />}>
        <Route
          path="/current-session"
          element={
            <FullLayout
              heading={
                handleCurrentSession
                  ? handleCurrentSession
                  : "No current session"
              }
              showBreadcrumb={true}
              children={<CurrentSessionPage />}
            />
          }
        />
      </Route>
      <Route element={<ProtectedRoute requiredRole={[roles.ROLE_USER]} />}>
        <Route
          path="/current-session/:id"
          element={
            <MainLayout
              heading={
                handleCurrentSession
                  ? handleCurrentSession
                  : "No current session"
              }
              homeLink="current-session"
              showBreadcrumb={true}
              breadcrumbLink={
                handleCurrentSession
                  ? handleCurrentSession
                  : "No current session"
              }
              children={<CurrentSessionDescriptionPage />}
            />
          }
        />
      </Route>
      <Route element={<ProtectedRoute requiredRole={[roles.ROLE_USER]} />}>
        <Route
          path="/change-password"
          element={
            <MainLayout
              heading="Change Password"
              homeLink="current-session"
              showBreadcrumb={true}
              breadcrumbLink="Change Password"
              children={<ChangePasswordPage />}
            />
          }
        />
      </Route>
      <Route element={<ProtectedRoute requiredRole={[roles.ROLE_USER]} />}>
        <Route
          path="/terms-and-conditions"
          element={
            <MainLayout
              heading="Terms And Conditions"
              homeLink="current-session"
              showBreadcrumb={true}
              breadcrumbLink="Terms And Conditions"
              children={<TermsAndConditionsPage height={false} />}
            />
          }
        />
      </Route>
      <Route element={<ProtectedRoute requiredRole={[roles.ROLE_USER]} />}>
        <Route
          path="/privacy-policy"
          element={
            <MainLayout
              heading="Privacy Policy"
              homeLink="current-session"
              showBreadcrumb={true}
              breadcrumbLink="Privacy Policy"
              children={<PrivacyPolicyPage height={false} />}
            />
          }
        />
      </Route>
      <Route element={<ProtectedRoute requiredRole={[roles.ROLE_USER]} />}>
        <Route
          path="/profile"
          element={
            <MainLayout
              heading="My Profile"
              homeLink="current-session"
              showBreadcrumb={true}
              breadcrumbLink="My Profile"
              children={<MyProfilePage margin={true} client={true} />}
            />
          }
        />
      </Route>
      {/* Sub Admin Routes */}
      <Route
        path="/signin/trainer"
        element={
          <AuthLayout
            backgroundImage={Assets.BackgroundImage1}
            children={
              <SigninForm
                btnText="Log In"
                navigateLink="sub-admin/subscription"
                isWelcomeText={false}
                isForgetText={true}
              />
            }
          />
        }
      />
      <Route
        path="/sub-admin/forgot-password"
        element={
          <AuthLayout
            backgroundImage={Assets.BackgroundImage1}
            children={<ForgotPasswordForm />}
          />
        }
      />
      <Route
        path="/sub-admin/verification"
        element={
          <AuthLayout
            backgroundImage={Assets.BackgroundImage2}
            children={
              <VerificationCodeForm navigateLink="sub-admin/reset-password" />
            }
          />
        }
      />
      <Route
        path="/sub-admin/professional-verification"
        element={
          <AuthLayout
            backgroundImage={Assets.BackgroundImage2}
            children={
              <VerificationCodeForm navigateLink="sub-admin/add-group" />
            }
          />
        }
      />
      <Route
        path="/sub-admin/reset-password"
        element={
          <AuthLayout
            backgroundImage={Assets.BackgroundImage2}
            children={<ResetPasswordForm />}
          />
        }
      />
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
          />
        }
      >
        <Route
          path="/sub-admin/subscription"
          element={
            <SubAdminFullLayout
              backgroundImage={Assets.BackgroundImage3}
              children={<SubscriptionPage />}
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
          />
        }
      >
        <Route
          path="/sub-admin/payment-method"
          element={
            <SubAdminFullLayout
              backgroundImage={Assets.BackgroundImage3}
              children={<PaymentMethodPage />}
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
            checkSubscription={1}
          />
        }
      >
        <Route
          path="/sub-admin/add-group"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1={
                handleCurrentSession
                  ? handleCurrentSession
                  : "No current session"
              }
              breadcrumbLink2=""
              children={<AddGroupPage />}
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
          />
        }
      >
        <Route
          path="/sub-admin/profile"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1="Profile Affiliation"
              breadcrumbLink2=""
              children={<MyProfilePage client={false} />}
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
            checkSubscription={1}
          />
        }
      >
        <Route
          path="/sub-admin/client-history"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              show={true}
              breadcrumbLink1="Client History"
              breadcrumbLink2=""
              children={<ClientHistoryPage />}
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
            checkSubscription={1}
          />
        }
      >
        <Route
          path="/sub-admin/email-setup"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1="E-mail Setup"
              breadcrumbLink2=""
              children={<EmailSetupPage />}
            />
          }
        />
      </Route>

      <Route
        path="/sub-admin/email-setup-test"
        element={<EmailSetupPageTest />}
      />
      {/* <Route element={<ProtectedRoute requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]} checkSubscription={1} />}>
                <Route path="/sub-admin/email-template"
                    element={<SubAdminMainLayout homeLink="sub-admin/manage-group" breadcrumbLink1="E-mail Setup"
                        breadcrumbLink2="" children={<EmailTemplatePage />} />} />
            </Route> */}
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
            checkSubscription={1}
          />
        }
      >
        <Route
          path="/sub-admin/manage-group"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1="All Groups"
              breadcrumbLink2=""
              children={<ManageGroupPage />}
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
          />
        }
        checkSubscription={1}
      >
        <Route
          path="/sub-admin/manage-group/:id"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1={
                handleCurrentSession
                  ? handleCurrentSession
                  : "No current session"
              }
              breadcrumbLink2=""
              breadcrumbLink3={groupName}
              children={<ManageSpecificGroupPage setGroupName={setGroupName} />}
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
          />
        }
      >
        <Route
          path="/sub-admin/settings/change-password"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1="Settings"
              breadcrumbLink2=""
              children={
                <SettingPage
                  children1={<SideNavigation />}
                  children2={<SubAdminChangePasswordPage />}
                />
              }
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
          />
        }
      >
        <Route
          path="/sub-admin/settings/terms-and-conditions"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1="Settings"
              breadcrumbLink2="Terms-and-Conditions"
              children={
                <SettingPage
                  children1={<SideNavigation />}
                  children2={
                    <TermsAndConditionsPage height={true} noGutters={true} />
                  }
                />
              }
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
          />
        }
      >
        <Route
          path="/sub-admin/settings/privacy-policy"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1="Settings"
              breadcrumbLink2="Privacy Policy"
              children={
                <SettingPage
                  children1={<SideNavigation />}
                  children2={
                    <PrivacyPolicyPage height={true} noGutters={true} />
                  }
                />
              }
            />
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            requiredRole={[roles.ROLE_SUB_ADMIN, roles.ROLE_PROFESSIONAL]}
          />
        }
      >
        <Route
          path="/sub-admin/settings/subscription-upgrade"
          element={
            <SubAdminMainLayout
              homeLink="sub-admin/manage-group"
              breadcrumbLink1="Settings"
              breadcrumbLink2="Subscription Upgrade"
              children={
                <SettingPage
                  children1={<SideNavigation />}
                  children2={<SubscriptionUpgradePage />}
                />
              }
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default Navigation;
