import React, { useState, useContext, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import NotificationCard from '../home/NotificationCard'
import { RemoveGroupPopupContext, RemoveHistoryPopupContext } from "../../App"
import { useNavigate } from 'react-router-dom'
import { Logout, EmptyLocalStorage, DeleteClientHistory } from "../../services/auth/auth.services"
import { success, error } from "../../constants/msg";


const RemoveHistoryPopup = ({ rows, setRows, setTempArray, selectedHistory }) => {
    const [handleRemoveHistoryPopup, setHandleRemoveHistoryPopup] = useContext(RemoveHistoryPopupContext);
    const [isDisabled, setIsDisabled] = useState(false)
    const navigate = useNavigate()

    const returnSelectedHistoryIndex = (temp) => {
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === selectedHistory) {
                return i
            }
        }
    }

    const deleteHistory = async () => {
        setIsDisabled(true)

        try {
            let temp = [...rows]
            const res = await DeleteClientHistory(selectedHistory)
            let index = returnSelectedHistoryIndex(temp)
            temp.splice(index, 1)
            setRows(temp)
            setTempArray(temp)
            success(res.data.message)
            setIsDisabled(false)
            setHandleRemoveHistoryPopup(false)
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(() => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <>
            <Modal
                className='logout-modal'
                show={handleRemoveHistoryPopup}
                onHide={() => setHandleRemoveHistoryPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleRemoveHistoryPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Delete Group</h6>
                        </div>

                        <div className='p-5 text-center'>
                            <div>
                                <p>
                                    Are you sure you want to delete history ?
                                </p>
                            </div>

                            <div className='mt-5'>
                                <Button disabled={isDisabled} onClick={() => deleteHistory()} className="btn-common me-4">Delete</Button>
                                <Button onClick={() => setHandleRemoveHistoryPopup(false)} className="btn-common no-bg">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RemoveHistoryPopup