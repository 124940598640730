import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Form, } from 'react-bootstrap'


function EditPage(props) {
    const [formData, setFormData] = useState({});
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const { setValue, handleSubmit, } = useForm({
        mode: "onBlur",
    });

    //Note for dev:This is when we are getting data from the API 

      // const contentState = ContentState.createFromBlockArray(htmlToDraft(result.data?.content).contentBlocks);
      // const newEditorState = EditorState.createWithContent(contentState);
      // setEditorState(newEditorState);


    const onSubmit = (data) => {

        let testing = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        console.log("Editor Data", testing)


    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
    }

    return (
        <Form autoComplete="off" role="presentation"
            onSubmit={handleSubmit(onSubmit)}>

            <Editor
                editorState={editorState}
                toolbar={{
                    options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'list'],
                    inline: {
                        inDropdown: false,
                        className: "editor-toolbar",
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'strikethrough'],

                    },
                }}
                toolbarClassName="editorToolbar"
                wrapperClassName="wrapperClassName"
                editorClassName="editorTemplateContainer"
                onEditorStateChange={onEditorStateChange}
            />
            <input type={"submit"} className={"btn btn-green-theme w-100 mt-3 d-inline"}
                value={"Submit"} />
        </Form>
    )
}

export default EditPage;