import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';


const RightPanel = ({ children }) => {
    return (
        <div className='right'>
            <div className='h-100 py-4'>
                <Container className="h-100">
                    <Row className=' h-100 justify-content-center'>
                        <Col xs={12} xxl={5} xl={7} lg={8} sm={10} className="my-auto">
                            {children}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default RightPanel