import React, {useState} from 'react'
import {Mp3Icon} from '../../../../constants/svgs';
import AudioPlayer from 'react-h5-audio-player';
import {Button} from "react-bootstrap"
import 'react-h5-audio-player/lib/styles.css';
import {FaPause, FaPlay} from "react-icons/fa";
import {error, success} from '../../../../constants/msg';
import {GoMute, GoUnmute} from "react-icons/go";
import {useDispatch} from "react-redux";
import {DeleteEResource} from "../../../../services/auth/auth.services";
import {setReloadBoxData} from "../../../../redux/slices/boxSlice";

const AudioComponent = ({ rowData, setRowModalOpen,  getResourceBoxes }) => {
    const customIcons = {
        play: <FaPlay />,
        pause: <FaPause />,
        volume: <GoUnmute />,
        volumeMute: <GoMute />
    };
    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = async () => {
        setIsDeleting(true);
        await DeleteEResource(rowData.id).then((data)=>{
            setIsDeleting(false);
            success(data.data.message);
            dispatch(setReloadBoxData(true))
            setRowModalOpen(false)
            getResourceBoxes()
        }).catch((e) => {
            setIsDeleting(false);
            error(e.response.data.message)
        })
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center gap-4">
                <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                    <div className="mp3_icon">
                        <Mp3Icon />
                    </div>
                    <p className='text-white m-0'>{rowData.files}.{rowData.media_type}</p>
                </div>
                <div className={'music-player-main'}>
                    <AudioPlayer
                        customIcons={customIcons}
                        layout={'stacked'}
                        // customProgressBarSection={[<CustomProgressIndicator key="custom-progress-indicator" />]}
                        // autoPlay
                        volumeJumpStep={false}
                        volumnProgress={false}
                        src={rowData.media_asset}
                        onPlay={e => console.log("onPlay")}
                    />
                </div>
                <div className="action_button d-flex gap-3 mx-auto">
                    {/*<a href={rowData.media_asset} download={true} target="_blank">*/}
                    {/*    <Button>Download</Button>*/}
                    {/*</a>*/}
                    <Button className="btn-common no-bg" onClick={handleDelete}>Delete</Button>
                </div>
            </div>
        </>
    )
}

export default AudioComponent