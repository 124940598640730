import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    selectedBoxData: null,
    reloadBoxData: false,
    boxResources: []
}

export const boxSlice = createSlice({
    name: 'box',
    initialState,
    reducers: {
        setSelectedBoxData: (state,action) => {
            state.selectedBoxData = action.payload
        },
        setReloadBoxData: (state,action) => {
            state.reloadBoxData = action.payload
        },
        setBoxResources: (state,action) => {
            state.boxResources = action.payload;
        }
    },
})

export const { setSelectedBoxData,setReloadBoxData, setBoxResources } = boxSlice.actions

export default boxSlice.reducer