import React, {useContext, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import AddGroupPopup from '../components/popup/AddGroupPopup'
import {AddGroupIcon} from '../constants/svgs'
import {AddGroupPopupContext, AddSessionPopupContext, CurrentSessionContext, UserDataContext} from "../App"
import {GetEssionsListing, GetGroups} from '../services/auth/auth.services'
import {error} from "../constants/msg";

import {NavLink, useNavigate} from 'react-router-dom'
import Assets from '../constants/images'
import Loader from '../components/loader/Loader'
import AddSessionPopup from '../components/popup/AddSessionPopup'

const AddGroupPage = ({ setGroupName }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useContext(UserDataContext)
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [handleAddGroupPopup, setHandleAddGroupPopup] = useContext(AddGroupPopupContext);

    const [handleCurrentSession, setHandleCurrentSession] = useContext(CurrentSessionContext);
    const [handleAddSessionPopup, setHandleAddSessionPopup] = useContext(AddSessionPopupContext);
    const [sessionCreated, setSessionCreated] = useState(false);

    const [boxes, setBoxes] = useState([])
    const [groups, setGroups] = useState([])
    const [session, setSession] = useState({})
    const images = [Assets.Art1, Assets.Art2]


    useEffect(() => {
        const getEsessionAndBoxes = async () => {
            try {
                await GetEssionsListing(userData.id).then(async (res) => {
                    if (res.data.data.length > 0) {
                        setHandleCurrentSession(res.data.data[0].name)
                        setHandleAddSessionPopup(false);
                        const session_id = res.data.data[0].id
                        try {
                            const response = await GetGroups(session_id);
                            setSession(res.data.data[0])
                            setGroups(response.data.data)
                            setIsLoading(false)
                            setIsError(false)
                        } catch (e) {
                            setIsLoading(false)
                            setIsError(true)
                            error(e.response.data.message)
                        }
                    } else {
                        setHandleAddSessionPopup(true)
                        setIsLoading(false)
                        setIsError(true)
                    }
                })
            } catch (e) {
                setIsLoading(false)
                setIsError(true)
                error(e.response.data.message)
            }
        }

        getEsessionAndBoxes()
    }, [userData, sessionCreated])

    const returnImage = (index) => {
        if (index % 2 == 0) {
            return images[0]
        } else {
            return images[1]
        }
    }
    return (
        <div className="common-page add-group-page">
            <AddGroupPopup session={session} isStateUpdate={false} />
            <AddSessionPopup sessionCreated={sessionCreated} setSessionCreated={setSessionCreated}
                isStateUpdate={handleAddSessionPopup} />

            <Row>
                <Col sm={6} md={4} xl={3} className="mb-4">
                    <div className="left d-flex align-items-center justify-content-center text-center p-4 bg-white">
                        <div onClick={() => setHandleAddGroupPopup(true)}
                            className='add-box-container py-5 d-flex flex-column align-items-center justify-content-center text-center w-100'>
                            <div className='holder mb-3'>
                                <AddGroupIcon />
                            </div>
                            <h6 className='m-0 p-0'>Add New Group</h6>
                        </div>
                    </div>
                </Col>

                <Col sm={6} md={8} xl={9} className="mb-4">
                    {
                        groups.length > 0 ?
                            <div className="right p-4 bg-white">
                                <Row>
                                    {
                                        groups.map((data, i) => (
                                            <Col key={i} xs={12} lg={6} xl={4}>
                                                <div className='current-session-card text-center p-2'>
                                                    <NavLink to={`/sub-admin/manage-group/${data.id}`} state={{
                                                        data: {
                                                            group_id: data.id,
                                                            box_id: "",
                                                        }
                                                    }}>
                                                        <img src={returnImage(i)} alt="image" />
                                                        <h6 className='p-0 m-0 mt-2'>{data?.name}</h6>
                                                    </NavLink>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                            :
                            <div
                                className="right d-flex align-items-center justify-content-center text-center p-4 bg-white">
                                {
                                    isLoading ?
                                        <Loader />
                                        :
                                        <h1>No box added yet</h1>
                                }
                            </div>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default AddGroupPage