import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import NotificationCard from '../home/NotificationCard'
import { NotesPopupContext } from "../../App"

const NotesPopup = () => {
    const [handleNotesPopup, setHandleNotesPopup] = useContext(NotesPopupContext);

    return (
        <div>
            <Modal
                className='notes-modal'
                show={handleNotesPopup}
                onHide={()=>setHandleNotesPopup(false)}
                size="lg"
                centered
                aria-labelledby="contained-modal-title-vcenter"

            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleNotesPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom pb-4 mb-4'>
                            <h6 className='p-0 pt-2 m-0'>Notes</h6>
                        </div>
                        <div className='p-2 text-center'>
                            <p>
                                This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.
                                These words are here to provide the reader with a basic impression  of how actual text will appear  in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form. That being the case,  there is really no point in your continuing to read them. After all,
                                you have many other things you should be doing.
                                <br /><br />
                                This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed.
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NotesPopup