import React, { useEffect, useState } from "react";
import { customTableStyles } from "../../../utils/constants";
import DataTable from "react-data-table-component";
import Loader from "../../loader/Loader";
import { Modal } from "react-bootstrap";
import ImageComponent from "./mediaTypeModalComponent/ImageComponent";
import PdfComponent from "./mediaTypeModalComponent/PdfComponent";
import LinkOpenComponent from "./mediaTypeModalComponent/LinkOpenComponent";
import AudioComponent from "./mediaTypeModalComponent/AudioComponent";
import VideoPlayer from "./mediaTypeModalComponent/VideoPlayer";
import { SortIcon } from "../../../constants/svgs";
import { GetResourceBoxes } from "../../../services/auth/auth.services";
import { error } from "../../../constants/msg";
import { useDispatch, useSelector } from "react-redux";
import {
  setBoxResources,
  setReloadBoxData,
} from "../../../redux/slices/boxSlice";
import { FaTimes } from "react-icons/fa";
import EmptyComponent from "./mediaTypeModalComponent/EmptyComponent";

const SubAdminBoxTable = () => {
  const dispatch = useDispatch();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalHistoryRecord, setTotalHistoryRecord] = useState(15);
  const [pageHistory, setPageHistory] = useState(1);
  const [perPageHistory, setPerPageHistory] = useState(5);
  const [rowModalOpen, setRowModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBoxId, setSelectedBoxId] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const { selectedBoxData, reloadBoxData, boxResources } = useSelector(
    (state) => state.box
  );
  // Sample data for the table
  const getResourceBoxes = async () => {
    if (!selectedBoxId || selectedBoxId !== selectedBoxData.id) {
      setIsLoading(true);
    }
    let array = [];
    // let sortBy = sortingOrder
    try {
      let params = {};
      setLoading(true);
      params["paginate"] = 1;

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["per_page"] = perPage;
      }
      params["orderBy"] = "id";
      params["sortedBy"] = "desc";
      params["current_session"] = "1";
      params["box_id"] = selectedBoxData.id;
      await GetResourceBoxes(params).then(async (res) => {
        setIsLoading(true)
        setSelectedBoxId(selectedBoxData.id);
        dispatch(setReloadBoxData(false));
        setTotalRows(res.data?.meta.total);
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          if (
            typeof data[i].resource !== "undefined" &&
            data[i].resource !== null
          ) {
            //data[i].resource.resource_boxId = data[i].id;
            array.push({
              id: data[i].resource.id,
              files: data[i].resource.title,
              file_type: data[i].resource.type,
              media_type: data[i].resource.type,
              media_asset:
                data[i].resource.type == "link"
                  ? data[i].resource.link
                  : data[i].resource.url,
            });
          }
        }
        dispatch(setBoxResources(array));
        setIsLoading(false);
      });
    } catch (e) {
      dispatch(setReloadBoxData(false));
      setIsLoading(false);
      error(e.response.data.message);
    }
  };
  useEffect(() => {
    getResourceBoxes();
  }, [selectedBoxData, reloadBoxData, page, perPage]);

  // Columns configuration
  const columns = [
    {
      name: "Files",
      selector: (row) => row.files,
      sortable: true,
    },
    {
      name: "Files Type",
      selector: (row) => row.file_type,
      sortable: true,
    },
  ];

  const handleRowClick = (row) => {
    // console.log("row:", row);
    setRowData(row);
    setRowModalOpen(true);
  };
  //${boxResources.length > 0 ? null : "noTableData"}
  return (
    <>
      <div className={`table-container`}>

        <DataTable
          data={boxResources}
          columns={columns}
          customStyles={customTableStyles}
          className="RowCursorPointer"
          sortIcon={<SortIcon />}
          // fixedHeader
          // fixedHeaderScrollHeight='500px'
          progressPending={isLoading}
          responsive
          selectableRows={false}
          striped
          noRowsPerPage={true}
          highlightOnHover={true}
          showSortable={true}
          pagination
          // paginationRowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
          paginationTotalRows={totalRows}
          paginationServer
          onRowClicked={handleRowClick}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          progressComponent={<Loader />}
          noDataComponent={
            <div className="mt-5">
              <h3 className="text-center no-items"> No resources available</h3>
            </div>
          }
        />
      </div>

      {/* Row Modal */}
      <Modal
        className="mediaModal logout-modal"
        size="md"
        show={rowModalOpen}
        onHide={() => {
          setRowModalOpen(false);
        }}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="closeIcon">
          <div onClick={() => {
            setRowModalOpen(false);
          }}
            className="fa-times"><FaTimes /></div>
        </div>
        <Modal.Body className="border-0">
          <div className="darkModalWrapper">
            {rowData &&
              (rowData.media_type === "image" ? (
                <ImageComponent
                  rowData={rowData}
                  setRowModalOpen={setRowModalOpen}
                  getResourceBoxes={getResourceBoxes}
                />
              ) : rowData.media_type === "document" ? (
                <PdfComponent
                  rowData={rowData}
                  setRowModalOpen={setRowModalOpen}
                  getResourceBoxes={getResourceBoxes}
                />
              ) : rowData.media_type === "link" ? (
                <LinkOpenComponent
                  rowData={rowData}
                  setRowModalOpen={setRowModalOpen}
                  getResourceBoxes={getResourceBoxes}
                />
              ) : rowData.media_type === "mp3" ? (
                <AudioComponent
                  rowData={rowData}
                  setRowModalOpen={setRowModalOpen}
                  getResourceBoxes={getResourceBoxes}
                />
              ) : rowData.media_type === "video" ? (
                <VideoPlayer
                  rowData={rowData}
                  setRowModalOpen={setRowModalOpen}
                  getResourceBoxes={getResourceBoxes}
                />
              ) : (

                  <EmptyComponent
                      rowData={rowData}
                      setRowModalOpen={setRowModalOpen}
                      getResourceBoxes={getResourceBoxes}
                  />
                // <p className="text-white">No data</p>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubAdminBoxTable;
