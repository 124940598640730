import React, {useState} from "react";

import Assets from "../../constants/images";
import {ImageIconSmall} from "../../constants/svgs";

const ImageCard = ({
                       data,
                       downloadFile,
                       click,
                       setClick,
                       setSelectedData,
                       setRowModalOpen,
                       setModalType
                   }) => {
    const [isError, setIsError] = useState(false);

    return (
        <div
            onClick={() => {
                setClick(!click);
                setSelectedData(data);
                setRowModalOpen(true);
                setModalType(true)
            }}
            className="my-card file-card image-card pb-3 p-2"
        >
            {/* <Button onClick={()=>downloadFile(data?.file_url)} className='download-btn'><MdDownloadForOffline /></Button> */}

            {isError ? (
                <div className="bg-div bg-light-grey py-4">
                    <div className="file-icon-container bg-white">
                        <ImageIconSmall />
                    </div>
                </div>
            ) : (
                <img
                    onError={(e) => {
                        e.target.src = Assets.ImagePlaceHolder;
                        setIsError(true);
                    }}
                    src={data.file_url}
                    alt="image"
                />
            )}
            <span className="d-inline-block mt-2">{data?.title}</span>
        </div>
    );
};

export default ImageCard;
