import React, {useContext} from 'react'
import {ImagePopupContext} from '../../App';
import Assets from '../../constants/images'

const ImageViewer = ({ data, setClickedImage}) => {
    const [handleImagePopup, setHandleImagePopup] = useContext(ImagePopupContext);

    return (
        <div className='image-viewer'>
            <div className="div-img mb-4">
                <img onClick={() => {
                    setClickedImage(data)
                    // setHandleImagePopup(true)

                }}
                     onError={(e) => e.target.src = Assets.ImagePlaceHolder} className='w-100' src={data?.file_url} alt="image" />
            </div>
            {/* <h6 className='p-0 m-0'>Image Details</h6> */}
        </div>
    )
}

export default ImageViewer