import React from 'react';
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

const AuthLayout = ({backgroundImage, children}) => {
    return (
        <section className='auth-layout'>
            <div className='d-flex'>
                <LeftPanel 
                    backgroundImage={backgroundImage}
                />
                
                <RightPanel 
                    children={children}
                />
            </div>
        </section>
    )
}

export default AuthLayout