import React, {useContext, useEffect, useState} from 'react'
import {Button, Form, InputGroup, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import {EditGroupPopupContext} from "../../App"
import {useForm} from "react-hook-form";
import {SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT} from '../../constants/app-constants';
import {error, success} from "../../constants/msg";

import {useNavigate} from "react-router-dom";
import {EditGroup} from '../../services/auth/auth.services';
import {setReloadGroups} from "../../redux/slices/groupSlice";
import {useDispatch} from "react-redux";
import Loader from "../loader/Loader";

const EditGroupPopup = ({ selectedGroup, mergeGroupAndBoxes, setMergeGroupAndBoxes }) => {
    const [handleEditGroupPopup, setHandleEditGroupPopup] = useContext(EditGroupPopupContext);
    const [isDisabled, setIsDisabled] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        reset({
            groupTitle: selectedGroup.name
        })
    }, [selectedGroup])

    const onSubmit = async (data) => {
        const { groupTitle } = data
        try {
            setIsDisabled(true)
            const res = await EditGroup(selectedGroup.id, groupTitle)
            success(res.data.message)
            setHandleEditGroupPopup(false)
            setIsDisabled(false)
            dispatch(setReloadGroups(true))
            mergeGroupAndBoxes[selectedGroup.index].name = groupTitle
            setMergeGroupAndBoxes(mergeGroupAndBoxes)
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div>
            <Modal
                className='add-group-modal'
                show={handleEditGroupPopup}
                onHide={() => {
                    setHandleEditGroupPopup(false)
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => {
                            setHandleEditGroupPopup(false)
                        }}
                             className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0'>Edit Group</h6>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='p-5'>
                                {/* <div className='text-center'>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the look.
                                    </p>
                                </div> */}

                                <div className=''>
                                    <Form.Label>Group Title</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            maxLength={VALIDATIONS.GROUP_TITLE}
                                            autoComplete="off"
                                            // onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key)) && e.preventDefault()}
                                            name="groupTitle"
                                            type="text"
                                            placeholder="Enter group title"
                                            autoFocus={true}
                                            {...register("groupTitle",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.GROUP_TITLE,
                                                        message: VALIDATIONS_TEXT.GROUP_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.GROUP_TITLE_REQUIRED
                                                    },
                                                    validate: (value) => {
                                                        // Custom validation to disallow leading and trailing spaces
                                                        if (value.trim() !== value) {
                                                            return "White spaces are not allowed";
                                                        }
                                                        return true;
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.groupTitle && <small className='text-red'>{errors.groupTitle.message}</small>}
                                </div>

                                {/*<div className='mt-5'>*/}
                                {/*    <Button disabled={isDisabled} type='submit' className="w-100">Update</Button>*/}
                                {/*</div>*/}

                                <>
                                    {
                                        isDisabled ?
                                            <div className='text-center mt-4'>
                                                <Loader />
                                            </div>
                                            :
                                            <div className='mt-5'>
                                                <Button disabled={isDisabled} type='submit' className="w-100">Update</Button>
                                            </div>
                                    }
                                </>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditGroupPopup